<mat-form-field
  [attr.aria-required]="control().hasValidator(Validators.required)"
  [ngClass]="appliedClass()"
  appearance="outline"
  class="w-[18.75rem]"
  hideRequiredMarker
  id="select"
  subscriptSizing="dynamic"
>
  <img
    [alt]="'SELECT_MENU' | translate"
    [ngClass]="control().disabled ? 'opacity-10' : ''"
    class="absolute end-0 translate-y-[0.7rem]"
    height="8"
    ngSrc="assets/images/icons/arrow-down.svg"
    priority="true"
    width="14"
  />
  <mat-label [attr.aria-labelledby]="dropdownLabel() | translate"
    >{{ dropdownLabel() | translate }} {{ addRequiredToLabel() | translate }}
  </mat-label>
  <mat-select #matSelect [formControl]="control()" [multiple]="isMultiple()">
    <mat-select-trigger>
      <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(currentOptionLabel!)" class="relative -z-10"></p>
    </mat-select-trigger>
    <div class="p-4">
      <est-search-input
        #searchInputComponent
        (keydown.space)="$event.stopPropagation()"
        [appliedClass]="'w-full'"
        [debounceTime]="searchDebounceTime()"
        [searchSignal]="searchSignal"
      ></est-search-input>
    </div>
    <div
      #containerUi
      (scroll)="isElementScrolledToTheBottom(containerUi, 50) && !isLoading() && !isNoMoreResults() && emitScrollEnd()"
      class="max-h-[12.33rem] overflow-auto"
    >
      @if (containsAllOption()) {
        <mat-option value="">{{ "ALL" | translate }}</mat-option>
      }
      @for (item of dropdownOptions(); track item.value) {
        @if ($first) {
          <mat-divider></mat-divider>
        }
        <mat-option [value]="item.value" [disabled]="disabledOptions.includes(item.value)">
          <p [innerHTML]="item.label"></p>
        </mat-option>
        <mat-divider />
      }
      @if (!isMultiple()) {
        <mat-option [value]="selectedDropdownOption()?.value" class="!hidden"
          >{{ selectedDropdownOption()?.label }}
        </mat-option>
      } @else {
        @for (option of cachedDropdownOptions; track $index) {
          <mat-option [value]="option.value" class="!hidden">{{ option.label }}</mat-option>
        }
      }
      @if (isLoading()) {
        <div class="pb-2">
          <est-spinner height="30" width="30" />
        </div>
        <!--    Have to contain at least 1 option even if loading-overlay for the menu to show (Material requires that)   -->
        <mat-option class="!hidden"></mat-option>
      }
      @if (!isLoading() && dropdownOptions().length === 0) {
        <p class="text-sm text-center p-2 text-black-600">
          {{ "NO_RESULTS" | translate }}
        </p>
        <mat-option class="!hidden"></mat-option>
      }
    </div>
  </mat-select>
</mat-form-field>
@if (control().touched && control().invalid && control().hasError("required")) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ dropdownLabel() | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
  </mat-error>
}
