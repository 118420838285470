<div class="text-center py-12 pb-6">
  <p class="mb-6 font-bold text-2xl">{{ "CUSTOMERS.EXISTING_CUSTOMER" | translate }}</p>
  <p class="mb-12 text-sm font-light w-[35rem] m-auto">{{ "CUSTOMERS.EXISTING_CUSTOMER_DESCRIPTION" | translate }}</p>
  <p class="font-medium mb-4">{{ "CUSTOMERS.MATCH_FOUND" | translate }}</p>
  @if (matchData().matchRequest) {
    <div class="matching-box">
      <p class="matching-box__header col-span-2">{{ "CUSTOMERS.CUSTOMER_DETAILS" | translate }}</p>
      <p class="matching-box__header">{{ "CUSTOMERS.NEW_DETAILS" | translate }}</p>

      <p class="matching-box__title">{{ "CUSTOMERS.CUSTOMER_NAME" | translate }}:</p>
      <p
        class="matching-box__content"
        [estCustomerMatchingColoring]="['firstName', 'lastName']"
        [differences]="matchData().differences"
      >
        {{ fullOldName() }}
      </p>
      <p
        class="matching-box__content"
        [estCustomerMatchingColoring]="['firstName', 'lastName']"
        [differences]="matchData().differences"
      >
        {{ fullNewName() }}
      </p>

      <p class="matching-box__title">{{ "CUSTOMERS.CUSTOMER_NUMBER" | translate }}:</p>
      <p
        class="matching-box__content"
        estCustomerMatchingColoring="customerNumber"
        [differences]="matchData().differences"
      >
        {{ matchData().matchRequest!.customerNumber }}
      </p>
      <p
        class="matching-box__content"
        estCustomerMatchingColoring="customerNumber"
        [differences]="matchData().differences"
      >
        {{ matchData().matchResult?.customerNumber }}
      </p>

      <p class="matching-box__title">{{ "EMAIL" | translate }}:</p>
      <p class="matching-box__content" estCustomerMatchingColoring="email" [differences]="matchData().differences">
        {{ matchData().matchRequest!.email }}
      </p>
      <p class="matching-box__content" estCustomerMatchingColoring="email" [differences]="matchData().differences">
        {{ matchData().matchResult?.email }}
      </p>

      <p class="matching-box__title">{{ "PHONE" | translate }}:</p>
      <p
        class="matching-box__content"
        estCustomerMatchingColoring="phoneNumber"
        [differences]="matchData().differences"
      >
        {{ matchData().matchRequest!.phoneNumber }}
      </p>
      <p
        class="matching-box__content"
        estCustomerMatchingColoring="phoneNumber"
        [differences]="matchData().differences"
      >
        {{ matchData().matchResult?.phoneNumber }}
      </p>

      <p class="matching-box__title">{{ "BIRTHDAY" | translate }}:</p>
      <p class="matching-box__content" estCustomerMatchingColoring="birthday" [differences]="matchData().differences">
        {{ matchData().matchRequest!.birthday }}
      </p>
      <p class="matching-box__content" estCustomerMatchingColoring="birthday" [differences]="matchData().differences">
        {{ matchData().matchResult?.birthday }}
      </p>

      <p class="matching-box__title">{{ "SOCIAL_SECURITY_NUMBER" | translate }}:</p>
      <div class="flex items-center gap-2">
        <p class="matching-box__content" estCustomerMatchingColoring="pin" [differences]="matchData().differences">
          {{ oldPin() }}
        </p>
        @if (oldPin()) {
          <button (click)="isOldPinVisible.set(!isOldPinVisible())">
            <img
              [alt]="(isOldPinVisible() ? 'PIN.HIDE_PIN' : 'PIN.SHOW_PIN') | translate"
              [src]="isOldPinVisible() ? 'assets/images/icons/eye.svg' : 'assets/images/icons/eye-dimmed.svg'"
            />
          </button>
        }
      </div>
      <div class="flex items-center gap-2">
        <p class="matching-box__content" estCustomerMatchingColoring="pin" [differences]="matchData().differences">
          {{ newPin() }}
        </p>
        @if (newPin()) {
          <button (click)="isNewPinVisible.set(!isNewPinVisible())">
            <img
              [alt]="(isNewPinVisible() ? 'PIN.HIDE_PIN' : 'PIN.SHOW_PIN') | translate"
              [src]="isNewPinVisible() ? 'assets/images/icons/eye.svg' : 'assets/images/icons/eye-dimmed.svg'"
            />
          </button>
        }
      </div>

      <p class="matching-box__title">{{ "PROPERTIES.ADDRESS" | translate }}:</p>
      <p
        class="matching-box__content"
        [estCustomerMatchingColoring]="['houseNumber', 'streetName']"
        [differences]="matchData().differences"
      >
        {{ oldAddress() }}
      </p>
      <p
        class="matching-box__content"
        [estCustomerMatchingColoring]="['houseNumber', 'streetName']"
        [differences]="matchData().differences"
      >
        {{ newAddress() }}
      </p>

      <p class="matching-box__title">{{ "ADDRESS.ZIP_CODE" | translate }}:</p>
      <p class="matching-box__content" estCustomerMatchingColoring="zipCode" [differences]="matchData().differences">
        {{ matchData().matchRequest!.addressInformation.zipCode }}
      </p>
      <p class="matching-box__content" estCustomerMatchingColoring="zipCode" [differences]="matchData().differences">
        {{ matchData().matchResult?.addressInformation?.zipCode }}
      </p>

      <p class="matching-box__title">{{ "ADDRESS.CITY" | translate }}:</p>
      <p class="matching-box__content" estCustomerMatchingColoring="city" [differences]="matchData().differences">
        {{ matchData().matchRequest!.addressInformation.city }}
      </p>
      <p class="matching-box__content mb-3" estCustomerMatchingColoring="city" [differences]="matchData().differences">
        {{ matchData().matchResult?.addressInformation?.city }}
      </p>
      <div class="col-span-2">
        <div class="flex items-center font-light gap-4 text-[0.625rem] mb-1">
          <div class="bg-success rounded-full w-3 h-3"></div>
          <p>{{ "CUSTOMERS.DETAILS_ARE_THE_SAME_MESSAGE" | translate }}</p>
        </div>
        <div class="flex items-center font-light gap-4 text-[0.625rem]">
          <div class="bg-error rounded-full w-3 h-3"></div>
          <p>{{ "CUSTOMERS.DETAILS_NOT_THE_SAME_MESSAGE" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="journey-box">
      <p class="font-medium text-xs mb-2">{{ "CUSTOMERS.CUSTOMER_JOURNEYS" | translate }}:</p>
      <div class="flex flex-col gap-4">
        @for (journey of matchData().matchResult!.journeys; track journey.id) {
          <div class="flex gap-6">
            <div>
              <p class="text-xs font-medium">
                {{ "PROPERTIES.MAIN_DETAILS.STAGE" | translate }}:&nbsp;<span class="font-light">{{
                  CustomerJourneyStageTranslationMapper.get(journey.stage)! | translate
                }}</span>
              </p>
              <p class="text-xs font-medium">
                {{ "RESPONSIBLE_AGENT" | translate }}:&nbsp;<span class="font-light">{{
                  journey.responsibleAgent
                }}</span>
              </p>
              <p class="text-xs font-medium">
                {{ "EVENTS.LAST_ACTIVITY" | translate }}:&nbsp;<span class="font-light">{{
                  journey.lastActivity | customDate: true
                }}</span>
              </p>
            </div>
            <div>
              <p class="text-xs font-medium">
                {{ "PROPERTIES.PROPERTY" | translate }}:&nbsp;<span class="font-light">{{ journey.property }}</span>
              </p>
              <p class="text-xs font-medium">
                {{ "LINKED_UNITS.TABLE.UNIT_NO" | translate }}:&nbsp;<span class="font-light">{{
                  journey.unitNumber
                }}</span>
              </p>
              <p class="text-xs font-medium">
                {{ "EVENTS.CONTRACT_STATUS" | translate }}:&nbsp;<span class="font-light">{{
                  journey.contractStatus
                }}</span>
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
