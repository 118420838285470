import {
  booleanAttribute,
  Component,
  DestroyRef,
  inject,
  Input,
  input,
  OnInit,
  output,
  signal,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";
import { catchError, EMPTY, finalize, OperatorFunction, Subject, switchMap } from "rxjs";
import { IAddressInformation } from "../../data/market/addresses/models/address-information.model";
import { AddressService } from "../../data/market/addresses/services/address.service";
import { INVALID_VAT_NUMBER_ERROR_KEY, VAT_NUMBER_NOT_EXISTS_ERROR_KEY } from "../constants/error-keys.constants";
import { InputComponent } from "../input/input.component";
import { IAddressFormGroup } from "../interfaces/address-form-group.interface";
import { IAddressLookUp } from "../interfaces/address-look-up.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { SelectFieldComponent } from "../select-field/select-field.component";
import { SpinnerComponent } from "../spinner/spinner.component";
import {
  disableAllControlsInFormGroup,
  enableAllControlsInFormGroup,
  getControlFromAddressFormGroup,
  markControlAsTouchedAndDirty,
} from "../utilities/form.utilities";
import { ADDRESS_DOOR_OPTIONS, ADDRESS_SIDE_OPTIONS } from "./address.constants";
import { MappedAddressFormGroup } from "./mapped-address-form-group.type";

@Component({
  selector: "est-address",
  standalone: true,
  imports: [SelectFieldComponent, InputComponent, SpinnerComponent],
  templateUrl: "./address.component.html",
  styleUrl: "./address.component.scss",
})
export class AddressComponent implements OnInit {
  formGroup = input.required<FormGroup<IAddressFormGroup>>();
  lookups = input.required<IAddressLookUp<IDropdownOption>>();
  isVatOrCountryCodeChanged = false; // To prevent sending multiple requests on un-focus with no editing
  @Input() formattedAddressSignal: WritableSignal<string> = signal("");
  @Input() isLoadingSignal: WritableSignal<boolean> = signal(false);
  hasVat = input<boolean>(true);
  enableVatIfCountrySelected = input(true, { transform: booleanAttribute });
  protected readonly getControlFromAddressFormGroup = getControlFromAddressFormGroup;
  readonly #addressService = inject(AddressService);
  readonly #destroyRef = inject(DestroyRef);
  #getAddressInformationByVatSubject$ = new Subject<void>();
  ADDRESS_SIDE_OPTIONS = ADDRESS_SIDE_OPTIONS;
  ADDRESS_DOOR_OPTIONS = ADDRESS_DOOR_OPTIONS;

  selectedValueChange = output<{ name: string; value: number | string }>();

  readonly #getAddressInformationByVatSubscription$ = this.#getAddressInformationByVatSubject$
    .pipe(takeUntilDestroyed(), this.#loadAddressDataHttpWithSwitchMap())
    .subscribe((addressInformation: IAddressInformation) => {
      if (this.hasVat()) {
        this.#handleIncomingAddressInformation(addressInformation);
      }
    });

  ngOnInit(): void {
    // Run once initialized, then subscribe to future country code changes
    this.#enableVatOnlyIfCountrySelected();
    this.#toggleVatOnCountryChange();
  }

  getAddressByVatAndCountryCode(): void {
    if (!this.hasVat()) return;
    const vatNumber = getControlFromAddressFormGroup(this.formGroup(), "vat").value;
    if (vatNumber.trim().length === 0 || !this.isVatOrCountryCodeChanged) return;
    this.#disableFormGroupExceptVatNumber();
    this.isVatOrCountryCodeChanged = false;
    this.#loadAddressData();
  }

  #disableFormGroupExceptVatNumber(): void {
    disableAllControlsInFormGroup(this.formGroup(), "vat");
  }

  #loadAddressData(): void {
    this.#getAddressInformationByVatSubject$.next();
  }

  #handleIncomingAddressInformation(addressInformation: IAddressInformation): void {
    enableAllControlsInFormGroup(this.formGroup());
    if (addressInformation.isValid) {
      this.#mapAddressInformationIntoAddressFormGroup(addressInformation);
      this.formattedAddressSignal.set(addressInformation.formattetAddress);
      this.selectedValueChange.emit({
        name: "vat",
        value: getControlFromAddressFormGroup(this.formGroup(), "vat").value,
      });
    } else {
      this.#invalidateVatControl(VAT_NUMBER_NOT_EXISTS_ERROR_KEY);
      this.formattedAddressSignal.set("");
    }
  }

  emitCountryChanged() {
    this.isVatOrCountryCodeChanged = true;
    this.getAddressByVatAndCountryCode();
  }

  #finalizeAddressLoading(): OperatorFunction<any, any> {
    return finalize(() => {
      this.isLoadingSignal.set(false);
    });
  }

  #loadAddressDataHttpWithSwitchMap(): OperatorFunction<any, any> {
    return switchMap(() => {
      this.isLoadingSignal.set(true);
      return this.#addressService
        .getAddressInformationByVat(
          getControlFromAddressFormGroup(this.formGroup(), "vat").value,
          getControlFromAddressFormGroup(this.formGroup(), "countryId").value,
        )
        .pipe(this.#finalizeAddressLoading(), this.#handleLoadAddressHttpError());
    });
  }

  #handleLoadAddressHttpError(): OperatorFunction<any, any> {
    return catchError(() => {
      enableAllControlsInFormGroup(this.formGroup());
      this.#invalidateVatControl(INVALID_VAT_NUMBER_ERROR_KEY);
      this.formattedAddressSignal.set("");
      return EMPTY;
    });
  }

  #toggleVatOnCountryChange(): void {
    getControlFromAddressFormGroup(this.formGroup(), "countryId")
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#enableVatOnlyIfCountrySelected();
      });
  }

  #enableVatOnlyIfCountrySelected(): void {
    if (!this.hasVat()) return;
    const countryCode = getControlFromAddressFormGroup(this.formGroup(), "countryId").value;
    if (countryCode && this.enableVatIfCountrySelected()) {
      getControlFromAddressFormGroup(this.formGroup(), "vat").enable();
    } else {
      getControlFromAddressFormGroup(this.formGroup(), "vat").disable();
    }
  }

  #mapAddressInformationIntoAddressFormGroup(addressInformation: IAddressInformation): void {
    const mappedAddressInformation: MappedAddressFormGroup = {
      ...addressInformation,
      vat: addressInformation.vatNumber,
      companyName: addressInformation.name,
      streetName: addressInformation.street,
      houseNumber: addressInformation.houseNumber,
      zipCode: addressInformation.postalCode,
      countryId: addressInformation.countryCode,
      city: addressInformation.city,
    };
    this.formGroup().patchValue(mappedAddressInformation);
  }

  #invalidateVatControl(errorKey: string): void {
    const vatControl = getControlFromAddressFormGroup(this.formGroup(), "vat");
    markControlAsTouchedAndDirty(vatControl);
    vatControl.setErrors({ [errorKey]: true });
  }
}
