import { Component, inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { NgComponentOutlet, NgOptimizedImage } from "@angular/common";
import { ISnackbarData } from "../interfaces/snackbar-data.interface";
import { SnackbarType } from "../enums/snackbar-type.enum";
import { TranslateModule } from "@ngx-translate/core";
import { SnackbarTranslateKeyMapper, SnackbarImgSourceMapper } from "./snackbar.mappers";

@Component({
  selector: "est-snackbar",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, NgComponentOutlet],
  templateUrl: "./snackbar.component.html",
  styleUrl: "./snackbar.component.scss",
})
export class SnackbarComponent {
  snackBarData: ISnackbarData = inject(MAT_SNACK_BAR_DATA);
  protected readonly SnackbarType = SnackbarType;
  protected readonly SnackbarImgSourceMapper = SnackbarImgSourceMapper;
  protected readonly SnackbarTranslateKeyMapper = SnackbarTranslateKeyMapper;
}
