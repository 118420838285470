export const getFileSize = (file: File) => {
  let fileSize = Math.ceil(file.size / 1024);
  if (fileSize > 1024) {
    fileSize = Math.ceil(fileSize / 1024);
    return `${fileSize} MB`;
  }
  return `${fileSize} KB`;
};

export const getImageFileDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const imageDataUrl = URL.createObjectURL(file);
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.src = imageDataUrl;
  });
};

export const getVideoFileDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const videoDataUrl = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = videoDataUrl;
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    };
  });
};

export const getFileExtension = (fileName: string): string => {
  const mediaFileSplit = fileName.split(".");
  return mediaFileSplit[mediaFileSplit.length - 1].toUpperCase();
};

export const downloadFileBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
