import { Component, inject, Input, input, output, signal, WritableSignal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { GeographicalLocationService } from "../../data/external-services/geographical-location/services/geographical-location.service";
import { InputComponent } from "../input/input.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { ButtonComponent } from "../button/button.component";
import { IGeographicLocation } from "../../data/external-services/geographical-location/models/geographic-location.model";

@Component({
  selector: "est-geographical-location",
  standalone: true,
  imports: [InputComponent, TranslateModule, MatError, MatIcon, ButtonComponent],
  templateUrl: "./geographical-location.component.html",
  styleUrl: "./geographical-location.component.scss",
})
export class GeographicalLocationComponent {
  @Input() isLoadingSignal: WritableSignal<boolean> = signal(false);
  @Input() formattedAddressSignal: WritableSignal<string> = signal("");
  isGeoLocationUpdated = output<boolean>();
  longitudeControl = input.required<FormControl<string>>();
  latitudeControl = input.required<FormControl<string>>();
  errorMessage: string | null = null;
  readonly #geographicalLocationService = inject(GeographicalLocationService);

  getLocationIfAddressValid(): void {
    if (this.formattedAddressSignal()) {
      this.errorMessage = null;
      this.#disableControls();
      this.#getGeographicalAddress();
    } else {
      this.errorMessage = "VALIDATION.PROVIDE_VALID_VAT_AND_ADDRESS";
    }
  }

  #getGeographicalAddress(): void {
    this.isLoadingSignal.set(true);
    this.#geographicalLocationService.getGeographicalLocation(this.formattedAddressSignal()).subscribe({
      next: (location: IGeographicLocation) => {
        this.longitudeControl().setValue(location.lon);
        this.latitudeControl().setValue(location.lat);
        this.longitudeControl().markAsDirty();
        this.latitudeControl().markAsDirty();
        this.isLoadingSignal.set(false);
        this.#enableControls();
        this.isGeoLocationUpdated.emit(true);
      },
      error: (error: Error) => {
        this.errorMessage = error.message;
        this.isLoadingSignal.set(false);
        this.#enableControls();
      },
    });
  }

  #disableControls(): void {
    this.latitudeControl().disable();
    this.longitudeControl().disable();
  }

  #enableControls(): void {
    this.latitudeControl().enable();
    this.longitudeControl().enable();
  }
}
