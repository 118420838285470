import { Component, input, OnInit, output } from "@angular/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { CustomerType } from "@root/data/market/legalEntities/enums/customer-type.enum";
import { CustomerTypeTranslationMapper } from "@root/data/market/legalEntities/mappers/customer-type-translation.mapper";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { InputComponent } from "@root/shared/input/input.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { ADDRESS_DOOR_OPTIONS, ADDRESS_SIDE_OPTIONS } from "@root/shared/address/address.constants";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  castControlFromAbstractToFormArray,
  castControlFromAbstractToFormControl,
  castControlFromAbstractToFormGroup,
} from "@root/shared/utilities/form.utilities";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { HeartButtonComponent } from "@root/shared/heart-button/heart-button.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { PinInputComponent } from "@root/shared/pin-input/pin-input.component";
import { ICountry } from "@root/shared/interfaces/country.interface";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { SelectDemographicQuestionButtonComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/components/select-demographic-question-button/select-demographic-question-button.component";
import { SearchCustomersBarComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/components/search-customers-bar/search-customers-bar.component";
import { CdkScrollable } from "@angular/cdk/overlay";
import { ISearchCustomer } from "@root/data/market/legalEntities/models/search-customer.model";

@Component({
  selector: "est-customer-information",
  standalone: true,
  imports: [
    SelectFieldComponent,
    SmallHeaderComponent,
    InputComponent,
    PhoneInputComponent,
    HeartButtonComponent,
    ButtonComponent,
    DatepickerComponent,
    LanguageDropdownComponent,
    MatSlideToggle,
    TranslateModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgClass,
    PinInputComponent,
    SelectDemographicQuestionButtonComponent,
    SearchCustomersBarComponent,
    CdkScrollable,
  ],
  templateUrl: "./customer-information.component.html",
  styleUrl: "./customer-information.component.scss",
})
export class CustomerInformationComponent implements OnInit {
  customerInformationControlNames = [
    "customerType",
    "addressInformation",
    "firstName",
    "lastName",
    "email",
    "preferredLanguageId",
    "phoneNumber",
    "phoneIsdCode",
  ];
  showSettings = input<boolean>(true);
  valuesChanged = output<void>();
  reloadRequest = output<void>();
  hasSearchBar = input<boolean>(false);
  formGroup = input.required<FormGroup>();
  demographicQuestions = input.required<IDemographicQuestionLookUp[]>();
  addressLookUps = input.required<IAddressLookUp<IDropdownOption>>();
  phoneControl = input.required<FormControl<IPhoneNumber>>();
  customerCategories = input.required<IDropdownOption[]>();
  isFavourite = input.required<FormControl<boolean>>();
  languages = input.required<ILanguage[]>();
  countries = input.required<ICountry[]>();
  legalEntityId = input<number>();
  collapsible = input<boolean>(false);
  selectedSearchCustomer: ISearchCustomer | null = null;
  customerTypeOptions = generateTranslatedDropdownOptionsFromEnum(CustomerType, CustomerTypeTranslationMapper);
  protected collapsed = true;
  protected readonly CustomerType = CustomerType;
  protected readonly ADDRESS_SIDE_OPTIONS = ADDRESS_SIDE_OPTIONS;
  protected readonly ADDRESS_DOOR_OPTIONS = ADDRESS_DOOR_OPTIONS;
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected readonly castControlFromAbstractToFormGroup = castControlFromAbstractToFormGroup;
  protected readonly castControlFromAbstractToFormArray = castControlFromAbstractToFormArray;

  ngOnInit(): void {
    this.collapsed = this.collapsible();
  }

  get getCustomerType(): CustomerType {
    return this.formGroup().controls["customerType"].value as CustomerType;
  }

  get getCollapsedButtonText(): string {
    return this.collapsed ? "ADDITIONAL_DETAILS.SHOW" : "ADDITIONAL_DETAILS.HIDE";
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  formatBirthday() {
    const birthday = this.formGroup().controls["birthday"].value;
    this.formGroup().controls["birthday"].setValue(birthday);
    this.valuesChanged.emit();
  }

  selectedCustomerHandle(selectedCustomer: ISearchCustomer | null) {
    this.selectedSearchCustomer = selectedCustomer;

    if (selectedCustomer) {
      this.disableCustomerInformationControls();
    } else {
      this.formGroup().enable();
    }

    this.valuesChanged.emit();
  }

  private disableCustomerInformationControls() {
    this.customerInformationControlNames.forEach((controlName) => {
      this.formGroup().get(controlName)?.disable();
    });
  }
}
