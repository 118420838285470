import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { APIS } from "@root/core/api/market.apis";
import { Observable, shareReplay } from "rxjs";
import { ILookUpItem } from "../models/look-up-item.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { ICountry } from "@root/shared/interfaces/country.interface";

@Injectable({
  providedIn: "root",
})
export class LookupsRepository {
  readonly #http = inject(HttpClient);
  #addressLookups$ = this.#http.get<IAddressLookUp<ILookUpItem>>(APIS.lookups.getDivisions).pipe(shareReplay(1));
  #cacheAllCurrenciesLookup$ = this.#http.get<ILookUpItem[]>(APIS.lookups.getAllCurrenciesLookup).pipe(shareReplay(1));

  getAllCurrenciesLookup(): Observable<ILookUpItem[]> {
    return this.#cacheAllCurrenciesLookup$;
  }
  getAddressLookups(): Observable<IAddressLookUp<ILookUpItem>> {
    return this.#addressLookups$;
  }

  getUsersLookUpsInDivision(params: IPaginationPayload): Observable<IPaginatedResponse<ILookUpItem>> {
    return this.#http.get<IPaginatedResponse<ILookUpItem>>(APIS.lookups.getUsers, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getCompaniesLookups(params: IPaginationPayload): Observable<IPaginatedResponse<ILookUpItem>> {
    return this.#http.get<IPaginatedResponse<ILookUpItem>>(APIS.lookups.getCompanies, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getHousingTypeLookup(): Observable<ILookUpItem[]> {
    return this.#http.get<ILookUpItem[]>(APIS.lookups.getHousingTypeLookup);
  }
  getUnitTypesLookup(): Observable<ILookUpItem[]> {
    return this.#http.get<ILookUpItem[]>(APIS.lookups.getUnitTypesLookup);
  }
  getUnitSalesTypeLookup(): Observable<ILookUpItem[]> {
    return this.#http.get<ILookUpItem[]>(APIS.lookups.getUnitSalesTypeLookup);
  }
  getAllAgentsLookup(id: number): Observable<ILookUpItem[]> {
    return this.#http.get<ILookUpItem[]>(APIS.lookups.allAgents, {
      params: new HttpParams({
        fromObject: { propertyId: id },
      }),
    });
  }

  getSystemAdminsInOrganisation(params: IPaginationPayload): Observable<IPaginatedResponse<ILookUpItem>> {
    return this.#http.get<IPaginatedResponse<ILookUpItem>>(APIS.user.getSystemAdminsInOrganisation, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getAllCountries(): Observable<ICountry[]> {
    return this.#http.get<ICountry[]>(APIS.getAllCountries);
  }
}
