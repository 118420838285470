import { inject, Injectable, signal } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IGetAvailableResponsibleAgentsLookupsQuery } from "@root/data/market/customer-journeys/queries/get-available-responsible-agents-lookups.query";
import { CustomerJourneysRepository } from "@root/data/market/customer-journeys/repositories/customer-journeys.repository";
import { mapCustomerJourneysSmallListTableInputOperator } from "@root/data/market/customer-journeys/utilities/customer-journeys.utilities";
import { CustomerJourneyStageTranslationMapper } from "@root/data/market/properties/mappers/customer-journey-stage-translation.mapper";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { ICreateCustomerFollowUpTask } from "@root/views/main/property/property-events/customer-follow-up/create-customer-follow-up-task.interface";
import {
  CustomerFollowUpFormGroup,
  CustomerFollowUpTaskFormGroup,
} from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up-form-group.type";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerJourneysService {
  readonly #customerJourneysRepository = inject(CustomerJourneysRepository);
  readonly #fb = inject(FormBuilder);
  readonly #translateService = inject(TranslateService);

  getAllOptions(customerId: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#customerJourneysRepository.getAll(customerId).pipe(
      map((res) => ({
        ...mapToSignalPaginatedResponse(res),
        results: signal(
          res.results.map((journey) => ({
            label: `${CustomerJourneyStageTranslationMapper.get(journey.stage)} - ${
              journey.propertyRelation || this.#translateService.instant("CUSTOMERS.NO_PROPERTY_RELATION")
            }`,
            value: journey.id,
            translateKey: CustomerJourneyStageTranslationMapper.get(journey.stage),
          })),
        ),
      })),
    );
  }

  getCustomerJourneysWithParams(
    params: IPaginationSortPayload & {
      legalEntityId: number;
    },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#customerJourneysRepository
      .getCustomerJourneysWithParams(params)
      .pipe(mapCustomerJourneysSmallListTableInputOperator());
  }

  getFollowUpFormGroup(journeyId: number): Observable<CustomerFollowUpFormGroup> {
    return this.#customerJourneysRepository.getFollowUp(journeyId).pipe(
      map((followUp) => {
        return this.#fb.group({
          interestRating: this.#fb.control<number | null>(followUp.interestRating),
          legalEntityRating: this.#fb.control<number | null>(followUp.conversationRating),
          followUpStatusId: this.#fb.control<number | null>(followUp.followUpStatus?.id ?? null),
          followUpTasks: this.#fb.array<CustomerFollowUpTaskFormGroup>([]),
          responsibleAgentId: this.#fb.control<number | null>(followUp.responsibleAgent?.id ?? null),
          responsibleAgentName: this.#fb.control<string>(followUp.responsibleAgent?.value ?? "", {
            nonNullable: true,
          }),
          selectedMarketingQuestionsIds: this.#fb.control<number[]>(followUp.selectedMarketingQuestionIds, {
            nonNullable: true,
          }),
        });
      }),
    );
  }

  patchFollowUp(journeyId: number, body: { [key: string]: any }): Observable<void> {
    return this.#customerJourneysRepository.patchFollowUp(journeyId, body);
  }

  selectMarketingQuestion(journeyId: number, questionId: number): Observable<void> {
    return this.#customerJourneysRepository.selectMarketingQuestion(journeyId, questionId);
  }

  createFollowUpTask(journeyId: number, payload: ICreateCustomerFollowUpTask): Observable<void> {
    return this.#customerJourneysRepository.createFollowUpTask(journeyId, payload);
  }

  getAvailableResponsibleAgentsLookupsOptions(
    query: IGetAvailableResponsibleAgentsLookupsQuery,
  ): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#customerJourneysRepository.getAvailableResponsibleAgentsLookups(query).pipe(
      map((res) => ({
        ...mapToSignalPaginatedResponse(res),
        results: signal(
          res.results.map((agent) => ({
            label: agent.value,
            value: agent.id,
          })),
        ),
      })),
    );
  }
}
