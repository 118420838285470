import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-file-upload-counter",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./files-upload-counter.component.html",
  styleUrl: "./files-upload-counter.component.scss",
})
export class FilesUploadCounterComponent {
  totalFiles = input<number>();
}
