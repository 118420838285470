import { inject, Injectable } from "@angular/core";
import { LookupsRepository } from "../repositories/lookups.repository";
import { map, Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import {
  mapLookupItemsToDropdownOptions,
  mapLookupItemsToDropdownOptionsToLabelAndValue,
  mapLookupItemsToDropdownOptionsUnitSpecification,
  mapLookupItemToSignalDropdownOptionsOperator,
} from "../utilities/lookups.utilties";
import { IPaginationSelectedIdPayload } from "@root/shared/interfaces/pagination-selected-id-payload.interface";
import { ICountry } from "@root/shared/interfaces/country.interface";

@Injectable({
  providedIn: "root",
})
export class LookupsService {
  readonly #lookupsRepository = inject(LookupsRepository);

  getUsersLookUpsInDivision(
    params: IPaginationSelectedIdPayload & { divisionId?: number },
  ): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#lookupsRepository
      .getUsersLookUpsInDivision(params)
      .pipe(mapLookupItemToSignalDropdownOptionsOperator());
  }
  getAddressLookupOptions(): Observable<IAddressLookUp<IDropdownOption>> {
    return this.#lookupsRepository.getAddressLookups().pipe(
      map((lookUpsOptions) => {
        return {
          countries: mapLookupItemsToDropdownOptions(lookUpsOptions.countries),
          floors: mapLookupItemsToDropdownOptions(lookUpsOptions.floors),
          sides: mapLookupItemsToDropdownOptions(lookUpsOptions.sides),
        };
      }),
    );
  }

  getCompaniesLookupOptions(
    params: IPaginationSelectedIdPayload,
  ): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#lookupsRepository.getCompaniesLookups(params).pipe(mapLookupItemToSignalDropdownOptionsOperator());
  }

  getHousingTypeLookup(): Observable<IDropdownOption[]> {
    return this.#lookupsRepository.getHousingTypeLookup().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptionsUnitSpecification(lookUpsOptions);
      }),
    );
  }

  getUnitTypesLookup(): Observable<IDropdownOption[]> {
    return this.#lookupsRepository.getUnitTypesLookup().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptionsUnitSpecification(lookUpsOptions);
      }),
    );
  }

  getUnitSalesType(): Observable<IDropdownOption[]> {
    return this.#lookupsRepository.getUnitSalesTypeLookup().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptionsUnitSpecification(lookUpsOptions);
      }),
    );
  }

  getAllAgentsLookup(id: number): Observable<IDropdownOption[]> {
    return this.#lookupsRepository.getAllAgentsLookup(id).pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptionsToLabelAndValue(lookUpsOptions);
      }),
    );
  }

  getAllCurrenciesLookup(): Observable<IDropdownOption[]> {
    return this.#lookupsRepository.getAllCurrenciesLookup().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptions(lookUpsOptions);
      }),
    );
  }

  getSystemAdminsInOrganisation(
    params: IPaginationSelectedIdPayload,
  ): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#lookupsRepository
      .getSystemAdminsInOrganisation(params)
      .pipe(mapLookupItemToSignalDropdownOptionsOperator());
  }

  getAllCountries(): Observable<ICountry[]> {
    return this.#lookupsRepository.getAllCountries();
  }
}
