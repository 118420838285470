<div class="w-[38.5rem] max-lg:w-[32rem] mb-4 flex gap-4">
  <div class="flex-1">
    <est-input [control]="latitudeControl()" appliedClass="w-full" label="ADDRESS.LATITUDE" type="number"></est-input>
  </div>
  <div class="flex-1">
    <est-input [control]="longitudeControl()" appliedClass="w-full" label="ADDRESS.LONGITUDE" type="number"></est-input>
  </div>
</div>
<est-button
  (click)="getLocationIfAddressValid()"
  [isLoading]="isLoadingSignal()"
  appliedClasses="uppercase text-sm font-semibold !px-6"
  buttonStyleType="button--secondary"
  buttonType="button"
  label="PROPERTIES.FORM.CALCULATE_FROM_ADDRESS"
/>
@if (errorMessage) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ errorMessage | translate }}
  </mat-error>
}
