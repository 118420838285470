<div class="pt-6 px-6 relative">
  <div class="flex gap-2 items-center">
    <p class="text-xl font-medium mb-1">
      {{ matDialogData.title | translate }}
    </p>
    @if (matDialogData.tooltipLabel) {
      <est-tooltip [tooltip]="matDialogData.tooltipLabel"></est-tooltip>
    }
  </div>
  <div class="absolute start-0 end-0">
    <hr class="mt-3 border-black-100" />
  </div>
  <div class="mt-8 w-96">
    @for (description of matDialogData.descriptions | slice: 0 : 2; track description) {
      <p class="pb-6 font-light text-sm" [innerHTML]="description | translate"></p>
    }
    @if (matDialogData.isInputIncluded) {
      <est-input [label]="matDialogData.inputLabel ?? ''"></est-input>
    }
    @if (matDialogData.descriptions.length > 2) {
      @for (description of matDialogData.descriptions | slice: 2; track description) {
        <p class="pb-6 font-light text-sm" [innerHTML]="description | translate"></p>
      }
    }
  </div>
</div>
<div class="flex justify-between bg-black-50 px-6 py-2.5 border-t border-black-300">
  <est-button
    appliedClasses="uppercase !px-6 !py-3"
    [label]="matDialogData.cancelLabel ?? 'COLD_FEET'"
    buttonStyleType="button--tertiary"
    [isLoading]="false"
    buttonType="button"
    (click)="cancel()"
  ></est-button>
  <est-button
    appliedClasses="uppercase !px-6 !py-3"
    [label]="matDialogData.submitLabel ?? ''"
    buttonStyleType="button--primary"
    [isLoading]="matDialogData.isSubmitLoading ? matDialogData.isSubmitLoading() : false"
    buttonType="button"
    (click)="matDialogData.callBack ? matDialogData.callBack() : null"
  ></est-button>
</div>
