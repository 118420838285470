import { inject, Injectable } from "@angular/core";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import {
  constructCustomerFollowUpFormGroup,
  constructCustomerInformationFormGroup,
  constructSendInvitationFormGroup,
} from "@root/shared/utilities/customer.utilities";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";

@Injectable()
export class InviteCustomerOnsiteFormControls {
  readonly #fb = inject(NonNullableFormBuilder);

  form = this.#fb.group({
    id: this.#fb.control<number | null>(null),
    eventInstanceId: this.#fb.control<number | null>(null),
    eventTimeSlotId: this.#fb.control<number | null>(null),
    customer: constructCustomerInformationFormGroup(),
    followUp: constructCustomerFollowUpFormGroup(),
    notificationMethod: constructSendInvitationFormGroup(),
  });
  phone = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue());
  isFavourite = this.#fb.control(false);

  constructor() {
    this.form.controls.customer.value;
    (this.form.controls.customer as FormGroup).addControl(
      "responsibleAgentId",
      this.#fb.control<string>("", Validators.required),
    );
  }
}
