import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function latLongValidators(type: "lat" | "long"): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null; // consider empty value as valid
    let isValid = false;
    if (type === "lat") {
      isValid = value >= -90 && value <= 90;
    } else {
      isValid = value >= -180 && value <= 180;
    }
    return isValid ? null : { LAT_LONG_INVALID: true };
  };
}
