<est-select-search-field
  [appliedClass]="appliedClasses()"
  [control]="control()"
  [dropdownLabel]="dropDownLabel()"
  [dropdownOptions]="paginatedOptions.results()"
  [isLoading]="isLoading"
  [isNoMoreResults]="isNoMoreResults"
  [searchSignal]="searchSignal"
  [selectedDropdownOption]="initialResponsibleAgentOption"
></est-select-search-field>
