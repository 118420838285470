import { Component, EventEmitter, input, Output } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonToggleModule, MatButtonToggleGroup } from "@angular/material/button-toggle";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-button-list",
  standalone: true,
  imports: [
    MatFormFieldModule,
    TranslateModule,
    MatButtonToggleGroup,
    MatButtonToggleModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: "./button-list.component.html",
  styleUrl: "./button-list.component.scss",
})
export class ButtonListComponent {
  label = input.required<string>();
  control = input(new FormControl());
  options = input.required<IDropdownOption[]>();
  name = input("");
  appliedClass = input<string>("");
  @Output() selectionChanged = new EventEmitter<any>();
}
