<form [formGroup]="formGroup()">
  <div class="pb-6">
    <div class="flex gap-2 items-center mb-2">
      <p class="font-semibold">
        {{ "CUSTOMERS.INVITATION_SENT_TO_CUSTOMER" | translate }}
      </p>
      <est-tooltip tooltip="CUSTOMERS.INVITATION_SENT_TO_CUSTOMER"></est-tooltip>
    </div>
    @if (hasEventDetails()) {
      <div class="flex flex-col gap-4 mb-4 pt-6">
        <p class="font-medium">
          {{ "CUSTOMERS.SEND_INVITATION_SUBTITLE" | translate }}
        </p>
        <div class="flex gap-4">
          <est-datepicker
            [control]="formGroup().controls.eventDate"
            appliedClasses="!w-[9.375rem]"
            label="DATE"
          ></est-datepicker>
          <est-time-picker
            [control]="formGroup().controls.startTime"
            [isoDate]="formGroup().controls.eventDate.value"
            label="STARTING_TIME"
          ></est-time-picker>
          <est-time-picker
            [control]="formGroup().controls.endTime"
            [isoDate]="formGroup().controls.eventDate.value"
            label="END_TIME"
          ></est-time-picker>
        </div>
      </div>

      <p class="font-light mb-4">{{ "CUSTOMERS.EXTERNAL_TITLE_DESCRIPTION" | translate }}</p>
      <est-input
        [control]="formGroup().controls.externalTitle"
        appliedClass="w-[37.438rem] mb-4 max-breakpoint-ipad-screen:w-[30rem]"
        label="CUSTOMERS.EXTERNAL_EVENT_TITLE"
      ></est-input>

      <hr class="border-black-100 absolute start-0 end-0" />
    }
    <div class="flex flex-col flex-wrap gap-4 mb-4 pt-6">
      <est-chips
        appliedClass="w-[37.438rem] max-breakpoint-ipad-screen:w-[30rem]"
        [control]="formGroup().controls.recipientIds"
        [selectedOptions]="RECIPIENT_OPTION"
        [hasPopup]="hasPopup()"
        [isDisabled]="isRecipientsDisabled()"
        label="CUSTOMERS.TO_RECIPIENT"
      ></est-chips>
      <est-select-search-available-responsible-agents
        [journeyId]="journeyId()"
        [control]="formGroup().controls.responsibleUserId"
        [initialResponsibleAgentOption]="initialResponsibleAgentOption"
        [appliedClasses]="'w-[37.438rem] max-breakpoint-ipad-screen:w-[30rem]'"
        dropDownLabel="CUSTOMERS.NOTIFY_AGENTS"
      ></est-select-search-available-responsible-agents>
    </div>
    <hr class="border-black-100 absolute start-0 end-0" />
    <div class="py-6">
      <est-input
        [control]="formGroup().controls.subject"
        appliedClass="w-[51.25rem] mb-4 max-breakpoint-ipad-screen:w-[30rem]"
        label="CUSTOMERS.SUBJECT"
        aria-disabled="false"
      ></est-input>
      <est-text-area
        label="CUSTOMERS.MESSAGE"
        [control]="formGroup().controls.message"
        appliedClass="w-[51.25rem] h-[19rem] max-breakpoint-ipad-screen:w-[30rem]"
      ></est-text-area>
      <div class="text-sm text-black-400 font-light mt-1">
        <span> {{ "CUSTOMERS.EMAIL_TEMPLATE" | translate }} : </span>
        <span>{{ emailTemplate }}</span>
      </div>
    </div>
    <hr class="border-black-100 absolute start-0 end-0" />
    <div class="py-6">
      <div class="flex gap-2 items-center mb-2">
        <p class="font-semibold">
          {{ "CUSTOMERS.COMMUNICATION_METHOD" | translate }}
        </p>
        <est-tooltip tooltip="CUSTOMERS.COMMUNICATION_METHOD"></est-tooltip>
      </div>
      <div class="flex gap-4">
        <mat-slide-toggle [formControl]="formGroup().controls.communicationMethodEmail" class="mb-6 mt-1.5"
          >{{ "EMAIL" | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle [formControl]="formGroup().controls.communicationMethodMyPage" class="mb-6 mt-1.5"
          >{{ "SMS" | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle [formControl]="formGroup().controls.communicationMethodSMS" class="mb-6 mt-1.5"
          >{{ "SYSTEM_MODULES.MY_PAGE" | translate }}
        </mat-slide-toggle>
      </div>
      <p class="text-sm font-light">{{ "CUSTOMERS.COMMUNICATION_METHOD_DESCRIPTION" | translate }}</p>
    </div>
  </div>
</form>
