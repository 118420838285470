import { Component, input, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ButtonStyleType } from "@root/shared/button/button-style.type";
import { ImagePositionType } from "@root/shared/button/image-position.type";
import { NgClass } from "@angular/common";
import { MatDivider } from "@angular/material/divider";
import { MatOption } from "@angular/material/autocomplete";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatSelect, MatSelectTrigger } from "@angular/material/select";

@Component({
  selector: "est-button-dropdown",
  standalone: true,
  imports: [
    ButtonComponent,
    NgClass,
    MatDivider,
    MatOption,
    MatCheckbox,
    MatSelect,
    ReactiveFormsModule,
    MatSelectTrigger,
  ],
  templateUrl: "./button-dropdown.component.html",
  styleUrl: "./button-dropdown.component.scss",
})
export class ButtonDropdownComponent {
  label = input.required<string>();
  labelCaseSensitiveClass = input<string>("uppercase");
  imageSrc = input<string>();
  imageAlt = input<string>();
  appliedClasses = input<string>("");
  buttonStyleType = input.required<ButtonStyleType>();
  imagePosition = input<ImagePositionType>("before");
  buttonType = input.required<string>();
  options = input.required<IDropdownOption[]>();
  control = input<FormControl>(new FormControl());
  isMultiSelect = input<boolean>(false);

  @ViewChild("dropdownMenu") dropdownMenu!: MatSelect;

  protected toggleDropdown() {
    this.dropdownMenu.toggle();
  }
}
