import { Component, computed, EventEmitter, input, model, Output, Signal } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { inputErrorStateMatcher } from "@root/shared/input/error-handler";
import { inputID } from "@root/shared/input/input.types";
@Component({
  template: "",
  styles: "",
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class BaseInput {
  // Do not bind to value and provide a control in the same time. Control will override the binding
  label = input.required<string>();
  ariaLabel: Signal<string> = computed(() => `${this.label} label`);
  placeholder = input<string>("");
  control = input<FormControl>();
  name = input("");
  type = input<string>("text");
  appliedClass = input<string>("");
  id = input<inputID>();
  matcher = new inputErrorStateMatcher();
  limitCharacters: boolean = true;
  isRequired: boolean = false;
  removeWhiteSpaces = input<boolean>(true);
  disabledTrailingText = input<string>("");
  value = model<string>("");
  @Output() focusedOut: EventEmitter<string> = new EventEmitter();

  addRequiredToLabel() {
    return this.control()?.hasValidator(Validators.required) ? "REQUIRED" : "";
  }

  emitFocusOut(value: FocusEvent) {
    if (value.target instanceof HTMLInputElement) {
      this.focusedOut.emit(value.target.value);
    }
  }

  trimWhiteSpaceAtTheEnd($event: Event) {
    if (!this.removeWhiteSpaces()) {
      return;
    }
    if (this.control()) this.control()?.setValue(($event.target as HTMLInputElement).value.trim());
    else this.value.set(($event.target as HTMLInputElement).value.trim());
  }

  handleNumberInput(event: KeyboardEvent): void {
    if (this.type() !== "number") return;
    const invalidCharacters = ["e", "E", "+", "-"];
    invalidCharacters.includes(event.key) && event.preventDefault();
  }
}
