import { APIS } from "@root/core/api/market.apis";
import { SourceLevel } from "@root/shared/enums/source-level.enum";

export const SourceLevelDemographicQuestionsApiMapper = new Map<
  SourceLevel,
  {
    getAll: () => string;
    delete: (id: number) => string;
    toggleActivation: (id: number) => string;
    lookUp: (id?: string) => string;
  }
>([
  [
    SourceLevel.Organisation,
    {
      getAll: () => APIS.demographicQuestions.organisation.getAllDemographicQuestions,
      delete: (id) => APIS.demographicQuestions.organisation.deleteDemographicQuestion(id),
      toggleActivation: (id) => APIS.demographicQuestions.organisation.toggleActivation(id),
      lookUp: () => APIS.demographicQuestions.organisation.lookUp,
    },
  ],
]);
