import { map, OperatorFunction } from "rxjs";
import { ITeamLookup } from "@root/data/market/teams/models/team-lookup.model";
import { signal } from "@angular/core";
import { TeamStatusTranslationMapper } from "../mappers/team-status-translation.mapper";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";

export const mapTeamsToSignalSmallListTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<ITeamLookup>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedTeams) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedTeams),
      results: signal(mapTeamsToSmallListTableInput(paginatedTeams.results)),
    };
  });
};

const mapTeamsToSmallListTableInput = (teams: ITeamLookup[]): ISmallListTableInput[] => {
  return teams.map((team) => ({
    ...team,
    status: TeamStatusTranslationMapper.get(team.status)!,
    teamLeaders: team.teamLeaders || "NONE",
    assignedPropertiesNo: team.assignedProperties.length, // Retrieve the actual number to be used for popup styling
    assignedProperties: mapAssignedPropertiesToString(team.assignedProperties),
    textWithImageSrc: team.imageUrl,
  }));
};

const mapAssignedPropertiesToString = (assignedProperties: string[]): string => {
  if (assignedProperties.length === 0) return "-";
  let assignedPropertiesString = constructAssignedPropertiesString(assignedProperties);
  assignedPropertiesString += ` (${assignedProperties.length})`;
  return assignedPropertiesString;
};

const constructAssignedPropertiesString = (assignedProperties: string[]): string => {
  let assignedPropertiesString = "";
  for (const [index, assignedProperty] of assignedProperties.entries()) {
    if (index === 2) {
      assignedPropertiesString +=
        assignedProperty.length > 3 || assignedProperties.length > 3
          ? assignedProperty.substring(0, 3) + "..."
          : assignedProperty;
      break;
    }
    assignedPropertiesString += assignedProperty;
    if (index < assignedProperties.length - 1) {
      // Not last index
      assignedPropertiesString += ", ";
    }
  }
  return assignedPropertiesString;
};
