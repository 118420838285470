@if (formGroup && !isLoading) {
  <est-customer-follow-up
    (selectMarketingQuestion)="selectMarketingQuestion($event)"
    (valueChange)="updateJourneyFollowUp($event)"
    (taskCreate)="createFollowUpTask($event)"
    [formGroup]="formGroup"
    [journeyId]="journeyId()()!"
  ></est-customer-follow-up>
}
@if (isLoading) {
  <div class="relative min-h-[40dvh]">
    <est-loading-overlay></est-loading-overlay>
  </div>
}
