import { IDivisionOverview } from "../models/division-overview.model";
import { ILargeListTableInput } from "../../../../shared/interfaces/large-list-table-input.interface";
import { formatDateToShortString } from "../../../../shared/utilities/date.utilities";
import { ILargeListTableHeader } from "../../../../shared/interfaces/large-list-table-header.interface";
import { IUnitStatuses } from "../../../../shared/interfaces/unit-statuses-interface";
import { UnitStatus } from "../enums/unit-status.enum";

export const mapDivisionsOverviewToLargeListTableInput = (
  divisionsOverview: IDivisionOverview[],
): ILargeListTableInput[] => {
  return divisionsOverview.map((divisionOverview) => {
    const largeListTableInput: ILargeListTableInput = {
      id: divisionOverview.id,
      header: mapDivisionOverviewToTableHeader(divisionOverview),
      unitStatuses: mapDivisionOverviewToUnitStatuses(divisionOverview),
      properties: divisionOverview.properties,
      units: divisionOverview.units,
      activeUsers: divisionOverview.activeUsers,
      createdOn: formatDateToShortString(new Date(divisionOverview.createdOn)),
    };
    return largeListTableInput;
  });
};

const mapDivisionOverviewToTableHeader = (divisionOverview: IDivisionOverview): ILargeListTableHeader => {
  return {
    name: divisionOverview.name,
    imageUrl: divisionOverview.logoUrl,
    subheader: divisionOverview.owner,
  };
};

const mapDivisionOverviewToUnitStatuses = (divisionOverview: IDivisionOverview): IUnitStatuses => {
  return {
    availableNo: extractUnitStatusCountFromDivisionOverview(divisionOverview, UnitStatus.Available),
    inProcessNo: extractUnitStatusCountFromDivisionOverview(divisionOverview, UnitStatus.InProcess),
    completedNo: extractUnitStatusCountFromDivisionOverview(divisionOverview, UnitStatus.Completed),
    notActiveNo: extractUnitStatusCountFromDivisionOverview(divisionOverview, UnitStatus.NotActive),
  };
};

const extractUnitStatusCountFromDivisionOverview = (
  divisionOverview: IDivisionOverview,
  status: UnitStatus,
): number => {
  return divisionOverview.unitsOverview.find((unitOverview) => unitOverview.mainStatus === status)?.count || 0;
};
