import { Component, inject } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { SlicePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { IDialog } from "@root/shared/interfaces/dialog.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

@Component({
  selector: "est-warning-dialog",
  standalone: true,
  imports: [ButtonComponent, InputComponent, SlicePipe, TranslateModule, MatTooltip, TooltipComponent],
  templateUrl: "./warning-dialog.component.html",
  styleUrl: "./warning-dialog.component.scss",
})
export class WarningDialogComponent {
  matDialogData: IDialog = inject(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<WarningDialogComponent> = inject(MatDialogRef);

  protected cancel = () => this.dialogRef.close(false);
}
