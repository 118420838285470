import { Component, effect, inject, Input, input, OnInit, signal, WritableSignal } from "@angular/core";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatChipGrid, MatChipInput, MatChipRow } from "@angular/material/chips";
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from "@angular/material/autocomplete";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { Observable } from "rxjs";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { EditCustomerPopUpComponent } from "@root/shared/send-invitiation-to-customer/edit-customer-pop-up/edit-customer-pop-up.component";

@Component({
  selector: "est-chips",
  standalone: true,
  imports: [
    MatFormField,
    MatChipGrid,
    MatChipInput,
    MatChipRow,
    MatLabel,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    TranslateModule,
    NgClass,
  ],
  templateUrl: "./chips.component.html",
  styleUrl: "./chips.component.scss",
})
export class ChipsComponent implements OnInit {
  label = input.required<string>();
  minCharsToTriggerSearchingCall = input(2);
  appliedClass = input<string>("");
  control = input<FormControl<any[]>>(
    new FormControl([], {
      nonNullable: true,
    }),
  );
  selectedOptions = input<IDropdownOption[]>([]);
  isValueAlreadySelectedHashMap = input<Map<any, boolean>>(new Map());
  hasPopup = input<boolean>(false);
  matDialog = inject(MatDialog);
  isDisabled = input(false);
  @Input() options: IDropdownOption[] = [];
  @Input() searchingCall?: (search: string) => Observable<IDropdownOption[]>;
  @Input() searchSignal: WritableSignal<string> = signal("");

  readonly #searchingEffect$ = effect(() => {
    // Handles searching on its own if a service call is provided
    if (!this.searchingCall) return;
    if (this.searchSignal().length >= this.minCharsToTriggerSearchingCall()) {
      this.searchingCall?.call(this, this.searchSignal()).subscribe((res) => {
        this.options = res;
        res.forEach((op) => {
          if (!this.isValueAlreadySelectedHashMap().has(op.value))
            this.isValueAlreadySelectedHashMap().set(op.value, false);
        });
      });
    } else {
      this.options = [];
    }
  });

  ngOnInit() {
    this.#prefillOptionsAlreadyExistsHashMap();
  }

  addChip(option: IDropdownOption) {
    this.selectedOptions().push(option);
    this.control().value.push(option.value);
    this.isValueAlreadySelectedHashMap().set(option.value, true);
  }

  removeChip(option: IDropdownOption) {
    this.control().value.splice(this.control().value.indexOf(option.value), 1);
    this.selectedOptions().splice(
      this.selectedOptions().findIndex((op) => op.value === option.value),
      1,
    );
    this.isValueAlreadySelectedHashMap().set(option.value, false);
  }

  #prefillOptionsAlreadyExistsHashMap() {
    // Based on the control value
    this.control().value.forEach((val) => this.isValueAlreadySelectedHashMap().set(val, true));
  }

  openCustomerDetailsPopup(): void {
    this.matDialog.open(EditCustomerPopUpComponent, {
      data: {
        legalEntityId: this.selectedOptions()[0].value,
      },
      width: "28.125rem",
      height: "44.5rem",
    });
  }
}
