<form (ngSubmit)="editDocument()" [formGroup]="editDocumentFormGroup" class="relative">
  <div class="sticky top-0 bg-white z-10">
    <est-dialog-header
      [includeExitButton]="true"
      tooltipLabel="DOCUMENTS.EDIT_DOCUMENT_DETAILS"
      label="DOCUMENTS.EDIT_DOCUMENT_DETAILS"
    >
    </est-dialog-header>
  </div>
  @if (!isPageLoading) {
    <div class="max-h-[59vh] overflow-y-auto relative p-6">
      <div class="mb-5">
        <mat-label class="font-medium text-sm me-2">{{ "DOCUMENTS.FILE_NAME" | translate }}</mat-label>
        <button
          [matTooltip]="'DOCUMENTS.FILE_NAME' | translate"
          class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
        <est-input label="DOCUMENTS.FILE_NAME" [control]="name" appliedClass="!w-full !mt-2"></est-input>
      </div>
      <div class="mb-5">
        <mat-label class="font-medium text-sm me-2">{{ "DOCUMENTS.DOCUMENT_NAME" | translate }}</mat-label>
        <button
          [matTooltip]="'DOCUMENTS.DOCUMENT_NAME' | translate"
          class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
        <est-input label="DOCUMENTS.DOCUMENT_NAME" [control]="documentName" appliedClass="!w-full !mt-2"></est-input>
      </div>
      <div>
        <mat-label class="font-medium text-sm me-2">{{ "DOCUMENTS.DOCUMENT_TYPE" | translate }}</mat-label>
        <button
          [matTooltip]="'DOCUMENTS.DOCUMENTS.DOCUMENT_TYPE' | translate"
          class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
        <est-select-field
          [control]="documentTypeControl"
          [dropDownOptions]="documentTypes"
          [selectMultipleOptions]="false"
          appliedClass="!w-full !mt-2"
          dropDownLabel="DOCUMENTS.DOCUMENT_TYPE"
        ></est-select-field>
      </div>
      <div class="absolute start-0 end-0">
        <hr class="my-6 border-black-100" />
      </div>
      <mat-slide-toggle class="mt-11 mb-4" [formControl]="active">{{
        "DOCUMENTS.MAKE_IT_ACTIVE_IN_THE_SYSTEM" | translate
      }}</mat-slide-toggle>
      <p class="font-light text-sm">{{ "DOCUMENTS.DOCUMENT_ACTIVE_INHERITED" | translate }}</p>
    </div>

    <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky z-10 justify-between">
      <est-button
        [label]="'CANCEL'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="dialogRef.close()"
        buttonType="button"
        buttonStyleType="button--tertiary"
      >
      </est-button>

      <est-button
        [label]="'SAVE'"
        [appliedClasses]="'!py-3 !px-6'"
        buttonStyleType="button--primary"
        [isLoading]="isLoading"
        buttonType="submit"
      >
      </est-button>
    </div>
  } @else {
    <div class="p-52 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</form>
