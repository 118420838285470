import { inject, Injectable } from "@angular/core";
import { FollowUpStatusRepository } from "@root/data/market/system-configurations/repositories/follow-up-status.repository";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { Observable } from "rxjs/internal/Observable";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapFollowUpStatusToSignalResponseSmallTableInput } from "@root/data/market/system-configurations/follow-up-status/utilities/follow-up-status.utilities";
import { IFollowUpStatus } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-status.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FollowUpStatusService {
  readonly #followUpStatusRepository = inject(FollowUpStatusRepository);

  getFollowUpStatus(payload: IInheritedQueryPayload): Observable<IFollowUpStatus> {
    return this.#followUpStatusRepository.getFollowUpStatus(payload);
  }

  updateFollowUpStatus(payload: IInheritedQueryPayload, body: any): Observable<void> {
    return this.#followUpStatusRepository.updateFollowUpStatus(payload, body);
  }

  createFollowUpStatus(body: any, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#followUpStatusRepository.createFollowUpStatus(body, sourceLevel, routeId);
  }

  getPaginatedFollowUpStatuses(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<ISignalPaginatedResponse<IFollowUpStatus>> {
    return this.#followUpStatusRepository
      .getPaginatedFollowUpStatuses(params, sourceLevel, routeId)
      .pipe(mapFollowUpStatusToSignalResponseSmallTableInput());
  }

  deleteFollowUpStatus(payload: IInheritedQueryPayload): Observable<void> {
    return this.#followUpStatusRepository.deleteFollowUpStatus(payload);
  }

  toggleFollowUpStatusInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#followUpStatusRepository.toggleFollowUpStatusInheritance(payload);
  }

  getFollowUpStatusesLookups(): Observable<IDropdownOption[]> {
    return this.#followUpStatusRepository.getFollowUpStatusesLookups().pipe(
      map((res) => {
        return res.map((followUp) => ({ value: followUp.id, label: followUp.value }));
      }),
    );
  }

  getFollowUpTasksCategories(): Observable<IDropdownOption[]> {
    return this.#followUpStatusRepository.getFollowUpTasksCategories().pipe(
      map((res) => {
        return res.map((followUp) => ({ value: followUp.id, label: followUp.value }));
      }),
    );
  }
}
