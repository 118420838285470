import { Component, inject, input } from "@angular/core";
import { BaseCompositeSelectSearchFieldComponent } from "../abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { LookupsService } from "../../data/market/lookups/services/lookups.service";
import { SelectSearchFieldComponent } from "../select-search-field/select-search-field.component";

@Component({
  selector: "est-select-owner-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./select-owner-field.component.html",
  styleUrl: "./select-owner-field.component.scss",
})
export class SelectOwnerFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  lookupsService = inject(LookupsService);
  label = input.required<string>();

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.lookupsService.getUsersLookUpsInDivision({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
