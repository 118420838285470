import { IUnitStatuses } from "../../shared/interfaces/unit-statuses-interface";
import { UnitStatus } from "../market/division/enums/unit-status.enum";
import { IUnitOverview } from "../market/division/models/unit-overview.model";

export const mapUnitsOverviewToUnitStatuses = (unitsOverview: IUnitOverview[]): IUnitStatuses => {
  return {
    availableNo: extractUnitStatusCountFromUnitsOverview(unitsOverview, UnitStatus.Available),
    inProcessNo: extractUnitStatusCountFromUnitsOverview(unitsOverview, UnitStatus.InProcess),
    completedNo: extractUnitStatusCountFromUnitsOverview(unitsOverview, UnitStatus.Completed),
    notActiveNo: extractUnitStatusCountFromUnitsOverview(unitsOverview, UnitStatus.NotActive),
  };
};

const extractUnitStatusCountFromUnitsOverview = (unitsOverview: IUnitOverview[], status: UnitStatus): number => {
  return unitsOverview.find((unitOverview) => unitOverview.mainStatus === status)?.count || 0;
};
