import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EXTERNAL_SERVICES_APIS } from "../../../../core/api/external-services.apis";
import { Observable } from "rxjs";
import { IGeographicLocation } from "../models/geographic-location.model";

@Injectable({
  providedIn: "root",
})
export class GeographicalLocationRepository {
  readonly #http = inject(HttpClient);

  getGeographicalLocation(formattedAddress: string): Observable<IGeographicLocation[]> {
    return this.#http.get<IGeographicLocation[]>(EXTERNAL_SERVICES_APIS.geographicLocation, {
      params: new HttpParams({
        fromObject: { q: formattedAddress },
      }),
    });
  }
}
