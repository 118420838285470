import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IUser } from "../../users/models/user.model";
import { HttpClient } from "@angular/common/http";
import { APIS } from "../../../../core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class AuthRepository {
  readonly #http = inject(HttpClient);

  getLoggedInUser(): Observable<IUser> {
    return this.#http.get<IUser>(APIS.getLoggedInUser);
  }

  switchUserDivision(divisionId: number): Observable<void> {
    return this.#http.post<void>(APIS.switchDivision, { divisionId });
  }
}
