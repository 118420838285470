import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { ICustomerDetails } from "@root/data/market/legalEntities/models/customer-details.model";
import { IUpdateCustomerPayloadDto } from "@root/data/market/legalEntities/models/update-customer-payload-dto.model";
import { IEmailTemplate } from "@root/data/market/legalEntities/models/email-template.model";
import { ISearchCustomer } from "@root/data/market/legalEntities/models/search-customer.model";
import { IInviteCustomerOnsiteDto } from "@root/data/market/legalEntities/models/invite-customer-onsite-dto.model";

@Injectable({
  providedIn: "root",
})
export class LegalEntityRepository {
  readonly #http = inject(HttpClient);

  getCustomerDetails(id: number): Observable<ICustomerDetails> {
    return this.#http.get<ICustomerDetails>(APIS.legalEntities.getCustomerDetails(id));
  }

  patchCustomerDetails(id: number, customerDetails: Partial<ICustomerDetails>): Observable<void> {
    return this.#http.patch<void>(APIS.legalEntities.patchCustomerDetails(id), customerDetails);
  }

  favouriteCustomer(id: number): Observable<void> {
    return this.#http.post<void>(APIS.legalEntities.favouriteCustomer, id);
  }

  inviteCustomerOnsite(dto: IInviteCustomerOnsiteDto) {
    return this.#http.post<void>(APIS.legalEntities.inviteCustomerOnsite, dto);
  }

  updateCustomer(id: number, payload: IUpdateCustomerPayloadDto) {
    return this.#http.put<void>(APIS.legalEntities.updateLegalEntity(id), payload);
  }

  getEmailTemplate(id: number = 1): Observable<IEmailTemplate> {
    return this.#http.get<IEmailTemplate>(APIS.legalEntities.emails.getEmailTemplate(id));
  }

  updateLegalEntity(id: number, customerDetails: Partial<ICustomerDetails>): Observable<void> {
    return this.#http.put<void>(APIS.legalEntities.updateLegalEntity(id), customerDetails);
  }

  searchCustomers(search: string): Observable<ISearchCustomer[]> {
    return this.#http.get<ISearchCustomer[]>(APIS.legalEntities.searchCustomers, {
      params: new HttpParams({
        fromObject: { search },
      }),
    });
  }
}
