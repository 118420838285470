import { signal } from "@angular/core";
import { PropertyAccess } from "@root/data/market/properties/enums/property-access.enum";
import { IPropertyLookup } from "@root/data/market/properties/models/property-lookup.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginatedPropertyLookupResponse } from "@root/shared/interfaces/paginated-property-lookup-response.interface";
import { IPaginatedPropertyResponse } from "@root/shared/interfaces/paginated-property-response.interface";
import { ISignalPaginatedPropertyResponse } from "@root/shared/interfaces/signal-paginated-property-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IPropertyPetTypePolicy } from "@root/data/market/properties/models/property-pet-type-policy.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapUnitsPercentageString } from "@root/data/utilities/units-availability-percentage.utilities";
import { mapUnitsOverviewToUnitStatuses } from "@root/data/utilities/units-overview.utilities";
import { ISignalPaginatedPropertyLookupResponse } from "@root/shared/interfaces/signal-property-lookup-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { map, OperatorFunction } from "rxjs";
import { PropertyTypeTranslationMapper } from "../mappers/property-type-translation.mapper";
import { IPropertyOverview } from "../models/property-overview.model";
import { IUploadPropertyMedia } from "@root/views/main/property/property-marketing/components/medias/property-medias/upload-property-media.interface";

export const mapPropertiesToSignalLargeListInputOperator = (): OperatorFunction<
  IPaginatedPropertyResponse<IPropertyOverview>,
  ISignalPaginatedPropertyResponse<ILargeListTableInput>
> => {
  return map((paginatedProperties) => ({
    ...mapToSignalPaginatedResponse(paginatedProperties),
    results: signal(mapPropertiesToLargeListInput(paginatedProperties.results)),
    totalFavourites: signal(paginatedProperties.totalFavourites),
    totalPropertiesAccessible: signal(paginatedProperties.totalPropertiesAccessible),
  }));
};

export const mapPropertiesToSignalSmallListInputOperator = (): OperatorFunction<
  IPaginatedPropertyResponse<IPropertyOverview>,
  ISignalPaginatedPropertyResponse<ISmallListTableInput>
> => {
  return map((paginatedProperties) => ({
    ...mapToSignalPaginatedResponse(paginatedProperties),
    results: signal(mapPropertiesToSmallListInput(paginatedProperties.results)),
    totalFavourites: signal(paginatedProperties.totalFavourites),
    totalPropertiesAccessible: signal(paginatedProperties.totalPropertiesAccessible),
  }));
};

export const mapNotAllowedSpeciesIdsOperator = (): OperatorFunction<
  IPropertyPetTypePolicy,
  IPropertyPetTypePolicy & { notAllowedSpeciesIds: number[] }
> => {
  return map((paginatedProperties) => ({
    ...paginatedProperties,
    notAllowedSpeciesIds: paginatedProperties.notAllowedSpecies.map((species) => species.id),
  }));
};

export const mapPropertiesLookupsToSignalSmallListInputOperator = (): OperatorFunction<
  IPaginatedPropertyLookupResponse<IPropertyLookup>,
  ISignalPaginatedPropertyLookupResponse<ISmallListTableInput>
> => {
  return map((paginatedProperties) => ({
    ...mapToSignalPaginatedResponse(paginatedProperties),
    results: signal(mapPropertiesLookupsToSmallListTableInput(paginatedProperties.results)),
    accessedProperties: signal(paginatedProperties.accessedProperties),
    totalProperties: signal(paginatedProperties.totalProperties),
  }));
};

const mapPropertiesToLargeListInput = (properties: IPropertyOverview[]): ILargeListTableInput[] => {
  return properties.map((property) => ({
    ...property,
    idleness: mapUnitsPercentageString(property.idleUnits, property.idleness),
    availability: mapUnitsPercentageString(property.availableUnits, property.availability),
    propertyType: PropertyTypeTranslationMapper.get(property.propertyType)!,
    header: {
      name: property.name,
      imageUrl: property.imageUrl,
      subheader: property.address,
    },
    unitStatuses: mapUnitsOverviewToUnitStatuses(property.unitsOverview),
  }));
};

const mapPropertiesToSmallListInput = (properties: IPropertyOverview[]): ISmallListTableInput[] => {
  return properties.map((property) => ({
    ...property,
    propertyType: PropertyTypeTranslationMapper.get(property.propertyType)!,
    unitStatuses: mapUnitsOverviewToUnitStatuses(property.unitsOverview),
  }));
};

const mapPropertiesLookupsToSmallListTableInput = (properties: IPropertyLookup[]): ISmallListTableInput[] => {
  return properties.map((property) => ({
    ...property,
    checkmarkStatus:
      property.propertyAccess === PropertyAccess.Direct
        ? true
        : property.propertyAccess === PropertyAccess.Denied
          ? false
          : property.hasTeam,
    listingType: PropertyTypeTranslationMapper.get(property.listingType),
    unitsNumber: `${property.unitsNumber} UNITS`,
  }));
};

export const mapPropertiesLookupsToDropdownOptionsOperator = (): OperatorFunction<
  IPaginatedPropertyLookupResponse<IPropertyLookup>,
  ISignalPaginatedResponse<IDropdownOption>
> => {
  return map((paginatedProperties) => ({
    ...mapToSignalPaginatedResponse(paginatedProperties),
    results: signal(
      paginatedProperties.results.map((property) => ({
        label: property.name,
        value: property.id,
      })),
    ),
  }));
};

export const mapPropertyPetTypePoliciesToSmallListTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<IPropertyPetTypePolicy>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedProperties) => ({
    ...mapToSignalPaginatedResponse(paginatedProperties),
    results: signal(mapPropertyPetTypePoliciesToSmallListTableInput(paginatedProperties.results)),
  }));
};

export const mapPropertyPetTypePoliciesToSmallListTableInput = (
  propertyPetTypePolicies: IPropertyPetTypePolicy[],
): ISmallListTableInput[] => {
  return propertyPetTypePolicies.map((propertyPetTypePolicy) => ({
    ...propertyPetTypePolicy,
    active: propertyPetTypePolicy.isActive,
    applicationRequired: propertyPetTypePolicy.isApplicationRequired ? "YES" : "NO",
    sizeRestriction: propertyPetTypePolicy.isSizeRestricted ? "YES" : "NO",
    speciesRestriction: propertyPetTypePolicy.isSpeciesRestricted ? "YES" : "NO",
    mouseoverMenuInputs: {
      externalTitles: propertyPetTypePolicy.externalTitles,
      internalTitle: propertyPetTypePolicy.internalTitle,
      lastChangedDate: propertyPetTypePolicy.createdAt,
    },
  }));
};
export const convertMediaFilesToFormData = (formData: FormData, media: IUploadPropertyMedia[]) => {
  media.forEach((document, index) => {
    formData.append(`media[${index}].Title`, document.title);
    formData.append(`media[${index}].MediaType`, document.mediaType);
    formData.append(`media[${index}].SEOScore`, "Good");
    formData.append(`media[${index}].ImageQuality`, "Good");
    formData.append(`media[${index}].File`, document.file);
  });
};

export const areArraysEqual = (array1: number[], array2: number[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
};
