import { map, OperatorFunction } from "rxjs";
import { IFollowUpStatus } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-status.model";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";

export const mapFollowUpStatusToSignalResponseSmallTableInput = (): OperatorFunction<any, any> => {
  return map((paginatedResponse: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedResponse),
      results: signal(mapFollowUpStatusToSmallListTableInput(paginatedResponse.results)),
    };
  });
};

export const mapFollowUpStatusToSmallListTableInput = (followUpStatuses: IFollowUpStatus[]): ISmallListTableInput[] => {
  return followUpStatuses.map((followUpStatus) => {
    return {
      ...followUpStatus,
      source: followUpStatus.source ?? "-",
      id: followUpStatus.id ?? 0,
    };
  });
};
