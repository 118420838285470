import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IUser } from "../models/user.model";
import { UserStatus } from "../enums/user-status.enum";
import { IUserRole } from "../models/user-role.model";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { APIS } from "@root/core/api/market.apis";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { IEditPropertyAccessPayload } from "@root/data/market/users/models/edit-property-access-payload.model";
import { IUserWithPropertyAndTeam } from "@root/data/market/users/models/user-with-property-and-team.model";

@Injectable({
  providedIn: "root",
})
export class UsersRepository {
  readonly #http = inject(HttpClient);

  getPaginatedUsers(status: UserStatus, params?: IPaginationSortPayload): Observable<IPaginatedResponse<IUser>> {
    return this.#http.get<IPaginatedResponse<IUser>>(APIS.user.getAllUsers, {
      params: new HttpParams({
        fromObject: { ...params, status },
      }),
    });
  }

  createUser(userData: FormData): Observable<IUser> {
    return this.#http.post<IUser>(APIS.user.createUser, userData);
  }

  editUserPropertyAccess(body: IEditPropertyAccessPayload): Observable<void> {
    return this.#http.put<void>(APIS.user.editUserPropertyAccess, body);
  }

  getAllLanguages(): Observable<ILanguage[]> {
    return this.#http.get<ILanguage[]>(APIS.getAllLanguages);
  }

  getUserRoles(): Observable<IUserRole[]> {
    return this.#http.get<IUserRole[]>(APIS.getAllUserRoles);
  }

  getAllUsers(params: IPaginationPayload & { divisionId?: number }): Observable<IPaginatedResponse<IUser>> {
    return this.#http.get<IPaginatedResponse<IUser>>(APIS.user.getAllUsers, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  restoreUser(id: number): Observable<void> {
    return this.#http.post<void>(APIS.user.restore(id), {});
  }

  archiveUser(id: number): Observable<void> {
    return this.#http.post<void>(APIS.user.archive(id), {});
  }
  blockUser(id: number): Observable<void> {
    return this.#http.post<void>(APIS.user.block(id), {});
  }
  unblockUser(id: number): Observable<void> {
    return this.#http.post<void>(APIS.user.unblock(id), {});
  }

  getUserById(id: number): Observable<IUserWithPropertyAndTeam> {
    return this.#http.get<IUserWithPropertyAndTeam>(APIS.user.getUserById(id));
  }

  resendInvitation(id: number): Observable<void> {
    return this.#http.post<void>(APIS.user.resendInvitation(id), {});
  }

  editUser(userData: FormData, userId: number): Observable<void> {
    return this.#http.put<void>(APIS.user.editUser, userData, {
      params: new HttpParams({
        fromObject: { userId: userId },
      }),
    });
  }
}
