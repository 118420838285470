import { NgClass, NgOptimizedImage } from "@angular/common";
import { booleanAttribute, Component, EventEmitter, input, OnInit, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDivider } from "@angular/material/divider";
import { MatError, MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { ICountry } from "../interfaces/country.interface";
import { ILanguage } from "../interfaces/language.interface";

@Component({
  selector: "est-language-dropdown",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    MatDivider,
    MatError,
    MatIcon,
    FormsModule,
    NgOptimizedImage,
  ],
  templateUrl: "./language-dropdown.component.html",
  styleUrl: "./language-dropdown.component.scss",
})
export class LanguageDropdownComponent<T extends ILanguage | ICountry = ILanguage> implements OnInit {
  countries = input.required<T[]>();
  selectMultipleOptions = input(true, { transform: booleanAttribute });
  control = input.required<FormControl<string | T | null>>();
  selectWithLanguageId = input<boolean>(false);
  appliedClasses = input<string>();
  id = input<string>("select");
  withLabel = input(true);
  withLanguageName = input(true);
  withErrorMessage = input(true);
  protected readonly Validators = Validators;
  @Output() selectionChanged: EventEmitter<void> = new EventEmitter<void>();

  getSelectChange(name: string | T | null): T | undefined {
    if (this.selectWithLanguageId()) {
      return this.countries().find((value) => this.#getId(value) === name);
    }
    if (typeof name === "string") {
      if (this.selectWithLanguageId()) return this.countries().find((value) => this.#getId(value) === name);
      return this.countries().find((value) => value.name === name);
    } else if (name !== null && typeof name === "object") {
      return this.countries().find((value) => value.name === name.name);
    }
    return undefined;
  }

  getCountryValue(country: T): T | string {
    if (this.selectWithLanguageId()) {
      return this.#getId(country);
    }

    if (typeof this.control().value === "object") {
      return country;
    } else if (typeof this.control().value === "string") {
      if (this.selectWithLanguageId()) return this.#getId(country);
      return country.name;
    } else {
      return this.#getId(country);
    }
  }

  trackByFunction(item: T) {
    return this.getCountryValue(item);
  }

  protected compareWithFunction(option: T | string | number, value: T | string | number): boolean {
    if (typeof option === "object" && typeof value === "object") {
      return this.#getId(option) === this.#getId(value);
    } else {
      return option === value;
    }
  }

  ngOnInit() {
    this.getSelectChange(this.control()?.value);
  }

  #getId(value: ICountry | ILanguage): string {
    return (value as ILanguage).languageId || (value as ICountry).id;
  }
}
