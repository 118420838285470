<div [ngClass]="appliedClasses()" class="w-[9.375rem]">
  <mat-form-field
    [attr.aria-required]="isRequired()"
    [ngClass]="{ error: control().invalid && control().dirty && control().touched }"
    appearance="outline"
    class="w-[9.375rem]"
    id="timepicker"
  >
    @if (label()) {
      <mat-label>{{ label() | translate }} {{ requiredLabel() | translate }}</mat-label>
    }
    <input [formControl]="innerControl" class="!py-1 cursor-text" id="time" matInput type="time" />
  </mat-form-field>
  @if (control().hasError("required") && shouldShowControlRequiredError(this.control())) {
    <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
      <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
      {{ label() | translate }}
      {{ "VALIDATION.IS_REQUIRED" | translate }}
    </mat-error>
  }
</div>
