<div class="flex flex-col gap-4">
  <div class="flex gap-4 flex-wrap">
    @for (
      demographicQuestionAnswerFormGroup of demographicQuestionAnswerFormArray().controls;
      track demographicQuestionAnswerFormGroup
    ) {
      <est-demographic-question-answer
        (answerDemographicQuestion)="answerDemographicQuestion($event)"
        [control]="demographicQuestionAnswerFormGroup"
        [demographicQuestionLookUps]="demographicQuestionLookUps()"
      ></est-demographic-question-answer>
    }
  </div>
  <est-button-dropdown
    [control]="control()"
    [isMultiSelect]="true"
    [options]="dropdownOptions"
    buttonStyleType="button--secondary"
    buttonType="button"
    imagePosition="before"
    imageSrc="/assets/images/icons/plus-black.svg"
    label="CUSTOMER_CARD.CUSTOMER_DETAILS.DEMOGRAPHICS.ADD_FIELD"
  ></est-button-dropdown>
</div>
