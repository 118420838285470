import { Component, OutputEmitterRef, input, output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SelectFieldComponent } from "../select-field/select-field.component";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { generateTimeZoneDropDownOptions } from "../utilities/dropdown-options.utilities";

@Component({
  selector: "est-timezone-select-field",
  standalone: true,
  templateUrl: "./timezone-select-field.component.html",
  styleUrl: "./timezone-select-field.component.scss",
  imports: [SelectFieldComponent],
})
export class TimezoneSelectFieldComponent {
  control = input(new FormControl<number>(0));
  appliedClasses = input("");
  change: OutputEmitterRef<any> = output();
  timeZoneDropdownOptions: IDropdownOption[] = generateTimeZoneDropDownOptions();
}
