import { AfterViewInit, ChangeDetectorRef, Component, inject, input } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JsonPipe, NgClass } from "@angular/common";
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input-gg";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "../under-development/under-development.component";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { IPhoneNumber } from "../interfaces/phone-number.interface";
import { isElementCloseToBottom } from "../utilities/element-position.utilities";
import { NUMBER_OF_PX_TO_SHOW_DROPDOWN_TO_TOP } from "../constants/global.constants";

@Component({
  selector: "est-phone-input",
  standalone: true,
  imports: [
    FormsModule,
    JsonPipe,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    TranslateModule,
    UnderDevelopmentComponent,
    MatError,
    MatIcon,
    NgClass,
  ],
  templateUrl: "./phone-input.component.html",
  styleUrl: "./phone-input.component.scss",
})
export class PhoneInputComponent implements AfterViewInit {
  separateDialCode = input<boolean>(true);
  control = input<FormControl<IPhoneNumber>>(new FormControl());
  #changeDec = inject(ChangeDetectorRef);
  protected readonly SearchCountryField = SearchCountryField;
  protected readonly PhoneNumberFormat = PhoneNumberFormat;
  protected readonly CountryISO: any = CountryISO;
  protected readonly isElementCloseToBottom = isElementCloseToBottom;
  protected readonly NUMBER_OF_PX_TO_SHOW_DROPDOWN_TO_TOP = NUMBER_OF_PX_TO_SHOW_DROPDOWN_TO_TOP;

  get getCountyISO(): any {
    if (!this.control() || !this.control().value) return;
    if (this.control().value.countryCode === null) {
      return CountryISO.Denmark;
    }
    for (const [key, value] of Object.entries(this.CountryISO)) {
      if (value === this.control().value.countryCode.toLowerCase()) {
        return this.CountryISO[key];
      }
    }
  }

  ngAfterViewInit(): void {
    this.#changeDec.detectChanges();
  }
}
