import { Directive, ElementRef, inject, input, OnInit, Renderer2 } from "@angular/core";

/*
  @component
  @description Special utility directive for component matching. Responsible for coloring similar and unsimilar fields in old and new data.
 */
@Directive({
  selector: "[estCustomerMatchingColoring]",
  standalone: true,
})
export class CustomerMatchingColoringDirective implements OnInit {
  readonly #renderer = inject(Renderer2);
  readonly #element = inject(ElementRef);
  readonly propertyNames = input.required<string | string[]>({
    alias: "estCustomerMatchingColoring",
  });
  readonly differences = input.required<string[]>();

  ngOnInit(): void {
    if (!this.#isPropertyDifferent()) {
      this.#renderer.addClass(this.#element.nativeElement, "text-success");
    } else {
      this.#renderer.addClass(this.#element.nativeElement, "text-error");
    }
  }

  #isPropertyDifferent(): boolean {
    const differences = this.differences().map((d) => d.toLowerCase());
    if (typeof this.propertyNames() === "string")
      return differences.includes((this.propertyNames() as string).toLowerCase());
    else {
      let isDifferent = false;
      (this.propertyNames() as Array<string>).forEach((propertyName) => {
        if (differences.includes(propertyName.toLowerCase())) {
          isDifferent = true;
          return;
        }
      });
      return isDifferent;
    }
  }
}
