<div class="flex justify-between items-center">
  <mat-tab-group (selectedIndexChange)="selectedTabChange($event)">
    @for (tab of tabs(); track tab.label) {
      <mat-tab [label]="tab.label | translate"></mat-tab>
    }
  </mat-tab-group>
  <div class="pe-6">
    <ng-content></ng-content>
  </div>
</div>

<hr class="border-black-100 sticky start-0 end-0" />
@if (selectedTab().component) {
  <div class="p-6">
    <ng-container *ngComponentOutlet="selectedTab().component!!; inputs: componentInputs()"></ng-container>
  </div>
}
