import { inject, Injectable } from "@angular/core";
import { MarketingQuestionsRepository } from "@root/data/market/marketing-questions/repositories/marketing-questions.repository";
import { Observable } from "rxjs/internal/Observable";
import { IMarketingQuestion } from "@root/data/market/marketing-questions/models/marketing-question.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapMarketingQuestionsToSignalResponseSmallTableInput } from "@root/data/market/marketing-questions/utilities/marketing-questions.utilities";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { IMarketingQuestionLookup } from "@root/data/market/marketing-questions/models/marketing-question-lookup.model";

@Injectable({
  providedIn: "root",
})
export class MarketingQuestionsService {
  readonly #marketingQuestionsRepository = inject(MarketingQuestionsRepository);

  getMarketingQuestion(payload: IInheritedQueryPayload): Observable<IMarketingQuestion> {
    return this.#marketingQuestionsRepository.getMarketingQuestion(payload);
  }

  updateMarketingQuestion(payload: IInheritedQueryPayload, body: any): Observable<void> {
    return this.#marketingQuestionsRepository.updateMarketingQuestion(payload, body);
  }

  createMarketingQuestion(body: any, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#marketingQuestionsRepository.createMarketingQuestion(body, sourceLevel, routeId);
  }

  getPaginatedMarketingQuestions(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<ISignalPaginatedResponse<IMarketingQuestion>> {
    return this.#marketingQuestionsRepository
      .getPaginatedMarketingQuestions(params, sourceLevel, routeId)
      .pipe(mapMarketingQuestionsToSignalResponseSmallTableInput());
  }

  deleteMarketingQuestion(payload: IInheritedQueryPayload): Observable<void> {
    return this.#marketingQuestionsRepository.deleteMarketingQuestion(payload);
  }

  toggleMarketingQuestionInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#marketingQuestionsRepository.toggleMarketingQuestionInheritance(payload);
  }

  toggleBlockedMarketingQuestion(payload: IInheritedQueryPayload): Observable<void> {
    return this.#marketingQuestionsRepository.toggleBlockedMarketingQuestion(payload);
  }

  getMarketingQuestionsLookups(): Observable<IMarketingQuestionLookup[]> {
    return this.#marketingQuestionsRepository.getMarketingQuestionsLookups();
  }
}
