import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { IPropertyOverview } from "@root/data/market/properties/models/property-overview.model";
import { IPaginatedPropertyResponse } from "@root/shared/interfaces/paginated-property-response.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { Observable } from "rxjs";
import { IDivisionDetails } from "../models/division-details.model";
import { IDivisionOption } from "../models/division-option.model";
import { IDivisionOverview } from "../models/division-overview.model";

@Injectable({ providedIn: "root" })
export class DivisionRepository {
  readonly #http = inject(HttpClient);

  getPaginatedDivisionsOverview(params?: IPaginationSortPayload): Observable<IPaginatedResponse<IDivisionOverview>> {
    return this.#http.get<IPaginatedResponse<IDivisionOverview>>(APIS.divisions.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getPaginatedDivisionProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType | "";
    },
  ): Observable<IPaginatedPropertyResponse<IPropertyOverview>> {
    return this.#http.get<IPaginatedPropertyResponse<IPropertyOverview>>(APIS.divisions.getProperties, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  createNewDivision(divisionFormData: FormData): Observable<FormData> {
    return this.#http.post<FormData>(APIS.divisions.create, divisionFormData);
  }

  getPaginatedDivisionsOptions(params: IPaginationPayload): Observable<IPaginatedResponse<IDivisionOption>> {
    return this.#http.get<IPaginatedResponse<IDivisionOption>>(APIS.divisions.listAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getDivisionDetailsById(divisionId?: number): Observable<IDivisionDetails> {
    let params = new HttpParams();
    if (divisionId !== undefined) {
      params = params.set("divisionId", divisionId.toString());
    }
    return this.#http.get<IDivisionDetails>(APIS.divisions.getById, { params: params });
  }

  updateDivisionDetails(divisionDetails: FormData, divisionId: number): Observable<IDivisionDetails> {
    return this.#http.put<IDivisionDetails>(APIS.divisions.updateById(divisionId), divisionDetails);
  }

  patchDivisionDetails(divisionDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.divisions.patchById, divisionDetailsForm);
  }

  getProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType | "";
    },
  ): Observable<IPaginatedPropertyResponse<IPropertyOverview>> {
    return this.#http.get<IPaginatedPropertyResponse<IPropertyOverview>>(APIS.divisions.getProperties, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }
}
