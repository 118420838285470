import { Side } from "@root/data/market/addresses/enums/side.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { generateDropdownOptionsFromNumber } from "../utilities/dropdown-options.utilities";

export const ADDRESS_SIDE_OPTIONS: IDropdownOption[] = [
  {
    label: "ADDRESS.SIDE_OPTIONS.RIGHT",
    value: Side.Right,
  },
  {
    label: "ADDRESS.SIDE_OPTIONS.LEFT",
    value: Side.Left,
  },
  {
    label: "ADDRESS.SIDE_OPTIONS.MIDDLE",
    value: Side.Middle,
  },
];

export const ADDRESS_DOOR_OPTIONS: IDropdownOption[] = generateDropdownOptionsFromNumber(20, false, 1, "ADDRESS.DOOR");
