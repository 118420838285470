import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";

@Injectable()
export class EditCustomerPopUpFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  phone = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue());
  formGroup = this.#fb.group({
    firstName: this.#fb.control<string>("", [Validators.required]),
    lastName: this.#fb.control<string>("", [Validators.required]),
    email: this.#fb.control<string>("", [Validators.required]),
    preferredLanguageId: this.#fb.control(""),
    phoneNumber: this.#fb.control(""),
    phoneIsdCode: this.#fb.control(""),
  });
}
