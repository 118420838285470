import { Component, computed, DestroyRef, inject, input, OnInit, output } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { isDateValid } from "@root/shared/utilities/date.utilities";
import { MatIcon } from "@angular/material/icon";
import { provideLuxonDateAdapter } from "@angular/material-luxon-adapter";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { markControlAsTouchedAndDirty, shouldShowControlRequiredError } from "@root/shared/utilities/form.utilities";
import { DateTime } from "luxon";
import { dateFormat } from "./date-format-luxon";
import { DATE_BEFORE_TODAY_KEY } from "../constants/error-keys.constants";
import { ISO_DATE_FORMAT_NO_TIME } from "@root/shared/constants/custom-date-formats.constants";

@Component({
  selector: "est-datepicker",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgOptimizedImage,
    TranslateModule,
    ReactiveFormsModule,
    MatIcon,
    NgClass,
    FormsModule,
  ],
  templateUrl: "./datepicker.component.html",
  styleUrl: "./datepicker.component.scss",
  providers: [provideLuxonDateAdapter(dateFormat)],
})
export class DatepickerComponent implements OnInit {
  // This component should accept date iso, and should output date in iso, using value 2 way binding or a form control
  // Warning: Do not pass a control and value at the same time. Control will override the value
  control = input<FormControl>();
  appliedClasses = input("");
  label = input("");
  value = input<DateTime | string | null>(null);
  dateOnly = input(false);
  valueChange = output<string>();
  DATE_BEFORE_TODAY_KEY = DATE_BEFORE_TODAY_KEY;
  isRequired = computed(() => this.control()?.hasValidator(Validators.required));
  requiredLabel = computed(() => (this.control()?.hasValidator(Validators.required) ? "REQUIRED" : ""));
  protected innerControl = new FormControl(); // Placeholder to store the value which is used to set the iso date in the main control
  protected readonly shouldShowControlError = shouldShowControlRequiredError;
  #destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.#updateControlState();
    this.#updateInnerControlOnControlChangeSubscription();
  }

  filterInvalidDates = (date: DateTime | null): boolean => {
    if (!date) return false;
    return isDateValid(date.toISO({ includeOffset: false })!);
  };

  updateDateAndEmitValueChange(event: MatDatepickerInputEvent<DateTime | any>): void {
    if (this.control()) {
      markControlAsTouchedAndDirty(this.control()!);
      this.#updateInnerControl(event.value);
    }
    if (!this.dateOnly()) {
      this.valueChange.emit(event.value?.toISO({ includeOffset: false }) || null);
    } else {
      this.valueChange.emit(event.value?.toFormat(ISO_DATE_FORMAT_NO_TIME) || null);
    }
  }

  isControlHasError() {
    return (
      this.innerControl.hasError("matDatepickerParse") ||
      (this.control() && shouldShowControlRequiredError(this.control()!))
    );
  }

  #copyInnerControlErrorsToMainControlValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      return this.innerControl.errors;
    };
  }

  #updateControlState() {
    this.control()?.addValidators(this.#copyInnerControlErrorsToMainControlValidator());
    this.control() && this.innerControl.setValue(this.control()!.value);
    this.control()?.disabled ? this.innerControl.disable() : this.innerControl.enable();
    this.control()?.registerOnDisabledChange((isDisabled) => {
      isDisabled ? this.innerControl.disable() : this.innerControl.enable();
    });
  }

  #updateInnerControlOnControlChangeSubscription() {
    this.control()
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#updateControlState();
      });
  }

  #updateInnerControl(value: DateTime) {
    if (typeof this.innerControl.value === "string") return;
    if (value) {
      if (!this.dateOnly()) {
        this.control()!.setValue(value.toISO({ includeOffset: false }));
      } else {
        this.control()!.setValue(value.toFormat(ISO_DATE_FORMAT_NO_TIME));
      }
    } else this.control()!.setValue(value);
    if (!this.control()?.value && this.innerControl.dirty) {
      this.control() && markControlAsTouchedAndDirty(this.control()!);
    }
  }
}
