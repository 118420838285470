<mat-form-field (click)="el.focus()" appearance="outline" [ngClass]="appliedClass()" id="chips">
  @if (label()) {
    <mat-label>{{ label() | translate }}</mat-label>
  }
  <mat-chip-grid #chipGrid aria-label="Enter fruits">
    @for (option of selectedOptions(); track $index) {
      <mat-chip-row tabIndex="-1" (click)="$event.stopPropagation()">
        <div class="flex items-center gap-2">
          <button [disabled]="selectedOptions().length < 2" (click)="removeChip(option)">
            <img
              class="max-w-none"
              ngSrc="assets/images/icons/times-circle.svg"
              width="15"
              height="14"
              priority
              [alt]="'REMOVE' | translate"
            />
          </button>
          @if (hasPopup()) {
            <button (click)="openCustomerDetailsPopup()">
              <img
                class="max-w-none"
                ngSrc="assets/images/icons/edit-user.svg"
                width="15"
                height="14"
                priority
                [alt]="'OPEN' | translate"
              />
            </button>
          }
          <p>
            {{ option.label }}
          </p>
        </div>
      </mat-chip-row>
    }
    <input
      [disabled]="isDisabled()"
      #el
      [(ngModel)]="searchSignal"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
    />
  </mat-chip-grid>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="addChip($event.option.value); el.value = ''">
  @for (option of options; track option.value) {
    @if (!isValueAlreadySelectedHashMap().get(option.value)) {
      <mat-option [value]="option">
        {{ option.label }}
      </mat-option>
    }
  }
</mat-autocomplete>
