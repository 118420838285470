@if (isPageLoading) {
  <est-loading-overlay></est-loading-overlay>
} @else {
  <est-customer-information
    [legalEntityId]="legalEntityId()"
    [countries]="countries"
    [formGroup]="formControls.formGroup"
    [phoneControl]="formControls.phone"
    (valuesChanged)="patchCustomerCardDetails()"
    (reloadRequest)="onReloadRequest()"
    [addressLookUps]="addressLookUps"
    [isFavourite]="formControls.isFavourite"
    [customerCategories]="customerCategoryOptions"
    [languages]="languageOptions"
    [demographicQuestions]="demographicQuestionLookUps"
  ></est-customer-information>
}
