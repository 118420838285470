import { inject, Injectable } from "@angular/core";
import { IDivisionTree } from "@root/shared/interfaces/division-tree.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginationSelectedIdPayload } from "@root/shared/interfaces/pagination-selected-id-payload.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPropertiesParams } from "@root/shared/interfaces/properties-params.interface";
import { ISignalPaginatedPropertyResponse } from "@root/shared/interfaces/signal-paginated-property-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISignalPaginatedPropertyLookupResponse } from "@root/shared/interfaces/signal-property-lookup-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { Observable } from "rxjs";
import { IAvailableCurrencies } from "../../units/models/unit-available-currencies.model";
import { PropertyType } from "../enums/property-type.enum";
import { ICreatePropertyPayload } from "../models/create-property-payload.model";
import { IFavoritePropertyResponse } from "../models/favorite-property-response.model";
import { IPropertyDetails } from "../models/property-details.model";
import { IPropertyUnitStatus } from "../models/property-unit-status.model";
import { PropertiesRepository } from "../repositories/properties.repository";
import {
  convertMediaFilesToFormData,
  mapNotAllowedSpeciesIdsOperator,
  mapPropertiesLookupsToDropdownOptionsOperator,
  mapPropertiesLookupsToSignalSmallListInputOperator,
  mapPropertiesToSignalLargeListInputOperator,
  mapPropertyPetTypePoliciesToSmallListTableInputOperator,
} from "../utilities/properties.utilities";
import { IToggleResponse } from "@root/data/market/shared/models/toggle-response.model";
import { IPropertyPetTypePolicy } from "@root/data/market/properties/models/property-pet-type-policy.model";
import { IUploadPropertyMedia } from "@root/views/main/property/property-marketing/components/medias/property-medias/upload-property-media.interface";
import { IPropertyMediaOverview } from "@root/data/market/properties/models/property-media-overview.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { mapDocumentsToSignalResponseSmallTableInput } from "@root/data/market/documents/utilities/documents.utilities";

@Injectable({ providedIn: "root" })
export class PropertiesService {
  #propertiesRepository = inject(PropertiesRepository);

  createProperty(property: ICreatePropertyPayload): Observable<number> {
    return this.#propertiesRepository.postProperty(property);
  }

  favoriteProperty(propertyId: number, companyId?: number): Observable<IFavoritePropertyResponse> {
    return this.#propertiesRepository.favoriteProperty({ propertyId, companyId });
  }

  getPaginatedPropertiesByCompanies(
    params: IPaginationSortPayload & {
      companyIds: number[];
      isFavourite?: boolean;
      propertyType?: PropertyType;
    },
  ): Observable<ISignalPaginatedPropertyResponse<ILargeListTableInput>> {
    return this.#propertiesRepository
      .getPaginatedPropertiesByCompanies(params)
      .pipe(mapPropertiesToSignalLargeListInputOperator());
  }

  getPaginatedSmallListTableInputPropertiesLookups(
    params: IPaginationSortPayload & { divisionIds?: number[]; teamIds?: number[]; userId?: number },
  ): Observable<ISignalPaginatedPropertyLookupResponse<ISmallListTableInput>> {
    return this.#propertiesRepository
      .getPropertiesLookups(params)
      .pipe(mapPropertiesLookupsToSignalSmallListInputOperator());
  }

  getPropertyPetTypePolicy(
    propertyId: number,
    petTypePolicyId: number,
  ): Observable<IPropertyPetTypePolicy & { notAllowedSpeciesIds: number[] }> {
    return this.#propertiesRepository
      .getPropertyPetTypePolicy(propertyId, petTypePolicyId)
      .pipe(mapNotAllowedSpeciesIdsOperator());
  }

  updatePropertyPetTypePolicy(propertyId: number, petPolicyId: number, body: { [key: string]: any }): Observable<void> {
    return this.#propertiesRepository.updatePropertyPetTypePolicy(propertyId, petPolicyId, body);
  }

  getPropertiesHierarchy(params: IPropertiesParams): Observable<IDivisionTree> {
    return this.#propertiesRepository.getPropertiesHierarchy(params);
  }

  getPropertiesOptions(params: IPaginationSelectedIdPayload): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#propertiesRepository
      .getPropertiesLookups(params)
      .pipe(mapPropertiesLookupsToDropdownOptionsOperator());
  }

  getPropertyDetails(id: string): Observable<IPropertyDetails> {
    return this.#propertiesRepository.getPropertyDetails(id);
  }

  updatePropertyDetails(propertyId: string, propertyDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#propertiesRepository.updatePropertyDetails(propertyId, propertyDetailsForm);
  }

  getAllCurrenciesForProperty(propertyId: string): Observable<IAvailableCurrencies> {
    return this.#propertiesRepository.getAllCurrenciesForProperty(propertyId);
  }

  reactivatePropertySettings(id: string, settingValue: string): Observable<void> {
    return this.#propertiesRepository.reactivatePropertySettings(id, settingValue);
  }

  updateSelectedCurrencies(id: string, selectedCurrencies: number[]): Observable<void> {
    return this.#propertiesRepository.updateSelectedCurrencies(id, selectedCurrencies);
  }

  changeCompany(id: string, companyId: number): Observable<void> {
    return this.#propertiesRepository.changeCompany(id, companyId);
  }

  addOrRemoveSecondaryAddress(id: string, isAddAddress: boolean): Observable<void> {
    return this.#propertiesRepository.addOrRemoveSecondaryAddress(id, isAddAddress);
  }

  getPropertyUnitStatusOverview(id: string): Observable<IPropertyUnitStatus> {
    return this.#propertiesRepository.getPropertyUnitStatusOverview(id);
  }

  addPropertyPetTypePolicy(
    body: {
      [key: string]: any;
    },
    propertyId: number,
    petTypePolicyId: number,
  ): Observable<void> {
    return this.#propertiesRepository.addPropertyPetTypePolicy(body, propertyId, petTypePolicyId);
  }

  getPropertyPetTypesAssignedIds(propertyId: number, petTypePolicyId: number): Observable<number[]> {
    return this.#propertiesRepository.getPropertyPetTypesAssignedIds(propertyId, petTypePolicyId);
  }

  deleteProperty(id: number): Observable<void> {
    return this.#propertiesRepository.deleteProperty(id);
  }

  getPaginatedSmallTableInputPropertyPetTypePolicies(
    params: IPaginationSortPayload & { propertyId?: number },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesRepository
      .listPropertyPetTypePolicies(params)
      .pipe(mapPropertyPetTypePoliciesToSmallListTableInputOperator());
  }

  deletePropertyPetTypePolicy(propertyId: number, petTypePolicyId: number): Observable<void> {
    return this.#propertiesRepository.deletePropertyPetTypePolicy(propertyId, petTypePolicyId);
  }

  togglePropertyPetTypePolicyActive(propertyId: number, petTypePolicyId: number): Observable<IToggleResponse> {
    return this.#propertiesRepository.togglePropertyPetTypePolicy(propertyId, petTypePolicyId);
  }

  getPropertyMediaOverview(propertyId: number): Observable<IPropertyMediaOverview[]> {
    return this.#propertiesRepository.getPropertyMediaOverview(propertyId);
  }

  uploadPropertyMedia(media: IUploadPropertyMedia[], propertyId: number): Observable<void> {
    const formData = new FormData();
    convertMediaFilesToFormData(formData, media);
    return this.#propertiesRepository.uploadPropertyMedia(formData, propertyId);
  }

  editPropertyMedia(propertyMedia: { [key: string]: any }, propertyId: number, mediaId?: number): Observable<void> {
    return this.#propertiesRepository.editPropertyMedia(propertyMedia, propertyId, mediaId);
  }

  getPaginatedSmallTableInputDocuments(
    params: IPaginationSortPayload & { filter: SourceLevel; eventInstanceId: number },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesRepository
      .getPaginatedInvitationDocuments(params)
      .pipe(mapDocumentsToSignalResponseSmallTableInput());
  }
}
