import { Component, input } from "@angular/core";
import { HighlightSearchPipe } from "@root/shared/pipe/highlight-search.pipe";
import { ISearchCustomer } from "@root/data/market/legalEntities/models/search-customer.model";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-search-customer-card",
  standalone: true,
  imports: [HighlightSearchPipe, TranslateModule],
  templateUrl: "./search-customer-card.component.html",
  styleUrl: "./search-customer-card.component.scss",
})
export class SearchCustomerCardComponent {
  search = input<string>("");
  protected readonly HighlightSearchPipe = HighlightSearchPipe;
  searchCustomer = input<ISearchCustomer>();

  calculateAge(birthday?: string): number | void {
    if (!birthday) {
      return;
    }
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
