<div class="flex flex-row">
  <div class="flex flex-col items-start gap-4 text-nowrap p-6 border-r-2">
    <b [innerHTML]="searchCustomer()?.name | highlightSearch: search()"></b>
    <div class="flex flex-row justify-between items-center w-full">
      <div class="flex flex-col items-start gap-4 w-full">
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "PROPERTIES.ADDRESS" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.address?.streetName | highlightSearch: search()"></p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "PHONE" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.phoneNumber | highlightSearch: search()"></p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "AGE" | translate }}</p>
          <span [innerHTML]="calculateAge(searchCustomer()?.birthday) | highlightSearch: search()">
            <span> {{ searchCustomer()?.birthday }}</span></span
          >
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "NATIONALITY" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.nationality | highlightSearch: search()"></p>
        </div>
      </div>
      <div class="flex flex-col items-start gap-4 w-full">
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "ZIPCODE_AND_CITY" | translate }}</p>
          <p
            [innerHTML]="
              searchCustomer()?.address?.zipCode + ' ' + searchCustomer()?.address?.city | highlightSearch: search()
            "
          ></p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "EMAIL" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.email | highlightSearch: search()"></p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "COUNTRY" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.country | highlightSearch: search()"></p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="font-bold">{{ "PREFERRED_LANGUAGE" | translate }}</p>
          <p [innerHTML]="searchCustomer()?.preferredLanguage | highlightSearch: search()"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-start gap-2 p-6 mt-[3rem]">
    <p class="font-bold">{{ "CUSTOMERS.CUSTOMER_JOURNEY" | translate }}</p>

    <div class="flex flex-col justify-between items-center">
      <div class="flex flex-col items-start gap-4 mb-4">
        @for (journey of searchCustomer()?.journeys; track journey) {
          <div class="flex flex-row gap-4">
            <div class="flex flex-col items-start gap-4">
              <p>
                <span class="font-bold">{{ "STAGE" | translate }}:</span>{{ journey.stage }}
              </p>
              <p>
                <span class="font-bold">{{ "CUSTOMERS.RESPONSIBLE_AGENT" | translate }}:</span>
                {{ journey.responsibleAgent }}
              </p>
              <p>
                <span class="font-bold">{{ "EVENTS.LAST_ACTIVITY" | translate }}: </span> {{ journey.lastActivity }}
              </p>
            </div>
            <div class="flex flex-col items-start gap-4">
              <p>
                <span class="font-bold">{{ "PROPERTIES.PROPERTY" | translate }}:</span> {{ journey.property }}
              </p>
              <p>
                <span class="font-bold">{{ "PROPERTIES.UNIT_NUMBER" | translate }}: </span> {{ journey.unitNumber }}
              </p>
              <p>
                <span class="font-bold">{{ "EVENTS.CONTRACT_STATUS" | translate }}: </span> {{ journey.contractStatus }}
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
