import { inject } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";

export abstract class BaseDocumentForm {
  fb = inject(NonNullableFormBuilder);
  name = this.fb.control("", [Validators.required]);
  documentName = this.fb.control("", [Validators.required]);
  documentTypeControl = this.fb.control<number | null>(null, [Validators.required]);
  active = this.fb.control(false);

  editDocumentFormGroup = this.fb.group({
    fileName: this.name,
    documentName: this.documentName,
    documentTypeId: this.documentTypeControl,
    isInheritanceActive: this.active,
  });
}
