<div class="sticky top-0 bg-white z-10">
  <est-dialog-header [includeExitButton]="true" label="UPLOAD_FILE.NAME" tooltipLabel="UPLOAD_FILE.NAME">
  </est-dialog-header>
  <mat-progress-bar [value]="getProgressbarValue()" class="py-1"></mat-progress-bar>
  <div class="relative p-6">
    <mat-stepper #stepper [linear]="true" animationDuration="0">
      <mat-step>
        <div class="flex gap-2">
          <div class="flex-1">
            <est-upload-file-drop-zone
              (fileUpload)="updateControl($event)"
              [(maxFileSize)]="maxFileSize"
              [isMultiple]="true"
              [supportedTypes]="supportedTypes"
              appliedClasses="!h-[20rem]"
            ></est-upload-file-drop-zone>
          </div>
          @if (control.value.length > 0) {
            <div class="w-[25rem] flex-col overflow-y-auto max-h-[20rem]">
              @for (document of control.value; track $index) {
                <div
                  class="flex justify-between items-center bg-black-100 border border-black-300 border-dashed p-4 rounded-md mb-1"
                >
                  <div class="flex gap-2">
                    <div class="py-4 px-8 bg-white rounded flex justify-center items-center">
                      <img
                        [alt]="'FILE' | translate"
                        width="38"
                        height="39"
                        priority
                        ngSrc="assets/images/icons/pdf.svg"
                      />
                    </div>
                    <div class="text-xs font-light">
                      <p>
                        <span class="font-medium">{{ ("FILE_NAME" | translate) + ": " }}</span>
                        <span class="truncate">{{ document.DocumentName }}</span>
                      </p>
                      <p>
                        <span class="font-medium">{{ ("FILE_SIZE" | translate) + ": " }}</span>
                        <span>{{ getFileSizeInKb(document.FormFile) }}</span>
                      </p>
                    </div>
                  </div>
                  <button
                    (click)="deleteFile($index)"
                    type="button"
                    class="bg-white p-1.5 rounded border border-black-400"
                  >
                    <img
                      [alt]="'UPLOAD_FILE.DELETE_FILE' | translate"
                      ngSrc="assets/images/icons/trash-icon.svg"
                      width="12"
                      height="12"
                      priority
                    />
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </mat-step>
      <mat-step>
        <p class="text-sm font-light mb-6">
          {{ "UPLOAD_FILE.CHANGE_NAME_MSG" | translate }}
        </p>
        <div>
          @if (control.value.length > 0) {
            @for (document of control.value; track $index) {
              <div
                class="flex justify-between items-center bg-black-100 border border-black-300 border-dashed p-4 rounded-md mb-1"
              >
                <div class="flex gap-2 w-full justify-between">
                  <div class="flex gap-2 flex-col w-5/6">
                    <div class="flex items-center w-full">
                      <p class="text-sm font-medium mb-2 w-1/6">{{ "NAME" | translate }}</p>
                      <est-input
                        appliedClass="w-[26.25rem] h-8 mb-4"
                        label="NAME"
                        [control]="getControlInFormArray(documentFileNameArrayControl, 'DocumentName', $index)"
                      ></est-input>
                    </div>

                    <div class="flex items-center w-full">
                      <p class="text-sm font-medium mb-2 w-1/6">{{ "UPLOAD_FILE.TYPE" | translate }}</p>
                      <est-select-field
                        [control]="getControlInFormArray(documentFileNameArrayControl, 'DocumentTypeId', $index)"
                        [dropDownOptions]="documentTypes"
                        [selectMultipleOptions]="false"
                        appliedClass="w-[26.25rem] h-8 mb-3"
                        dropDownLabel="UPLOAD_FILE.TYPE"
                      ></est-select-field>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="activate-document" class="text-sm font-medium mb-2">
                      {{ "UPLOAD_FILE.ACTIVATE" | translate }}
                    </label>
                    <mat-slide-toggle
                      id="activate-document"
                      class="mb-6 mt-1.5"
                      [checked]="document.Active"
                      (change)="toggleDocumentActivation($event.checked, document, $index)"
                    >
                    </mat-slide-toggle>
                  </div>
                  <div class="flex items-center justify-center">
                    <button
                      (click)="deleteFile($index)"
                      type="button"
                      class="bg-white p-1.5 rounded border border-black-400"
                    >
                      <img
                        [alt]="'UPLOAD_FILE.DELETE_FILE' | translate"
                        ngSrc="assets/images/icons/trash-icon.svg"
                        width="12"
                        height="12"
                        priority
                      />
                    </button>
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
    @if (stepper.selectedIndex === 0) {
      <est-button
        (click)="closeDialog()"
        [appliedClasses]="'!py-3 !px-6'"
        label="CANCEL"
        buttonStyleType="button--tertiary"
        buttonType="button"
      />
      <est-button
        (click)="stepper.next()"
        [appliedClasses]="'!py-3 !px-6'"
        label="NEXT"
        buttonStyleType="button--primary"
        buttonType="button"
        [disabled]="!control.value.length"
      />
    } @else {
      <est-button
        (click)="stepper.previous()"
        [appliedClasses]="'!py-3 !px-6'"
        label="BACK"
        buttonStyleType="button--tertiary"
        buttonType="button"
      />
      <est-button
        (click)="submitForm()"
        [appliedClasses]="'!py-3 !px-6'"
        label="UPLOAD"
        buttonStyleType="button--primary"
        buttonType="button"
        [isLoading]="isUploading"
        [disabled]="!control.value.length"
      />
    }
  </div>
</div>
