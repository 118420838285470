import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FollowUpStatusApiMapper } from "@root/data/market/system-configurations/follow-up-status/mappers/follow-up-status-api.mapper";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { Observable } from "rxjs/internal/Observable";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IFollowUpStatus } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-status.model";
import { IFollowUpStatusLookup } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-status-lookup.model";
import { IFollowUpTasksCategory } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-tasks-category.model";
import { APIS } from "@root/core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class FollowUpStatusRepository {
  readonly #http = inject(HttpClient);
  readonly #apiMap = FollowUpStatusApiMapper;

  getFollowUpStatus(payload: IInheritedQueryPayload): Observable<IFollowUpStatus> {
    return this.#http.get<IFollowUpStatus>(this.#apiMap.get(payload.sourceLevel)!.get(payload.routeId), {
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }

  updateFollowUpStatus(payload: IInheritedQueryPayload, body: any): Observable<void> {
    return this.#http.put<void>(this.#apiMap.get(payload.sourceLevel)!.update(payload.routeId), {
      ...payload.ids,
      ...body,
    });
  }

  createFollowUpStatus(body: any, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(sourceLevel)!.create(routeId), body);
  }

  getPaginatedFollowUpStatuses(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<IPaginatedResponse<IFollowUpStatus>> {
    return this.#http.get<IPaginatedResponse<IFollowUpStatus>>(this.#apiMap.get(sourceLevel)!.getAll(routeId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteFollowUpStatus(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.delete<void>(this.#apiMap.get(payload.sourceLevel)!.delete(payload.routeId), {
      body: { ...payload.ids },
    });
  }

  toggleFollowUpStatusInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(payload.sourceLevel)!.toggleInheritance(payload.routeId), {
      ...payload.ids,
    });
  }

  getFollowUpStatusesLookups(): Observable<IFollowUpStatusLookup[]> {
    return this.#http.get<IFollowUpStatusLookup[]>(APIS.organisation.getFollowUpStatusesLookups);
  }

  getFollowUpTasksCategories(): Observable<IFollowUpTasksCategory[]> {
    return this.#http.get<IFollowUpTasksCategory[]>(APIS.organisation.getFollowUpStatusesTasksLookups);
  }
}
