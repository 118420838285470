import { Component, EventEmitter, input, Output } from "@angular/core";
import { ButtonListComponent } from "@root/shared/button-list/button-list.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { FormControl } from "@angular/forms";
import { NullableBoolean } from "@root/shared/type/nullable-boolean.type";

@Component({
  selector: "est-decision-button-list",
  standalone: true,
  imports: [ButtonListComponent],
  templateUrl: "./decision-button-list.component.html",
  styleUrl: "./decision-button-list.component.scss",
})
export class DecisionButtonListComponent {
  label = input.required<string>();
  control = input(new FormControl());
  name = input("");
  appliedClass = input<string>("");
  @Output() selectionChanged = new EventEmitter<NullableBoolean>();

  decisionOptions: IDropdownOption[] = [
    {
      label: "?",
      value: null,
    },
    {
      label: "NO",
      value: false,
    },
    {
      label: "YES",
      value: true,
    },
  ];
}
