<div class="grid place-items-center text-3xl text-primary">
  <h4>{{ "UNDER_DEVELOPMENT" | translate }}</h4>
  <img
    [alt]="'UNDER_DEVELOPMENT' | translate"
    height="500"
    ngSrc="assets/images/under-development.jpg"
    priority="true"
    width="700"
  />
</div>
