import { inject, Injectable } from "@angular/core";
import { DemographicQuestionsRepository } from "@root/data/market/demographic-questions/repositories/demographic-questions.repository";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { Observable } from "rxjs";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";

@Injectable({
  providedIn: "root",
})
export class DemographicQuestionService {
  readonly #demographicQuestionRepository = inject(DemographicQuestionsRepository);

  lookUpDemographicQuestions(sourceLevel: SourceLevel, routeId?: string): Observable<IDemographicQuestionLookUp[]> {
    return this.#demographicQuestionRepository.lookUpDemographicQuestions(sourceLevel, routeId);
  }

  removeDemographicQuestionAnswer(demographicQuestionAnswerId: number): Observable<void> {
    return this.#demographicQuestionRepository.removeDemographicQuestionAnswer(demographicQuestionAnswerId);
  }

  answerDemographicQuestion(
    demographicQuestionId: number,
    legalEntityId: number,
    answerOptionIds?: number[],
    answerInputValue?: string,
  ): Observable<void> {
    return this.#demographicQuestionRepository.answerDemographicQuestion(
      demographicQuestionId,
      legalEntityId,
      answerOptionIds,
      answerInputValue,
    );
  }
}
