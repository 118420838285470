import { SnackbarType } from "../enums/snackbar-type.enum";

export const SnackbarImgSourceMapper = new Map<SnackbarType, string>([
  [SnackbarType.Error, "error.svg"],
  [SnackbarType.Loading, "loading-spinner-secondary.svg"],
  [SnackbarType.Hanging, "loading-clock.svg"],
  [SnackbarType.Success, "success-check.svg"],
]);

export const SnackbarTranslateKeyMapper = new Map<SnackbarType, string>([
  [SnackbarType.Error, "ERROR"],
  [SnackbarType.Loading, "LOADING"],
  [SnackbarType.Hanging, "HANGING"],
  [SnackbarType.Success, "SUCCESS"],
]);
