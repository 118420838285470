<div [ngClass]="appliedClass()" class="w-full">
  <div class="flex flex-col items-start">
    <div class="flex gap-2 items-center">
      <p class="text-2xl font-bold mb-1">
        {{ label() | translate }}
      </p>
      <est-tooltip [tooltip]="tooltip()"></est-tooltip>
    </div>
    <p class="text-xs text-black-500 font-light">
      {{ "LAST_UPDATED" | translate }} {{ name() }}, {{ date() | date: DATE_PIPE_FORMAT }}
    </p>
  </div>
</div>
<hr class="mt-6 border-black-100 absolute start-0 end-0" />
