import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { APIS } from "@root/core/api/market.apis";

export const SourceLevelDocumentApiMapper = new Map<
  SourceLevel,
  {
    uploadDocuments: (id?: string) => string;
    getDetails: (id?: string) => string;
    getAll: (id?: string) => string;
    deleteDocument: (id?: string) => string;
    viewDocument: (id?: string) => string;
    editDocument: (id?: string) => string;
    toggleDocumentInheritance: (id?: string) => string;
    toggleBlockedDocument: (id?: string) => string;
  }
>([
  [
    SourceLevel.Organisation,
    {
      uploadDocuments: () => APIS.document.organisation.uploadDocuments,
      getDetails: () => APIS.document.organisation.getDocumentDetails,
      getAll: () => APIS.document.organisation.getAll,
      deleteDocument: () => APIS.document.organisation.deleteDocument,
      viewDocument: () => APIS.document.organisation.viewDocument,
      editDocument: () => APIS.document.organisation.editDocument,
      toggleDocumentInheritance: () => APIS.document.organisation.toggleDocumentInheritance,
      toggleBlockedDocument: () => "No such thing for organisation :P",
    },
  ],
  [
    SourceLevel.Division,
    {
      uploadDocuments: () => APIS.document.division.uploadDocuments,
      getDetails: () => APIS.document.division.getDocumentDetails,
      getAll: () => APIS.document.division.getAll,
      deleteDocument: () => APIS.document.division.deleteDocument,
      viewDocument: () => APIS.document.division.viewDocument,
      editDocument: () => APIS.document.division.editDocument,
      toggleDocumentInheritance: () => APIS.document.division.toggleDocumentInheritance,
      toggleBlockedDocument: () => APIS.document.division.toggleBlockedDocument,
    },
  ],
  [
    SourceLevel.Company,
    {
      uploadDocuments: (id?: string) => APIS.document.company.uploadDocuments(id!),
      getDetails: (id?: string) => APIS.document.company.getDocumentDetails(id!),
      getAll: (id?: string) => APIS.document.company.getAll(id!),
      deleteDocument: (id?: string) => APIS.document.company.deleteDocument(id!),
      viewDocument: (id?: string) => APIS.document.company.viewDocument(id!),
      editDocument: (id?: string) => APIS.document.company.editDocument(id!),
      toggleDocumentInheritance: (id?: string) => APIS.document.company.toggleDocumentInheritance(id!),
      toggleBlockedDocument: (id?: string) => APIS.document.company.toggleBlockedDocument(id!),
    },
  ],
  [
    SourceLevel.Property,
    {
      uploadDocuments: (id?: string) => APIS.document.property.uploadDocuments(id!),
      getDetails: (id?: string) => APIS.document.property.getDocumentDetails(id!),
      getAll: (id?: string) => APIS.document.property.getAll(id!),
      deleteDocument: (id?: string) => APIS.document.property.deleteDocument(id!),
      viewDocument: (id?: string) => APIS.document.property.viewDocument(id!),
      editDocument: (id?: string) => APIS.document.property.editDocument(id!),
      toggleDocumentInheritance: (id?: string) => APIS.document.property.toggleDocumentInheritance(id!),
      toggleBlockedDocument: (id?: string) => APIS.document.property.toggleBlockedDocument(id!),
    },
  ],
]);
