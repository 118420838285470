import { Component, effect, inject, input, OnInit, signal, WritableSignal } from "@angular/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { InputComponent } from "@root/shared/input/input.component";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { PaymentFrequencySelectFieldComponent } from "@root/shared/payment-frequency-select-field/payment-frequency-select-field.component";
import { PropertyAddressComponent } from "@root/shared/property-address/property-address.component";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TimezoneSelectFieldComponent } from "@root/shared/timezone-select-field/timezone-select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { SelectSearchAvailableResponsibleAgentsComponent } from "@root/views/main/property/property-events/customer-follow-up/select-search-available-responsible-agents/select-search-available-responsible-agents.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ISendInvitationFormGroup } from "@root/shared/send-invitiation-to-customer/send-invitiation-formgroup.type";
import { ChipsComponent } from "@root/shared/chips/chips.component";
import { finalize } from "rxjs";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { TimePickerComponent } from "@root/shared/time-picker/time-picker.component";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";

@Component({
  selector: "est-send-invitation-to-customer",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    SelectSearchFieldComponent,
    InputComponent,
    TextAreaComponent,
    ButtonComponent,
    MatSlideToggle,
    PaymentFrequencySelectFieldComponent,
    PropertyAddressComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    TimezoneSelectFieldComponent,
    TooltipComponent,
    TranslateModule,
    SelectSearchAvailableResponsibleAgentsComponent,
    ChipsComponent,
    DatepickerComponent,
    TimePickerComponent,
  ],
  templateUrl: "./send-invitation-to-customer.component.html",
  styleUrl: "./send-invitation-to-customer.component.scss",
})
export class SendInvitationToCustomerComponent implements OnInit {
  #legalEntityService = inject(LegalEntityService);
  #propertyEventsService = inject(PropertiesEventsService);
  formGroup = input.required<FormGroup<ISendInvitationFormGroup>>();
  journeyId = input<number>();
  propertyId = input<number>();
  eventInstanceId = input<number>();
  recipientName = input<string>("");
  recipientId = input<number>();
  hasEventDetails = input<boolean>();
  hasPopup = input<boolean>(true);
  RECIPIENT_OPTION: IDropdownOption[] = [];
  emailTemplate: string = "";
  initialResponsibleAgentOption: WritableSignal<IDropdownOption | undefined> = signal(undefined);
  isRecipientsDisabled = input(false);

  readonly #setSelectedOptionEffect$ = effect(() => {
    this.RECIPIENT_OPTION = [
      {
        label: this.recipientName(),
        value: this.recipientId(),
      },
    ];
  });

  ngOnInit(): void {
    this.getEmailTemplate();
    if (this.hasEventDetails()) {
      this.getEventDetails();
    }
  }

  getEmailTemplate() {
    this.#legalEntityService
      .getEmailTemplate(1)
      .pipe(finalize(() => {}))
      .subscribe({
        next: (res) => {
          this.emailTemplate = res.title;
          this.formGroup().controls.message.patchValue(res.body);
        },
      });
  }

  getEventDetails() {
    this.#propertyEventsService
      .getPropertyEventById(this.propertyId()!, this.eventInstanceId()!)
      .pipe(finalize(() => {}))
      .subscribe({
        next: (res) => {
          this.formGroup().patchValue(res);
          this.formGroup().controls.eventDate.patchValue(res.startTime);
        },
      });
  }
}
