import { NgClass } from "@angular/common";
import { Component, input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { BaseInput } from "../abstracts/base-input/base-input.abstract";
import {
  LAT_LONG_INVALID,
  VALIDATION_ERROR_KEY,
  VAT_NUMBER_NOT_EXISTS_ERROR_KEY,
} from "../constants/error-keys.constants";

@Component({
  selector: "est-input",
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, TranslateModule, MatInputModule, MatIcon, NgClass],
  templateUrl: "./input.component.html",
  styleUrl: "./input.component.scss",
})
export class InputComponent extends BaseInput implements OnInit {
  protected readonly INVALID_VAT_NUMBER_ERROR_KEY = VAT_NUMBER_NOT_EXISTS_ERROR_KEY;
  protected readonly VAT_NUMBER_NOT_EXISTS_ERROR_KEY = VAT_NUMBER_NOT_EXISTS_ERROR_KEY;
  protected readonly VALIDATION_ERROR_KEY = VALIDATION_ERROR_KEY;
  protected readonly LAT_LONG_INVALID = LAT_LONG_INVALID;

  maxNumberOfChar = input<number>();

  ngOnInit() {
    this.isRequired = this.control()?.hasValidator(Validators.required) || false;
  }
}
