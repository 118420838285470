import { Component, input, OnInit, signal, WritableSignal } from "@angular/core";
import { MAT_TABS_CONFIG, MatTab, MatTabGroup, MatTabLabel, MatTabsModule } from "@angular/material/tabs";
import { ITab } from "@root/shared/interfaces/tab.interface";
import { TranslateModule } from "@ngx-translate/core";
import { NgComponentOutlet } from "@angular/common";
import { RouterLinkActive } from "@angular/router";
import { TabsConfig } from "@root/shared/tabs/tabs.config";

@Component({
  selector: "est-tabs",
  standalone: true,
  imports: [MatTabGroup, MatTab, TranslateModule, NgComponentOutlet, MatTabLabel, RouterLinkActive, MatTabsModule],
  templateUrl: "./tabs.component.html",
  styleUrl: "./tabs.component.scss",
  providers: [{ provide: MAT_TABS_CONFIG, useValue: TabsConfig }],
})
export class TabsComponent implements OnInit {
  tabs = input.required<ITab[]>();
  componentInputs = input<{ [key: string]: any }>();
  selectedTab!: WritableSignal<ITab>;

  selectedTabChange(tabIndex: number) {
    this.selectedTab.set(this.tabs()[tabIndex]);
    if (this.selectedTab().callbackFn) {
      this.selectedTab().callbackFn!();
    }
  }

  ngOnInit() {
    this.selectedTab = signal(this.tabs()[0]);
  }
}
