<div class="p-6 max-w-[32rem]">
  <div class="flex gap-7 items-center font-light text-sm">
    <img
      width="50"
      height="50"
      priority="true"
      ngSrc="assets/images/icons/{{ SnackbarImgSourceMapper.get(snackBarData.type) }}"
      [alt]="SnackbarTranslateKeyMapper.get(snackBarData.type)! | translate"
    />
    <div>
      <p class="font-medium mb-4">
        {{ "SNACKBAR." + SnackbarTranslateKeyMapper.get(snackBarData.type) + ".TITLE" | translate }}
      </p>
      <p class="mb-4">
        @if (snackBarData.customMessage) {
          {{ snackBarData.customMessage | translate }}
        } @else {
          {{ "SNACKBAR." + SnackbarTranslateKeyMapper.get(snackBarData.type) + ".DESCRIPTION" | translate }}
        }
      </p>
      @if (snackBarData.type === SnackbarType.Error && !snackBarData.customMessage) {
        <p>{{ "SNACKBAR.ERROR.SECONDARY_DESCRIPTION" | translate }}</p>
      }
      @if (snackBarData.component) {
        <ng-container *ngComponentOutlet="snackBarData.component; inputs: snackBarData.componentInputs"></ng-container>
      }
    </div>
  </div>
</div>
