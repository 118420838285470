import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { SYSTEM_ADMIN_ROLE_ID } from "../../shared/constants/global.constants";
import { IPropertyIdAccess } from "@root/shared/list-select-properties/property-id-access.interface";

const excludedDefaultMappingKeys = ["phoneNumber", "systemLanguage", "divisions", "teamIds", "properties"];

export const constructCreateUserFormData = (formGroup: FormGroup): FormData => {
  const formData: FormData = new FormData();
  appendGenericFormKeys(formData, formGroup);
  appendPhoneNumber(formData, formGroup);
  appendSystemLanguage(formData, formGroup);
  appendUserRoles(formData, formGroup);
  appendTeamIds(formData, formGroup);
  appendPropertyIdsAccess(formData, formGroup);

  return formData;
};

export const constructCreateSystemAdminFormData = (formGroup: FormGroup): FormData => {
  const formData: FormData = new FormData();
  appendGenericFormKeys(formData, formGroup);
  appendSystemAdminRole(formData);
  appendPhoneNumber(formData, formGroup);
  appendSystemLanguage(formData, formGroup);
  return formData;
};

const appendGenericFormKeys = (formData: FormData, formGroup: FormGroup): void => {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key)!;
    if (control.value === null) return;
    if (!excludedDefaultMappingKeys.includes(key)) {
      formData.append(key, control.value);
    }
  });
};

const appendSystemAdminRole = (formData: FormData): void => {
  formData.append("organisationRoleId", SYSTEM_ADMIN_ROLE_ID);
};

const appendPropertyIdsAccess = (formData: FormData, formGroup: FormGroup): void => {
  const control = formGroup.get("properties")!;
  control.value.forEach((property: IPropertyIdAccess, index: number) => {
    formData.append(`properties[${index}].propertyId`, property.propertyId.toString());
    formData.append(`properties[${index}].propertyAccess`, property.propertyAccess);
  });
};

const appendTeamIds = (formData: FormData, formGroup: FormGroup): void => {
  const control = formGroup.get("teamIds")!;
  control.value.forEach((teamId: any, index: number) => {
    formData.append(`teamIds[${index}]`, teamId);
  });
};

const appendPhoneNumber = (formData: FormData, formGroup: FormGroup): void => {
  const control = formGroup.get("phoneNumber")!;
  formData.append("phoneNumber", control.value.number);
  formData.append("phoneCountryCode", control.value.countryCode);
};

const appendSystemLanguage = (formData: FormData, formGroup: FormGroup): void => {
  const control = formGroup.get("systemLanguage")!;
  formData.append("systemLanguage", control.value);
};

const appendUserRoles = (formData: FormData, formGroup: FormGroup): void => {
  const array = formGroup.get("divisions")!;
  if (!(array instanceof FormArray)) return;
  array.controls.forEach((userRole: AbstractControl, index: number) => {
    if (userRole instanceof FormGroup) {
      formData.append(`userDivisionRoles[${index}].divisionId`, userRole.controls["divisionId"].value);
      formData.append(`userDivisionRoles[${index}].roleId`, userRole.controls["roleId"].value);
    }
  });
};
