export function getDocumentPopupWindowFeatures(): string {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const windowWidth = screenWidth / 2;
  const windowHeight = screenHeight;

  const left = window.screenLeft + (screenWidth - windowWidth) / 2;
  const top = 0;

  return `width=${windowWidth},height=${windowHeight},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,status=no`;
}
