import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SourceLevelCustomerCategoryApiMapper } from "@root/data/market/system-configurations/customer-category/mappers/customer-category-api.mapper";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { Observable } from "rxjs";
import { ICustomerCategory } from "@root/data/market/system-configurations/customer-category/models/customer-category.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ICustomerCategoryLookUp } from "@root/data/market/system-configurations/customer-category/models/customer-category-look-up.model";

@Injectable({
  providedIn: "root",
})
export class CustomerCategoryRepository {
  readonly #http = inject(HttpClient);
  readonly #apiMap = SourceLevelCustomerCategoryApiMapper;

  getCustomerCategory(payload: IInheritedQueryPayload): Observable<ICustomerCategory> {
    return this.#http.get<ICustomerCategory>(this.#apiMap.get(payload.sourceLevel)!.get(payload.routeId), {
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }

  updateCustomerCategory(payload: IInheritedQueryPayload, body: ICustomerCategory): Observable<void> {
    return this.#http.put<void>(this.#apiMap.get(payload.sourceLevel)!.update(payload.routeId), {
      ...payload.ids,
      ...body,
    });
  }

  createCustomerCategory(body: ICustomerCategory, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(sourceLevel)!.create(routeId), body);
  }

  getPaginatedCustomerCategories(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<IPaginatedResponse<ICustomerCategory>> {
    return this.#http.get<IPaginatedResponse<ICustomerCategory>>(this.#apiMap.get(sourceLevel)!.getAll(routeId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteCustomerCategory(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.delete<void>(this.#apiMap.get(payload.sourceLevel)!.delete(payload.routeId), {
      body: { ...payload.ids },
    });
  }

  toggleCustomerCategoryInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(payload.sourceLevel)!.toggleInheritance(payload.routeId), {
      ...payload.ids,
    });
  }

  lookUpCustomerCategories(payload: IInheritedQueryPayload): Observable<ICustomerCategoryLookUp[]> {
    return this.#http.get<ICustomerCategoryLookUp[]>(this.#apiMap.get(payload.sourceLevel)!.lookUp(payload.routeId), {
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }
}
