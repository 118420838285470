import { Component, DestroyRef, inject, input, OnInit, output, signal, WritableSignal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { RatingComponent } from "@root/shared/rating/rating.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { FollowUpStatusService } from "@root/data/market/system-configurations/services/follow-up-status.service";
import { AsyncPipe } from "@angular/common";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MarketingQuestionsService } from "@root/data/market/marketing-questions/services/marketing-questions.service";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  CustomerFollowUpFormGroup,
  CustomerFollowUpTaskFormGroup,
} from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up-form-group.type";
import { FormBuilder, Validators } from "@angular/forms";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { IMarketingQuestionLookup } from "@root/data/market/marketing-questions/models/marketing-question-lookup.model";
import { ICreateCustomerFollowUpTask } from "@root/views/main/property/property-events/customer-follow-up/create-customer-follow-up-task.interface";
import { SelectSearchAvailableResponsibleAgentsComponent } from "@root/views/main/property/property-events/customer-follow-up/select-search-available-responsible-agents/select-search-available-responsible-agents.component";

@Component({
  selector: "est-customer-follow-up",
  standalone: true,
  imports: [
    TranslateModule,
    TooltipComponent,
    RatingComponent,
    SelectFieldComponent,
    AsyncPipe,
    DatepickerComponent,
    TextAreaComponent,
    ButtonComponent,
    MatCheckbox,
    SelectSearchFieldComponent,
    SelectSearchAvailableResponsibleAgentsComponent,
  ],
  templateUrl: "./customer-follow-up.component.html",
  styleUrl: "./customer-follow-up.component.scss",
})
export class CustomerFollowUpComponent implements OnInit {
  followUpStatusOptions: IDropdownOption[] = [];
  followUpTasksCategories: IDropdownOption[] = [];
  marketingQuestions: IMarketingQuestionLookup[] = [];
  readonly formGroup = input.required<CustomerFollowUpFormGroup>();
  readonly journeyId = input<number>();
  readonly hasResponsibleAgent = input(true);
  readonly valueChange = output<{ [key: string]: any }>();
  readonly taskCreate = output<ICreateCustomerFollowUpTask>();
  readonly selectMarketingQuestion = output<number>();
  readonly destroyRef = inject(DestroyRef);
  readonly initialResponsibleAgentOption: WritableSignal<IDropdownOption | undefined> = signal(undefined);
  readonly #fb = inject(FormBuilder);
  readonly currentFollowUpTaskFormGroup: CustomerFollowUpTaskFormGroup = this.#fb.group({
    date: this.#fb.control<string | null>(null, Validators.required),
    followUpTaskId: this.#fb.control<number | null>(null, Validators.required),
    followUpNote: this.#fb.control<string | null>(null, Validators.required),
  });
  readonly #marketingQuestionsService = inject(MarketingQuestionsService);
  readonly #followUpStatusService = inject(FollowUpStatusService);

  ngOnInit() {
    this.#setInitialResponsibleAgentOption();
    this.#getFollowUpStatusOptions();
    this.#getFollowUpTasksCategoriesOptions();
    this.#getMarketingQuestionsLookups();
    this.#subscribeToFormValueChanges();
  }

  addFollowUpTask() {
    const currentFollowUpTaskControls = this.currentFollowUpTaskFormGroup.controls;
    const followUpTaskFormGroup = this.#fb.group({
      date: this.#fb.control(currentFollowUpTaskControls.date.value),
      followUpTaskId: this.#fb.control(currentFollowUpTaskControls.followUpTaskId.value),
      followUpNote: this.#fb.control(currentFollowUpTaskControls.followUpNote.value),
    });
    this.formGroup().controls.followUpTasks.push(followUpTaskFormGroup);
    this.taskCreate.emit(this.currentFollowUpTaskFormGroup.value as Required<ICreateCustomerFollowUpTask>);
    this.currentFollowUpTaskFormGroup.reset();
  }

  toggleMarketingQuestion(questionId: number, isEnabled: boolean) {
    const marketingQuestions = this.formGroup().controls.selectedMarketingQuestionsIds.value;
    if (isEnabled) {
      marketingQuestions.push(questionId);
    } else {
      marketingQuestions.splice(marketingQuestions.indexOf(questionId), 1);
    }
    this.selectMarketingQuestion.emit(questionId);
  }

  #getFollowUpStatusOptions() {
    this.#followUpStatusService
      .getFollowUpStatusesLookups()
      .subscribe((options) => (this.followUpStatusOptions = options));
  }

  #getFollowUpTasksCategoriesOptions() {
    this.#followUpStatusService
      .getFollowUpTasksCategories()
      .subscribe((options) => (this.followUpTasksCategories = options));
  }

  #subscribeToFormValueChanges() {
    this.formGroup()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const controlPaths = updateNestedControlsPathAndValue(this.formGroup());
        if (Object.keys(controlPaths).length > 0) this.valueChange.emit(controlPaths);
      });
  }

  #getMarketingQuestionsLookups() {
    this.#marketingQuestionsService.getMarketingQuestionsLookups().subscribe((res) => (this.marketingQuestions = res));
  }

  #setInitialResponsibleAgentOption() {
    if (this.formGroup().value.responsibleAgentName && this.formGroup().value.responsibleAgentId)
      this.initialResponsibleAgentOption.set({
        label: this.formGroup().value.responsibleAgentName!,
        value: this.formGroup().value.responsibleAgentId,
      });
  }
}
