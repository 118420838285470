import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { DocumentsRepository } from "@root/data/market/documents/repositories/documents.repository";
import {
  convertFilesToFormData,
  mapLookupItemsToDropdownOptions,
  mapDocumentsToSignalResponseSmallTableInput,
} from "@root/data/market/documents/utilities/documents.utilities";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ICreateDocumentRequest } from "@root/shared/interfaces/upload-document-payload.model";
import { IDocument } from "@root/data/market/documents/models/documents.model";
import { IDocumentDetails } from "@root/shared/interfaces/document-details.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  readonly #documentsRepository = inject(DocumentsRepository);

  getPaginatedSmallTableInputDocuments(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#documentsRepository
      .getPaginatedDocuments(params, sourceLevel, routeId)
      .pipe(mapDocumentsToSignalResponseSmallTableInput());
  }

  deleteDocument(payload: IInheritedQueryPayload): Observable<void> {
    return this.#documentsRepository.deleteDocument(payload);
  }

  viewDocument(payload: IInheritedQueryPayload): Observable<Blob> {
    return this.#documentsRepository.viewDocument(payload);
  }

  getDocumentTypes(): Observable<IDropdownOption[]> {
    return this.#documentsRepository.getDocumentTypes().pipe(
      map((option) => {
        return mapLookupItemsToDropdownOptions(option);
      }),
    );
  }

  uploadDocuments(documents: ICreateDocumentRequest[], sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    const formData = new FormData();
    convertFilesToFormData(formData, documents);
    return this.#documentsRepository.uploadDocuments(formData, sourceLevel, routeId);
  }

  getDocumentDetails(payload: IInheritedQueryPayload): Observable<IDocument> {
    return this.#documentsRepository.getDocumentDetails(payload);
  }

  updateDocument(payload: IInheritedQueryPayload, body: IDocumentDetails): Observable<void> {
    return this.#documentsRepository.updateDocument(payload, body);
  }

  toggleBlockedInheritedDocument(payload: IInheritedQueryPayload): Observable<void> {
    return this.#documentsRepository.toggleBlockedDocument(payload);
  }

  toggleDocumentInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#documentsRepository.toggleDocumentInheritance(payload);
  }
}
