import { Component, OutputEmitterRef, input, output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { PaymentFrequencyTranslationMapper } from "@root/shared/mappers/payment-frequency-translation.mapper";

@Component({
  selector: "est-payment-frequency-select-field",
  standalone: true,
  imports: [SelectFieldComponent],
  templateUrl: "./payment-frequency-select-field.component.html",
  styleUrl: "./payment-frequency-select-field.component.scss",
})
export class PaymentFrequencySelectFieldComponent {
  control = input(new FormControl<PaymentFrequency | null | undefined>(null));
  appliedClasses = input("");
  paymentFrequenciesOptions: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    PaymentFrequency,
    PaymentFrequencyTranslationMapper,
  );
  valueChanged: OutputEmitterRef<string> = output();
}
