import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { inject, Injectable } from "@angular/core";
import { TeamsRepository } from "@root/data/market/teams/repositories/teams.repository";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { Observable } from "rxjs";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapTeamsToSignalSmallListTableInputOperator } from "@root/data/market/teams/utilities/teams.utilities";
import { ITeamDetails } from "@root/data/market/teams/models/team-details.model";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  private readonly teamsRepository = inject(TeamsRepository);

  getPaginatedSmallListTableInputTeams(
    params: IPaginationSortPayload & { divisionIds?: number[] },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.teamsRepository.getPaginatedTeams(params).pipe(mapTeamsToSignalSmallListTableInputOperator());
  }

  createNewTeam(teamInformation: FormData): Observable<void> {
    return this.teamsRepository.createTeam(teamInformation);
  }
  getTeamById(teamId: number): Observable<ITeamDetails> {
    return this.teamsRepository.getTeamById(teamId);
  }
  updateTeamById(teamId: number, body: FormData) {
    return this.teamsRepository.updateTeamById(teamId, body);
  }
  reactivateTeam(id: number): Observable<void> {
    return this.teamsRepository.reactivateTeam(id);
  }

  deactivateTeam(id: number): Observable<void> {
    return this.teamsRepository.deactivateTeam(id);
  }

  assignProperties(teamId: number, properties: number[]) {
    return this.teamsRepository.assignProperties(teamId, properties);
  }
  replaceTeamManger(userId: number, teamIds: number[]): Observable<void> {
    return this.teamsRepository.replaceTeamManger(userId, teamIds);
  }
}
