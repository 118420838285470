<div
  [ngClass]="appliedClasses()"
  class="h-[20.5rem] bg-white border-2 border-dashed border-black-400 rounded-lg flex flex-col justify-center items-center cursor-pointer relative gap-y-4 text-center font-light"
>
  <input
    (change)="emitFileUploadEvent($event)"
    [accept]="getSupportedFileTypesExtensions()"
    [multiple]="isMultiple()"
    class="absolute inset-0 opacity-0 cursor-pointer"
    type="file"
  />
  <img [alt]="'UPLOAD_FILE.NAME'" height="39" ngSrc="assets/images/icons/upload-file-image.svg" priority width="27" />
  <div>
    <p class="text-sm font-light">{{ "UPLOAD_FILE.MESSAGE" | translate }}</p>
    <p class="text-sm font-medium mb-2">{{ "UPLOAD_FILE.BROWSE_YOUR_COMPUTER" | translate }}</p>
    @if (supportedTypes().length > 0) {
      <p class="text-xs text-black-500 font-light">
        {{ "UPLOAD_FILE.SUPPORTS_ONLY" | translate: { supportedTypes: getFileTypesString() } }}
      </p>
    }
    @if (maxFileSize()) {
      <p class="text-xs text-black-500 font-light">
        ({{ "UPLOAD_FILE.MAX_FILE_SIZE" | translate: { megaBytes: maxFileSize() } }})
      </p>
    }
    @if (recommendedImageHeight() && recommendedImageWidth()) {
      <p class="text-xs text-black-500 font-light mt-2">
        {{
          "UPLOAD_FILE.RECOMMENDED_SIZE"
            | translate
              : {
                  width: recommendedImageWidth(),
                  height: recommendedImageHeight()
                }
        }}
      </p>
    }
  </div>
</div>
