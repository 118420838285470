<div class="flex gap-2 items-center mb-2.5">
  <p class="font-medium">{{ "CUSTOMERS.FOLLOW_UP_ON_CUSTOMER" | translate }}</p>
  <est-tooltip tooltip="CUSTOMERS.FOLLOW_UP_ON_CUSTOMER"></est-tooltip>
</div>
<p class="font-light text-sm mb-3 w-1/2">{{ "CUSTOMERS.FOLLOW_UP_ON_CUSTOMER_DESCRIPTION" | translate }}</p>
<p class="font-light text-sm mb-6">{{ "CUSTOMERS.FOLLOW_UP_ON_CUSTOMER_SECONDARY_DESCRIPTION" | translate }}</p>
<hr class="absolute inset-x-0 border-black-200" />
<div class="flex gap-52 mt-12 mb-6">
  <div>
    <div class="flex gap-2 items-center mb-2">
      <p class="font-medium">{{ "RATING" | translate }}</p>
      <est-tooltip tooltip="RATING"></est-tooltip>
    </div>
    <p class="font-light text-sm mb-2">{{ "CUSTOMERS.INTEREST_RATING_MESSAGE" | translate }}</p>
    <est-rating [control]="formGroup().controls.interestRating" appliedClasses="mb-4"></est-rating>
    <p class="font-light text-sm mb-2">{{ "CUSTOMERS.CONVERSATION_RATING_MESSAGE" | translate }}</p>
    <est-rating [control]="formGroup().controls.legalEntityRating"></est-rating>
  </div>
  <div>
    <div class="flex gap-2 items-center mb-2">
      <p class="font-medium">{{ "CUSTOMERS.FOLLOW_UP_STATUS" | translate }}</p>
      <est-tooltip tooltip="CUSTOMERS.FOLLOW_UP_STATUS"></est-tooltip>
    </div>
    <p class="font-light text-sm mb-2">{{ "CUSTOMERS.FOLLOW_UP_STATUS_MESSAGE" | translate }}</p>
    <est-select-field
      [control]="formGroup().controls.followUpStatusId"
      [dropDownOptions]="followUpStatusOptions"
      [selectMultipleOptions]="false"
      dropDownLabel="CUSTOMERS.FOLLOW_UP_STATUS"
    ></est-select-field>
  </div>
</div>
<hr class="absolute inset-x-0 border-black-200" />
<div class="flex gap-2 items-center mb-2 mt-12">
  <p class="font-medium">{{ "CUSTOMERS.FOLLOW_UP_TASK" | translate }}</p>
  <est-tooltip tooltip="CUSTOMERS.FOLLOW_UP_TASK"></est-tooltip>
</div>
<p class="font-light text-sm mb-4">{{ "CUSTOMERS.FOLLOW_UP_TASK_DESCRIPTION" | translate }}</p>
<div class="flex gap-4 mb-2">
  <est-datepicker
    [control]="currentFollowUpTaskFormGroup.controls.date"
    [dateOnly]="true"
    appliedClasses="w-[9.375rem]"
    label="DATE"
  ></est-datepicker>
  <est-select-field
    [control]="currentFollowUpTaskFormGroup.controls.followUpTaskId"
    [dropDownOptions]="followUpTasksCategories"
    [selectMultipleOptions]="false"
    dropDownLabel="CATEGORY"
  ></est-select-field>
</div>
<div class="flex items-end gap-6 mb-6">
  <est-text-area
    [control]="currentFollowUpTaskFormGroup.controls.followUpNote"
    appliedClass="!w-[37.5rem] !h-20"
    label="CUSTOMERS.FOLLOW_UP_NOTE"
  ></est-text-area>
  <est-button
    (click)="addFollowUpTask()"
    [disabled]="currentFollowUpTaskFormGroup.invalid"
    buttonStyleType="button--primary"
    buttonType="button"
    label="CREATE"
  ></est-button>
</div>
<hr class="absolute inset-x-0 border-black-200" />
<div class="flex gap-2 items-center mb-2 mt-12">
  <p class="font-medium">{{ "CUSTOMERS.HEARD_ABOUT_PROPERTY" | translate }}</p>
  <est-tooltip tooltip="CUSTOMERS.FOLLOW_UP_TASK"></est-tooltip>
</div>
<p class="w-2/5 mb-6 font-light text-sm">{{ "CUSTOMERS.HEARD_ABOUT_PROPERTY_DESCRIPTION" | translate }}</p>
<p class="font-medium text-sm mb-2">{{ "CUSTOMERS.MEDIAS_AND_CAMPAIGNS" | translate }}</p>
<div class="flex gap-x-7 w-2/5 flex-wrap mb-6">
  @for (question of marketingQuestions; track question.id) {
    <mat-checkbox
      (change)="toggleMarketingQuestion(question.id, $event.checked)"
      [checked]="formGroup().controls.selectedMarketingQuestionsIds.value.includes(question.id)"
      class="font-light text-sm"
      >{{ question.value }}
    </mat-checkbox>
  }
</div>
@if (hasResponsibleAgent() && journeyId()) {
  <hr class="absolute inset-x-0 border-black-200" />
  <div class="flex gap-2 items-center mb-2 mt-12">
    <p class="font-medium">{{ "RESPONSIBLE_AGENT" | translate }}</p>
    <est-tooltip tooltip="RESPONSIBLE_AGENT"></est-tooltip>
  </div>
  <p class="mb-4 font-light text-sm">{{ "CUSTOMERS.RESPONSIBLE_AGENT_MESSAGE" | translate }}</p>
  <est-select-search-available-responsible-agents
    [journeyId]="journeyId()!"
    dropDownLabel="RESPONSIBLE_AGENT"
    [initialResponsibleAgentOption]="initialResponsibleAgentOption"
    [control]="formGroup().controls.responsibleAgentId"
  ></est-select-search-available-responsible-agents>
}
