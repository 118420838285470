import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAddressInformation } from "../models/address-information.model";
import { AddressRepository } from "../repositories/address.repository";

@Injectable({ providedIn: "root" })
export class AddressService {
  readonly #addressRepository = inject(AddressRepository);

  getAddressInformationByVat(vat: string, countryCode: string): Observable<IAddressInformation> {
    return this.#addressRepository.getAddressInformationByVat(vat, countryCode);
  }
}
