import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { GeographicalLocationRepository } from "../repositories/geographical-location.repository";
import { IGeographicLocation } from "../models/geographic-location.model";

@Injectable({
  providedIn: "root",
})
export class GeographicalLocationService {
  readonly #geographicalLocationRepository = inject(GeographicalLocationRepository);

  getGeographicalLocation(formattedAddress: string): Observable<IGeographicLocation> {
    return this.#geographicalLocationRepository.getGeographicalLocation(formattedAddress).pipe(
      map((locations) => {
        if (locations.length === 0) {
          throw new Error("ERRORS.UNABLE_TO_GET_LOCATION");
        }
        return locations[0];
      }),
    );
  }
}
