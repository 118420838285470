<div class="flex flex-col items-center sticky min-h-[41.8rem]">
  <div class="text-center mb-14 pt-16">
    <p class="text-[2rem] font-bold mb-4">{{ "CREATION_SUCCESS.NICE_THE_FAMILY_JUST_GOT_BIGGER" | translate }}</p>
    <p class="font-light">{{ description() | translate }}</p>
  </div>
  <div class="bg-black-50 border border-black-300 px-6 py-4 text-center rounded-lg">
    <p class="text-xl mb-2.5 font-medium">
      {{
        "CREATION_SUCCESS.GIVE_A_WELCOME_WARM"
          | translate
            : {
                type: namePrefix() || "" | translate,
                name: name() | translate
              }
      }}
    </p>
    <p class="text-sm font-light">
      {{ "CREATION_SUCCESS.CREATED_DATE" | translate: { date: creationDate() | customDate: true, user: createdBy() } }}
    </p>
  </div>
  <img
    [alt]="'CREATION_SUCCESS.IMAGE' | translate"
    class="absolute bottom-0 -z-10"
    draggable="false"
    height="500"
    ngSrc="assets/images/highFive.gif"
    priority
    width="400"
  />
</div>
