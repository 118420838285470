import { inject, Injectable } from "@angular/core";
import { SystemLanguage } from "@root/shared/enums/language.enum";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  #selectedLanguage: string = "";
  #translationServe = inject(TranslateService);
  getUserLanguage(): string {
    return this.#selectedLanguage;
  }

  setUserLanguage(systemLanguage: string) {
    this.#selectedLanguage = systemLanguage;
    this.#translationServe.use(systemLanguage);
    this.#setLanguageToLocalStorage(systemLanguage);
  }

  // Setting language to local storage if needed
  #setLanguageToLocalStorage(systemLanguage: string): void {
    localStorage.setItem("systemLanguage", systemLanguage);
  }
  getLanguageFromLocalStorage(): string {
    return localStorage.getItem("systemLanguage") || SystemLanguage.English;
  }
}
