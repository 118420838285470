<div class="flex justify-end items-center gap-4 mb-6 flex-wrap-reverse">
  <!-- Custom content to be put beside the search bar -->
  <ng-content></ng-content>
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
<div [ngClass]="tableAppliedClasses()">
  <est-small-list-table
    (selectRadioGroup)="
      updateAccessLevelHashMap($event.row.id, $event.key);
      updatePropertyAccessStatus($event.row, $event.key);
      updateAccessLevelControl($event.row.id, $event.key)
    "
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [radioGroupHashMap]="propertiesAccessLevelHashMap()"
    [sortingFilterSignal]="sortingFilterSignal"
    appliedClasses="!mb-0"
  ></est-small-list-table>
</div>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
