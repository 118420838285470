import { Component, inject } from "@angular/core";
import { CustomerFollowUpComponent } from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up.component";
import { BaseCustomerCardTabComponentComponent } from "@root/shared/abstracts/base-customer-card-tab-component/base-customer-card-tab-component.component";
import { CustomerFollowUpFormGroup } from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up-form-group.type";
import { CustomerJourneysService } from "@root/data/market/customer-journeys/services/customer-journeys.service";
import { finalize } from "rxjs";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ICreateCustomerFollowUpTask } from "@root/views/main/property/property-events/customer-follow-up/create-customer-follow-up-task.interface";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";

@Component({
  selector: "est-customer-card-follow-up",
  standalone: true,
  imports: [CustomerFollowUpComponent, LoadingOverlayComponent],
  templateUrl: "./customer-card-follow-up.component.html",
  styleUrl: "./customer-card-follow-up.component.scss",
})
export class CustomerCardFollowUpComponent extends BaseCustomerCardTabComponentComponent {
  formGroup?: CustomerFollowUpFormGroup;
  readonly #customerJourneysService = inject(CustomerJourneysService);
  readonly #snackbarService = inject(SnackbarService);

  override loadData() {
    this.isLoading = true;
    this.#customerJourneysService
      .getFollowUpFormGroup(this.journeyId()()!)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.formGroup = res;
        },
      });
  }

  updateJourneyFollowUp(body: { [key: string]: any }) {
    if ("legalEntityRating" in body) {
      body["conversationRating"] = body["legalEntityRating"];
      delete body["legalEntityRating"]; // In one screen it is legalEntityRating, in this one it should be sent as conversationRating
    }
    this.#customerJourneysService.patchFollowUp(this.journeyId()()!, body).subscribe();
  }

  selectMarketingQuestion(questionId: number) {
    this.#customerJourneysService.selectMarketingQuestion(this.journeyId()()!, questionId).subscribe();
  }

  createFollowUpTask(payload: ICreateCustomerFollowUpTask) {
    this.#customerJourneysService.createFollowUpTask(this.journeyId()()!, payload).subscribe({
      next: () => this.#snackbarService.open(SnackbarType.Success, "CUSTOMERS.FOLLOW_UP_CREATED_MESSAGE"),
    });
  }
}
