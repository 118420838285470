<div class="pt-6 px-6 relative">
  <div class="flex gap-2 items-center">
    <p class="text-xl font-medium mb-1">
      {{ matDialogData.title | translate }}
    </p>
    @if (matDialogData.tooltipLabel) {
      <est-tooltip [tooltip]="matDialogData.tooltipLabel"></est-tooltip>
    }
  </div>
  <div class="absolute start-0 end-0">
    <hr class="mt-3 border-black-100" />
  </div>
  <div class="mt-8 w-96">
    <p class="text-sm font-medium pb-2">{{ "WARNING" | translate }}!</p>
    @for (description of matDialogData.descriptions; track description) {
      <p class="pb-6 font-light text-sm" [innerHTML]="description | translate"></p>
    }
  </div>
</div>
<div class="bg-black-50 px-6 py-2.5 border-t border-black-300">
  <est-button
    appliedClasses="uppercase !px-6 !py-3"
    label="CLOSE"
    buttonStyleType="button--tertiary"
    [isLoading]="false"
    buttonType="button"
    (click)="cancel()"
  ></est-button>
</div>
