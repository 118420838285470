import { Component, inject, OnInit } from "@angular/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { MatProgressBar } from "@angular/material/progress-bar";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { InputComponent } from "@root/shared/input/input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { BaseDocumentForm } from "@root/shared/abstracts/base-document-form/base-document-form.abstract";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatLabel } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { DocumentService } from "@root/data/market/documents/services/document.service";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IDocument } from "@root/data/market/documents/models/documents.model";

@Component({
  selector: "est-edit-document",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatProgressBar,
    LoadingOverlayComponent,
    InputComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatLabel,
    MatTooltip,
    MatSlideToggle,
    SelectFieldComponent,
  ],
  templateUrl: "./edit-document.component.html",
  styleUrl: "./edit-document.component.scss",
})
export class EditDocumentComponent extends BaseDocumentForm implements OnInit {
  isPageLoading = false;
  componentInputs: {
    routeId?: string;
    ids: { [key: string]: string };
    sourceLevel: SourceLevel;
  } = inject(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<EditDocumentComponent> = inject(MatDialogRef);
  readonly #documentService = inject(DocumentService);
  isDocumentEdit = false;
  documentTypes: IDropdownOption[] = [];
  isLoading: boolean = false;

  editDocument() {
    if (this.editDocumentFormGroup.invalid) return;
    this.isLoading = true;
    this.#documentService
      .updateDocument(
        {
          sourceLevel: this.componentInputs.sourceLevel,
          ids: this.componentInputs.ids,
          routeId: this.componentInputs.routeId,
        },
        this.editDocumentFormGroup.value,
      )
      .subscribe({
        complete: () => {
          this.isDocumentEdit = true;
          this.isLoading = false;
          this.dialogRef.close(this.isDocumentEdit);
        },
      });
  }

  ngOnInit() {
    this.getDocumentById();
    this.getDocumentLookups();
  }

  getDocumentById() {
    this.name.disable();
    this.isPageLoading = true;
    this.#documentService
      .getDocumentDetails({
        sourceLevel: this.componentInputs.sourceLevel,
        ids: this.componentInputs.ids,
        routeId: this.componentInputs.routeId,
      })
      .subscribe({
        next: (data: IDocument) => {
          this.editDocumentFormGroup.patchValue({ ...data, documentTypeId: data.documentType.id });
          this.isPageLoading = false;
        },
      });
  }

  getDocumentLookups() {
    this.#documentService.getDocumentTypes().subscribe((documentTypes) => {
      this.documentTypes = documentTypes;
    });
  }
}
