import { signal } from "@angular/core";
import { IMarketingQuestion } from "@root/data/market/marketing-questions/models/marketing-question.model";
import { IExternalTitle } from "@root/shared/interfaces/external-title.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { map } from "rxjs/internal/operators/map";
import { OperatorFunction } from "rxjs/internal/types";

export const sortDefaultLanguage = (externalTitles: IExternalTitle[]): IExternalTitle[] => {
  return externalTitles.sort((a, b) => {
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;
    return 0;
  });
};

export const mapMarketingQuestionsToSignalResponseSmallTableInput = (): OperatorFunction<any, any> => {
  return map((paginatedResponse: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedResponse),
      results: signal(mapMarketingQuestionsToSmallListTableInput(paginatedResponse.results)),
    };
  });
};

export const mapMarketingQuestionsToSmallListTableInput = (
  marketingQuestions: IMarketingQuestion[],
): ISmallListTableInput[] => {
  return marketingQuestions.map((marketingQuestion) => {
    return {
      ...marketingQuestion,
      source: marketingQuestion.source ?? "-",
      mouseoverMenuInputs: {
        lastChangedDate: marketingQuestion.updatedAt,
        externalTitles: sortDefaultLanguage(marketingQuestion.externalTitles),
        internalTitle: marketingQuestion.internalTitle,
      },
    };
  });
};
