import { ModelAdapter } from "@root/data/market/shared/adapters/model.adapater";
import { IUpdateCustomerPayloadDto } from "@root/data/market/legalEntities/models/update-customer-payload-dto.model";
import { IUpdateCustomerPayloadModel } from "@root/data/market/legalEntities/models/update-customer-payload.model";
import { Injectable } from "@angular/core";
import { CustomerType } from "@root/data/market/legalEntities/enums/customer-type.enum";
import { IAddress } from "@root/shared/interfaces/address.interface";
import { setEmptyStringsToNull } from "@root/shared/utilities/object.utilities";

@Injectable({
  providedIn: "root",
})
export class UpdateCustomerAdapter extends ModelAdapter<IUpdateCustomerPayloadDto, IUpdateCustomerPayloadModel> {
  override fromDto(): IUpdateCustomerPayloadModel {
    throw new Error("Not implemented.");
  }

  override toDto(model: IUpdateCustomerPayloadModel): IUpdateCustomerPayloadDto {
    const dto = {
      ...(model as Required<IUpdateCustomerPayloadModel>),
      address: model.addressInformation as Required<IAddress>,
      countryId: model.nationalityId!,
      customerType: model.customerType as CustomerType,
    };
    setEmptyStringsToNull(dto);
    return dto;
  }
}
