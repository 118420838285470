import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { ICustomerJourney } from "@root/data/market/customer-journeys/models/customer-journey.model";
import { CustomerJourneyStageTranslationMapper } from "@root/data/market/properties/mappers/customer-journey-stage-translation.mapper";

export const mapCustomerJourneysSmallListTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<ICustomerJourney>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedJourneys) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedJourneys),
      results: signal(mapCustomerJourneysToSmallListTableInput(paginatedJourneys.results)),
    };
  });
};

const mapCustomerJourneysToSmallListTableInput = (journeys: ICustomerJourney[]): ISmallListTableInput[] => {
  return journeys.map((journey) => ({
    ...journey,
    stage: CustomerJourneyStageTranslationMapper.get(journey.stage),
  }));
};
