import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { DateTime } from "luxon";
import { ILookUp } from "@root/shared/interfaces/look-up.interface";

type DropdownOptionsGeneratorConfig = {
  number: number;
  breakInterval: number;
  includeZero?: boolean;
  prefixString?: string;
  suffixString?: string;
};
export const generateDropdownOptionsFromObject = (object: any): IDropdownOption[] => {
  // Constructs dropdown options from an object
  const dropdownOptions: IDropdownOption[] = [];
  Object.keys(object).forEach((key) => {
    dropdownOptions.push({
      label: object[key],
      value: object[key],
    });
  });
  return dropdownOptions;
};

export const generateTranslatedDropdownOptionsFromEnum = (
  enumObject: any,
  enumMapper: Map<NonNullable<unknown>, string>,
): IDropdownOption[] => {
  // Constructs dropdown options from an Enum with its mapper
  const dropdownOptions: IDropdownOption[] = [];
  Object.keys(enumObject).forEach((key) => {
    const label = enumMapper.get(enumObject[key]);
    if (!label) throw new Error(`Provided enum value does not exist in the provided mapper: ${enumObject[key]}`);
    dropdownOptions.push({
      label,
      value: enumObject[key],
    });
  });
  return dropdownOptions;
};

export const generateDropdownOptionsFromList = (list: any[], isValueString = false): IDropdownOption[] => {
  const dropdownOptions: IDropdownOption[] = [];
  list.forEach((item) => {
    dropdownOptions.push({
      label: item.toString(),
      value: isValueString ? item.toString() : item,
    });
  });
  return dropdownOptions;
};

export const generateDropdownOptionsFromNumber = (
  number: number,
  isValueString = false,
  startIndex = 0,
  prefixString = "",
): IDropdownOption[] => {
  // Constructs dropdown options of numbers ranging from 0 to number
  const dropdownOptions: IDropdownOption[] = [];
  for (let index = startIndex; index <= number; index++) {
    dropdownOptions.push({
      label: prefixString + " " + index.toString(),
      value: isValueString ? index.toString() : index,
      translateKey: prefixString,
    });
  }
  return dropdownOptions;
};

export const isValueInDropdownOptions = (value: any, options: IDropdownOption[]): boolean => {
  for (const option of options) {
    if (option.value === value) return true;
  }
  return false;
};

export const generateDropdownOptionsFromNumberWithBreaks = ({
  includeZero,
  number,
  breakInterval,
  suffixString,
  prefixString,
}: DropdownOptionsGeneratorConfig): IDropdownOption[] => {
  const dropdownOptions: IDropdownOption[] = [];
  for (let index = includeZero ? 0 : breakInterval; index <= number; index += breakInterval) {
    dropdownOptions.push({
      label: (prefixString ? prefixString + " " : "") + index.toString() + (suffixString ? " " + suffixString : ""),
      value: index,
      translateKey: prefixString || suffixString,
    });
  }
  return dropdownOptions;
};

export const generateTimeZoneDropDownOptions = (): IDropdownOption[] => {
  const timeZones = Intl.supportedValuesOf("timeZone").map((timezone) => ({
    value: DateTime.local({ zone: timezone }).offset / 60,
    label: `GMT ${DateTime.local({ zone: timezone }).toFormat("ZZ")}`,
  }));

  return Array.from(new Map(timeZones.map((tz) => [tz.label, tz])).values()).sort((a, b) => a.value - b.value);
};

export const generateDropdownOptionsFromLookUps = (lookUps: ILookUp[]): IDropdownOption[] => {
  return lookUps.map((lookUp) => {
    return {
      label: lookUp.value,
      value: lookUp.id,
    };
  });
};
