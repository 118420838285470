import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const DocumentTableHeader: ITableHeader[] = [
  {
    label: "ACTIVE",
    key: "isInheritanceActive",
    type: TableHeaderType.Toggle,
    isSortable: true,
  },
  {
    label: "TYPE",
    key: "fileType",
    type: TableHeaderType.FileType,
    isSortable: true,
  },
  {
    label: "NAME",
    key: "documentName",
    type: TableHeaderType.Text,
    isSortable: true,
    maxTextLength: 70,
  },
  {
    label: "DOCUMENTS.DOCUMENT_TYPE",
    key: "documentType",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "DOCUMENTS.DATE_OF_UPLOAD",
    key: "uploadedOn",
    type: TableHeaderType.Date,
    isSortable: true,
  },
  {
    label: "DOCUMENTS.UPLOADED_BY",
    key: "uploadedBy",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "DOCUMENTS.SOURCE",
    key: "sourceLevel",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "",
    key: "id",
    type: TableHeaderType.ViewDocument,
    isSortable: false,
  },
];
