import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IDocument } from "../models/documents.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IDocumentType } from "../models/document-type.model";
import { ICreateDocumentRequest } from "@root/shared/interfaces/upload-document-payload.model";
import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { MIMETypeFileExtensionMapper } from "@root/data/market/documents/mappers/file-extension-mime-type.mapper";
import { getDocumentPopupWindowFeatures } from "@root/shared/utilities/file-popup.utilities";

const MapperFileTypeToImage = new Map<string, string>([[FileExtension.PDF, "/assets/images/icons/document-type.svg"]]);
export const mapDocumentsToSmallListTableInput = (documents: IDocument[]): ISmallListTableInput[] => {
  return documents.map((document: any) => {
    return {
      ...document,
      fileType: mapFileTypeToImage(document.fileType),
      documentTypeID: document.documentType.id,
      documentType: document.documentType.name,
      sourceLevel: document.sourceName ? `${document.sourceName} (${document.sourceLevel})` : document.sourceLevel,
    };
  });
};
export const mapFileTypeToImage = (key: string) => {
  return MapperFileTypeToImage.get(key);
};

export const mapDocumentsToSignalResponseSmallTableInput = (): OperatorFunction<any, any> => {
  return map((paginatedUsers: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedUsers),
      results: signal(mapDocumentsToSmallListTableInput(paginatedUsers.results)),
    };
  });
};

export const mapLookupItemsToDropdownOptions = (type: IDocumentType[]): IDropdownOption[] => {
  return type.map((item) => ({
    label: item.value,
    value: item.id,
  }));
};

export const convertFilesToFormData = (formData: FormData, documents: ICreateDocumentRequest[]) => {
  documents.forEach((document, index) => {
    formData.append(`documents[${index}].DocumentName`, document.DocumentName);
    formData.append(`documents[${index}].DocumentTypeId`, document.DocumentTypeId.toString());
    formData.append(`documents[${index}].Active`, document.Active.toString());
    formData.append(`documents[${index}].FormFile`, document.FormFile);
  });
};

export const extractValidFiles = (files: FileList, maxFileSize: number): File[] => {
  return Array.from(files).filter((file) => file.size <= maxFileSize * 1024 * 1024);
};

export const showDocumentOnNewTab = (document: Blob) => {
  const blobUrl = URL.createObjectURL(document);
  window.open(blobUrl, "popup", getDocumentPopupWindowFeatures());
};

export const extractValidFilesByExtension = (files: FileList, validTypes: FileExtension[]): File[] => {
  return Array.from(files).filter((file) => validTypes.includes(MIMETypeFileExtensionMapper.get(file.type)!));
};
