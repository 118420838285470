<div class="mt-4">
  <p class="text-sm font-medium mb-4">{{ "PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_ADDRESS" | translate }} #1</p>
  <est-address
    [isLoadingSignal]="isLoadingSignal"
    [hasVat]="false"
    [formGroup]="propertyAddressControls().controls.primaryAddress.controls.addressInformation"
    [lookups]="addressLookups()"
    (selectedValueChange)="selectedValueChange.emit($event)"
  ></est-address>
  <p class="text-sm font-semibold mb-4">
    {{ "PROPERTIES.DETAILS.MAIN_DETAILS.GEOGRAPHIC_LOCATION" | translate }}
  </p>

  <est-geographical-location
    [formattedAddressSignal]="formattedPrimaryAddress"
    [isLoadingSignal]="isPrimaryAddressLoadingSignal"
    [latitudeControl]="propertyAddressControls().controls.primaryAddress.controls.addressInformation.controls.latitude!"
    [longitudeControl]="
      propertyAddressControls().controls.primaryAddress.controls.addressInformation.controls.longitude!
    "
    (isGeoLocationUpdated)="geoLocationChange.emit($event)"
  ></est-geographical-location>
</div>
@if (propertyAddressControls().controls.secondaryAddress) {
  <div class="mt-4">
    <div class="flex gap-4">
      <p class="text-sm font-medium mb-5">{{ "PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_ADDRESS" | translate }} #2</p>
      <div class="w-6 h-6 border border-black-300 rounded flex items-center justify-center">
        <button class="" (click)="removeSecondaryAddress()">
          <img width="12" height="12" ngSrc="assets/images/icons/trash-icon.svg" [alt]="'DELETE' | translate" />
        </button>
      </div>
    </div>

    <est-address
      [isLoadingSignal]="isLoadingSignal"
      [hasVat]="false"
      [formGroup]="propertyAddressControls().controls.secondaryAddress!.controls.addressInformation!"
      [lookups]="addressLookups()"
      (selectedValueChange)="selectedValueChange.emit($event)"
    ></est-address>
    <p class="text-sm font-semibold mb-4">
      {{ "PROPERTIES.DETAILS.MAIN_DETAILS.GEOGRAPHIC_LOCATION" | translate }}
    </p>
    <est-geographical-location
      [formattedAddressSignal]="formattedSecondaryAddress"
      [isLoadingSignal]="isSecondaryAddressLoadingSignal"
      [latitudeControl]="
        propertyAddressControls().controls.secondaryAddress!.controls.addressInformation!.controls.latitude!
      "
      [longitudeControl]="
        propertyAddressControls().controls.secondaryAddress!.controls.addressInformation!.controls.longitude!
      "
      (isGeoLocationUpdated)="geoLocationChange.emit($event)"
    ></est-geographical-location>
  </div>
}
@if (!propertyAddressControls().controls.secondaryAddress) {
  <est-button
    appliedClasses="mt-4"
    label="PROPERTIES.ADD_EXTRA_ADDRESS"
    buttonStyleType="button--tertiary"
    buttonType="button"
    imageAlt="ADD"
    imageSrc="assets/images/icons/plus-black.svg"
    (click)="addExtraAddress()"
  ></est-button>
}
