import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { constructCustomerInformationFormGroup } from "@root/shared/utilities/customer.utilities";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";

@Injectable({
  providedIn: "root",
})
export class CustomerCardDetailsFormControls {
  readonly #fb = inject(NonNullableFormBuilder);

  phone = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue());
  isFavourite = this.#fb.control(false);

  formGroup = constructCustomerInformationFormGroup();
}
