<div class="w-[38.5rem] max-lg:w-[32rem]">
  <div class="flex mb-4 gap-4">
    <div class="flex-1">
      <est-select-field
        (changeSelectValue)="emitCountryChanged(); selectedValueChange.emit({ name: 'countryId', value: $event })"
        [control]="getControlFromAddressFormGroup(formGroup(), 'countryId')"
        [dropDownOptions]="lookups().countries"
        [selectMultipleOptions]="false"
        appliedClass="w-full"
        dropDownLabel="COUNTRY"
      ></est-select-field>
    </div>
    <div class="flex-1 relative">
      @if (hasVat()) {
        <est-input
          (focusedOut)="getAddressByVatAndCountryCode()"
          (input)="isVatOrCountryCodeChanged = true"
          [control]="getControlFromAddressFormGroup(formGroup(), 'vat')"
          [label]="'BILLING.VAT_NUMBER'"
          appliedClass="w-full"
        ></est-input>
      }
      @if (isLoadingSignal()) {
        <est-spinner appliedClasses="absolute -end-10 top-0 bottom-0 m-auto" width="25" height="25"></est-spinner>
      }
    </div>
  </div>
  @if (hasVat()) {
    <div class="flex mb-4 gap-4">
      <div class="flex-1">
        <est-input
          (focusout)="
            selectedValueChange.emit({
              name: 'companyName',
              value: getControlFromAddressFormGroup(formGroup(), 'companyName').value
            })
          "
          [control]="getControlFromAddressFormGroup(formGroup(), 'companyName')"
          appliedClass="w-full"
          label="COMPANIES.COMPANY"
        ></est-input>
      </div>
      <div class="flex-1">
        <est-input
          (focusout)="
            selectedValueChange.emit({
              name: 'careOf',
              value: getControlFromAddressFormGroup(formGroup(), 'careOf').value
            })
          "
          [control]="getControlFromAddressFormGroup(formGroup(), 'careOf')"
          appliedClass="w-full"
          label="C/O"
        ></est-input>
      </div>
    </div>
  }
</div>
<div class="flex gap-4 flex-wrap mb-4">
  <est-input
    (focusout)="
      selectedValueChange.emit({
        name: 'streetName',
        value: getControlFromAddressFormGroup(formGroup(), 'streetName').value
      })
    "
    [control]="getControlFromAddressFormGroup(formGroup(), 'streetName')"
    appliedClass="w-[18.75rem]"
    label="ADDRESS.STREET"
  ></est-input>
  <est-input
    (focusout)="
      selectedValueChange.emit({
        name: 'houseNumber',
        value: getControlFromAddressFormGroup(formGroup(), 'houseNumber').value
      })
    "
    [control]="getControlFromAddressFormGroup(formGroup(), 'houseNumber')"
    appliedClass="w-[9.375rem]"
    label="NUMBER"
  ></est-input>
  <est-select-field
    [control]="getControlFromAddressFormGroup(formGroup(), 'floor')"
    [dropDownOptions]="lookups().floors"
    [selectMultipleOptions]="false"
    appliedClass="w-[9.375rem]"
    dropDownLabel="ADDRESS.FLOOR"
    (changeSelectValue)="selectedValueChange.emit({ name: 'floor', value: $event })"
  ></est-select-field>
  <est-select-field
    [control]="getControlFromAddressFormGroup(formGroup(), 'side')"
    [dropDownOptions]="ADDRESS_SIDE_OPTIONS"
    [selectMultipleOptions]="false"
    appliedClass="w-[9.375rem]"
    dropDownLabel="ADDRESS.SIDE"
    (changeSelectValue)="selectedValueChange.emit({ name: 'side', value: $event })"
  ></est-select-field>

  <est-select-field
    [control]="getControlFromAddressFormGroup(formGroup(), 'door')"
    [dropDownOptions]="ADDRESS_DOOR_OPTIONS"
    [selectMultipleOptions]="false"
    appliedClass="w-[9.375rem]"
    dropDownLabel="ADDRESS.DOOR"
    (changeSelectValue)="selectedValueChange.emit({ name: 'door', value: $event })"
  ></est-select-field>
</div>
<div class="flex gap-4 mb-4">
  <est-input
    (focusout)="
      selectedValueChange.emit({ name: 'zipCode', value: getControlFromAddressFormGroup(formGroup(), 'zipCode').value })
    "
    [control]="getControlFromAddressFormGroup(formGroup(), 'zipCode')"
    appliedClass="w-[9.375rem]"
    label="ADDRESS.ZIP_CODE"
    type="number"
  ></est-input>
  <est-input
    (focusout)="
      selectedValueChange.emit({ name: 'city', value: getControlFromAddressFormGroup(formGroup(), 'city').value })
    "
    [control]="getControlFromAddressFormGroup(formGroup(), 'city')"
    appliedClass="w-[18.75rem]"
    label="ADDRESS.CITY"
  ></est-input>
</div>
