import {
  booleanAttribute,
  Component,
  effect,
  inject,
  Input,
  input,
  OnInit,
  output,
  signal,
  WritableSignal,
} from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "../abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableSortingFilter } from "../interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "../interfaces/pagination-sort-payload.interface";
import { constructInitialSignalPaginatedResponse, copyPaginatedSignalResponse } from "../utilities/signals.utilities";
import { listSelectPropertiesTableHeaders } from "./list-select-properties-table.headers";
import { PaginationComponent } from "../pagination/pagination.component";
import { SearchInputComponent } from "../search-input/search-input.component";
import { SmallListTableComponent } from "../small-list-table/small-list-table.component";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { NgClass } from "@angular/common";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { PropertyAccess } from "@root/data/market/properties/enums/property-access.enum";
import { FormControl } from "@angular/forms";
import { IPropertyIdAccess } from "@root/shared/list-select-properties/property-id-access.interface";

@Component({
  selector: "est-list-select-properties",
  standalone: true,
  imports: [PaginationComponent, SearchInputComponent, SmallListTableComponent, NgClass],
  templateUrl: "./list-select-properties.component.html",
  styleUrl: "./list-select-properties.component.scss",
})
export class ListSelectPropertiesComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  @Input() override paginatedData: ISignalPaginatedResponse<ISmallListTableInput> =
    constructInitialSignalPaginatedResponse();
  @Input() accessLevelControl = new FormControl<IPropertyIdAccess[]>([]);
  // We use extra hashmaps instead to enhance performance
  propertiesAccessLevelHashMap = input(new Map<number, string>()); // {key => propertyId: value => propertyAccessLevel}
  propertiesAccessStatusHashMap = input(new Map<number, boolean>()); // Stores the access status, so it would not reset on pagination
  readonly headers = listSelectPropertiesTableHeaders;
  divisionIds = input.required<number[]>();
  teamIds = input<number[]>([]);
  userId = input<number>();
  tableAppliedClasses = input("");
  acceptInitialValue = input(false, {
    // Set to true when the data is initially available before the component is loading. Must also pass paginatedData
    transform: booleanAttribute,
  });
  accessStatusChange = output<boolean>();
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  #isInitialRequest = true;
  loadDataEffect$ = effect(() => {
    this.#registerLoadDataEffectSignals();
    if (this.divisionIds().length === 0) return;
    if (this.#isInitialRequest && this.acceptInitialValue()) {
      // Do not send an extra request if the data is already available
      this.#isInitialRequest = false;
    } else {
      this.loadData({
        sortBy: this.sortingFilterSignal().sortBy,
        sortDescending: this.sortingFilterSignal().sortDescending,
        pageSize: this.paginatedData.pageSize(),
        pageNumber: this.paginatedData.currentPage(),
        search: this.searchSignal(),
        divisionIds: this.divisionIds(),
        teamIds: this.teamIds(),
        ...(this.userId() ? { userId: this.userId() } : {}),
      });
    }
  });
  readonly #propertiesService = inject(PropertiesService);

  ngOnInit() {
    if (this.acceptInitialValue())
      this.#updateAccessLevelHashMapBulkAndUpdateAccessStatus(this.paginatedData.results());
  }

  updateAccessLevelHashMap(propertyId: number, accessLevel: string): void {
    // Access type should be PropertyAccess enum. It is defined here as a string to be compatible with Small table 'key' property
    this.propertiesAccessLevelHashMap().set(propertyId, accessLevel);
  }

  updateAccessLevelControl(propertyId: number, propertyAccess: string): void {
    const propertyIdAccess = this.accessLevelControl.value?.find((property) => property.propertyId === propertyId);
    if (propertyIdAccess) propertyIdAccess.propertyAccess = propertyAccess as PropertyAccess;
    else
      this.accessLevelControl.setValue([
        ...(this.accessLevelControl.value || []),
        {
          propertyId,
          propertyAccess: propertyAccess as PropertyAccess,
        },
      ]);
  }

  updatePropertyAccessStatus(property: ISmallListTableInput, accessLevel: string): void {
    const previousAccessStatus = property.checkmarkStatus!;
    property.checkmarkStatus =
      accessLevel === PropertyAccess.Direct
        ? true
        : accessLevel === PropertyAccess.Denied
          ? false
          : property["hasTeam"];
    this.propertiesAccessStatusHashMap().set(property.id, property.checkmarkStatus!);
    if (property.checkmarkStatus !== previousAccessStatus) this.accessStatusChange.emit(property.checkmarkStatus!);
  }

  override loadData(params: IPaginationSortPayload & { divisionIds: number[]; teamIds: number[] }): void {
    this.isTableLoading = true;
    this.#propertiesService
      .getPaginatedSmallListTableInputPropertiesLookups(params)
      .subscribe((paginatedProperties) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
        this.#updateAccessLevelHashMapBulkAndUpdateAccessStatus(paginatedProperties.results());
        this.isTableLoading = false;
      });
  }

  #updateAccessLevelHashMapBulkAndUpdateAccessStatus(properties: ISmallListTableInput[]): void {
    properties.forEach((property) => {
      if (!this.propertiesAccessLevelHashMap().has(property.id)) {
        this.propertiesAccessLevelHashMap().set(property.id, property["propertyAccess"]);
      }
      if (this.propertiesAccessStatusHashMap().has(property.id)) {
        property.checkmarkStatus = this.propertiesAccessStatusHashMap().get(property.id);
      }
    });
  }

  #registerLoadDataEffectSignals(): void {
    // Registers the required signals for the effect to run
    this.searchSignal();
    this.sortingFilterSignal().sortBy, this.sortingFilterSignal().sortDescending, this.paginatedData.pageSize();
    this.paginatedData.currentPage();
    this.searchSignal();
    this.divisionIds();
    this.teamIds();
    this.userId();
  }
}
