import { Component, computed, input, signal } from "@angular/core";
import { IMatchCustomerResponse } from "@root/data/market/properties/models/match-customer-response.model";
import { TranslateModule } from "@ngx-translate/core";
import { CustomerMatchingColoringDirective } from "@root/shared/customer-matching/customer-matching-coloring.directive";
import { CustomerJourneyStageTranslationMapper } from "@root/data/market/properties/mappers/customer-journey-stage-translation.mapper";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";

@Component({
  selector: "est-customer-matching",
  standalone: true,
  imports: [TranslateModule, CustomerMatchingColoringDirective, CustomDatePipe],
  templateUrl: "./customer-matching.component.html",
  styleUrl: "./customer-matching.component.scss",
})
export class CustomerMatchingComponent {
  protected readonly CustomerJourneyStageTranslationMapper = CustomerJourneyStageTranslationMapper;
  matchData = input.required<IMatchCustomerResponse>();

  fullOldName = computed(() => {
    return this.matchData().matchResult?.firstName.concat(" ", this.matchData().matchResult?.lastName ?? "");
  });

  fullNewName = computed(() => {
    return this.matchData().matchRequest.firstName.concat(" ", this.matchData().matchRequest.lastName);
  });

  isOldPinVisible = signal(false);
  isNewPinVisible = signal(false);

  oldPin = computed(() => {
    const pin = this.matchData().matchResult?.pin;
    if (!pin) return "";
    return pin.slice(0, 6).concat(" - ", this.isOldPinVisible() ? pin.slice(6) : "****");
  });

  newPin = computed(() => {
    const pin = this.matchData().matchRequest?.pin;
    if (!pin) return "";
    return pin.slice(0, 6).concat(" - ", this.isNewPinVisible() ? pin.slice(6) : "****");
  });

  oldAddress = computed(() => {
    const address = this.matchData().matchResult?.addressInformation;
    if (!address) return "";
    return `${address.streetName || ""} ${address.houseNumber || ""}`;
  });

  newAddress = computed(() => {
    const address = this.matchData().matchRequest?.addressInformation;
    if (!address) return "";
    return `${address.streetName || ""} ${address.houseNumber || ""}`;
  });
}
