import { inject, Injectable } from "@angular/core";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { map, Observable } from "rxjs";
import { ICustomerCategory } from "@root/data/market/system-configurations/customer-category/models/customer-category.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { CustomerCategoryRepository } from "@root/data/market/system-configurations/repositories/customer-category.repository";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapCustomerCategoryToSignalResponseSmallTableInput } from "@root/data/market/system-configurations/customer-category/utlities/customer-category.utilities";
import { ICustomerCategoryLookUp } from "@root/data/market/system-configurations/customer-category/models/customer-category-look-up.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

@Injectable({
  providedIn: "root",
})
export class CustomerCategoryService {
  readonly #customerCategoryRepository = inject(CustomerCategoryRepository);

  getCustomerCategory(payload: IInheritedQueryPayload): Observable<ICustomerCategory> {
    return this.#customerCategoryRepository.getCustomerCategory(payload);
  }

  updateCustomerCategory(payload: IInheritedQueryPayload, body: ICustomerCategory): Observable<void> {
    return this.#customerCategoryRepository.updateCustomerCategory(payload, body);
  }

  createCustomerCategory(body: ICustomerCategory, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#customerCategoryRepository.createCustomerCategory(body, sourceLevel, routeId);
  }

  getPaginatedCustomerCategories(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<ISignalPaginatedResponse<ICustomerCategory>> {
    return this.#customerCategoryRepository
      .getPaginatedCustomerCategories(params, sourceLevel, routeId)
      .pipe(mapCustomerCategoryToSignalResponseSmallTableInput());
  }

  deleteCustomerCategory(payload: IInheritedQueryPayload): Observable<void> {
    return this.#customerCategoryRepository.deleteCustomerCategory(payload);
  }

  toggleCustomerCategoryInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#customerCategoryRepository.toggleCustomerCategoryInheritance(payload);
  }

  lookUpCustomerCategories(payload: IInheritedQueryPayload): Observable<ICustomerCategoryLookUp[]> {
    return this.#customerCategoryRepository.lookUpCustomerCategories(payload);
  }

  lookUpCustomerCategoriesOptions(payload: IInheritedQueryPayload) {
    return this.#customerCategoryRepository.lookUpCustomerCategories(payload).pipe(
      map((customerCategoryLookUps: ICustomerCategoryLookUp[]) =>
        customerCategoryLookUps.map<IDropdownOption>((lookUp) => {
          return {
            label: lookUp.value,
            value: lookUp.id,
          };
        }),
      ),
    );
  }
}
