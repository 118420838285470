import { inject, Injectable, signal } from "@angular/core";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import {
  mapPropertiesToSignalLargeListInputOperator,
  mapPropertiesToSignalSmallListInputOperator,
} from "@root/data/market/properties/utilities/properties.utilities";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginationSelectedIdPayload } from "@root/shared/interfaces/pagination-selected-id-payload.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedPropertyResponse } from "@root/shared/interfaces/signal-paginated-property-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { map, Observable } from "rxjs";
import { IDivisionDetails } from "../models/division-details.model";
import { DivisionRepository } from "../repositories/division.repository";
import { mapDivisionsOverviewToLargeListTableInput } from "../utilities/map-division.utilities";
import { IDropdownWithRoleNameOption } from "@root/shared/interfaces/dropdown-with-role-name-option.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";

@Injectable({ providedIn: "root" })
export class DivisionService {
  readonly #divisionRepository = inject(DivisionRepository);

  getPaginatedTableInputDivisionsOverview(
    params: IPaginationSortPayload,
  ): Observable<ISignalPaginatedResponse<ILargeListTableInput>> {
    return this.#divisionRepository.getPaginatedDivisionsOverview(params).pipe(
      map((paginatedDivisionsOverview) => {
        const divisionsOverviews = mapDivisionsOverviewToLargeListTableInput(paginatedDivisionsOverview.results);
        return {
          ...mapToSignalPaginatedResponse(paginatedDivisionsOverview),
          results: signal(divisionsOverviews),
        } as ISignalPaginatedResponse<ILargeListTableInput>;
      }),
    );
  }

  getSmallTableInputProperties(
    params: IPaginationSortPayload,
  ): Observable<ISignalPaginatedPropertyResponse<ISmallListTableInput>> {
    return this.#divisionRepository.getProperties(params).pipe(mapPropertiesToSignalSmallListInputOperator());
  }

  getPaginatedDivisionOptions(
    params: IPaginationSelectedIdPayload & { userId?: number },
  ): Observable<ISignalPaginatedResponse<IDropdownWithRoleNameOption>> {
    return this.#divisionRepository.getPaginatedDivisionsOptions(params).pipe(
      map((paginatedDivisionsOptions) => {
        return {
          ...mapToSignalPaginatedResponse(paginatedDivisionsOptions),
          results: signal(
            paginatedDivisionsOptions.results.map((division) => {
              return {
                label: division.name,
                value: division.id,
                roleName: division.roleName,
              };
            }),
          ),
        };
      }),
    );
  }

  createNewDivisionBeadOnFormData(divisionFormData: FormData): Observable<FormData> {
    return this.#divisionRepository.createNewDivision(divisionFormData);
  }

  getDivisionDetails(divisionId?: number): Observable<IDivisionDetails> {
    return this.#divisionRepository.getDivisionDetailsById(divisionId);
  }

  patchDivision(divisionDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#divisionRepository.patchDivisionDetails(divisionDetailsForm);
  }

  getProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType;
    },
  ): Observable<ISignalPaginatedPropertyResponse<ILargeListTableInput>> {
    return this.#divisionRepository.getProperties(params).pipe(mapPropertiesToSignalLargeListInputOperator());
  }

  updateDivisionDetails(divisionDetails: FormData, divisionId: number): Observable<IDivisionDetails> {
    return this.#divisionRepository.updateDivisionDetails(divisionDetails, divisionId);
  }
}
