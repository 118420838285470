import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightSearch",
  standalone: true,
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    if (!value) {
      return;
    }

    value = value.toString();

    const regex = new RegExp(args, "gi");
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(regex, `<span class='bg-success-dark font-bold'>${match[0]}</span>`);
  }
}
