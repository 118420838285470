import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgOptimizedImage } from "@angular/common";

@Component({
  selector: "est-under-development",
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage],
  templateUrl: "./under-development.component.html",
  styleUrl: "./under-development.component.scss",
})
export class UnderDevelopmentComponent {}
