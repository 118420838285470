export const isElementCloseToBottom = (element: HTMLElement, threshold = 0, outerElement?: HTMLElement): boolean => {
  let outerElementMidPoint;
  if (threshold) {
    return outerElement
      ? outerElement.getBoundingClientRect().top +
          outerElement.getBoundingClientRect().height -
          element.getBoundingClientRect().bottom <=
          threshold
      : window.innerHeight - element.getBoundingClientRect().bottom <= threshold;
  }
  if (outerElement) {
    outerElementMidPoint = outerElement.getBoundingClientRect().top + outerElement.getBoundingClientRect().height / 2;
  } else {
    outerElementMidPoint = window.innerHeight / 2;
  }
  return element.getBoundingClientRect().top > outerElementMidPoint;
};
