import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { APIS } from "../../../../core/api/market.apis";
import { IAddressInformation } from "../models/address-information.model";

@Injectable({ providedIn: "root" })
export class AddressRepository {
  readonly #http = inject(HttpClient);

  getAddressInformationByVat(vat: string, countryCode: string): Observable<IAddressInformation> {
    return this.#http.get<IAddressInformation>(APIS.addresses.getAddress, {
      params: new HttpParams({
        fromObject: {
          vatNumber: vat,
          countryCode: countryCode,
        },
      }),
    });
  }
}
