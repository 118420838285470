import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { Observable } from "rxjs/internal/Observable";
import { SourceLevelDemographicQuestionsApiMapper } from "../mappers/demographic-question-api.mapper";
import { IDemographicQuestion } from "../models/demographic-question.model";

@Injectable({
  providedIn: "root",
})
export class DemographicQuestionsRepository {
  readonly #http = inject(HttpClient);
  readonly #apiMap = SourceLevelDemographicQuestionsApiMapper;

  getPaginatedDemographicQuestions(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
  ): Observable<IPaginatedResponse<IDemographicQuestion>> {
    return this.#http.get<IPaginatedResponse<IDemographicQuestion>>(this.#apiMap.get(sourceLevel)!.getAll(), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteDemographicQuestion(sourceLevel: SourceLevel, id: number): Observable<void> {
    return this.#http.delete<void>(this.#apiMap.get(sourceLevel)!.delete(id));
  }

  toggleDemographicQuestionInheritance(sourceLevel: SourceLevel, id: number): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(sourceLevel)!.toggleActivation(id), {});
  }

  lookUpDemographicQuestions(sourceLevel: SourceLevel, routeId?: string): Observable<IDemographicQuestionLookUp[]> {
    return this.#http.get<IDemographicQuestionLookUp[]>(this.#apiMap.get(sourceLevel)!.lookUp(routeId));
  }

  removeDemographicQuestionAnswer(demographicQuestionAnswerId: number): Observable<void> {
    return this.#http.delete<void>(APIS.demographicQuestions.removeDemographicQuestionAnswer, {
      body: {
        demographicQuestionAnswerId: demographicQuestionAnswerId,
      },
    });
  }

  answerDemographicQuestion(
    demographicQuestionId: number,
    legalEntityId: number,
    answerOptionIds?: number[],
    answerInputValue?: string,
  ): Observable<void> {
    return this.#http.post<void>(APIS.demographicQuestions.answerDemographicQuestion(demographicQuestionId), {
      legalEntityId: legalEntityId,
      answerOptionIds: answerOptionIds,
      answerInputValue: answerInputValue,
    });
  }
}
