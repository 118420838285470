import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DatePipe, NgOptimizedImage } from "@angular/common";
import { CustomDatePipe } from "../../core/pipes/custom-date.pipe";

@Component({
  selector: "est-creation-success-message",
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage, DatePipe, CustomDatePipe],
  templateUrl: "./creation-success-message.component.html",
  styleUrl: "./creation-success-message.component.scss",
})
export class CreationSuccessMessageComponent {
  namePrefix = input<string>();
  creationDate = input(new Date().toISOString());
  name = input.required<string>();
  description = input.required<string>();
  createdBy = input<string>();
}
