import { inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { DemographicQuestionService } from "@root/data/market/demographic-questions/services/demographic-question.service";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { CustomerCategoryService } from "@root/data/market/system-configurations/services/customer-category.service";
import { UsersService } from "@root/data/market/users/services/users.service";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { ICountry } from "@root/shared/interfaces/country.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { IInviteCustomerOnsiteInputs } from "@root/shared/invite-customer-onsite/invite-customer-onsite-inputs.interface";
import { InviteCustomerOnsiteFormControls } from "@root/shared/invite-customer-onsite/invite-customer-onsite.form-controls";
import { constructInitialAddressLookups } from "@root/shared/utilities/address.utilities";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";
import { IUpdateCustomerPayloadModel } from "@root/data/market/legalEntities/models/update-customer-payload.model";
import { IInviteCustomerOnsiteModel } from "@root/data/market/legalEntities/models/invite-customer-onsite.model";

@Injectable()
export class InviteCustomerOnsiteFacadeService {
  readonly componentInputs: IInviteCustomerOnsiteInputs = inject(MAT_DIALOG_DATA);
  readonly inviteCustomerFormControls = inject(InviteCustomerOnsiteFormControls);
  addressLookups: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  languageOptions: ILanguage[] = [];
  countries: ICountry[] = [];
  demographicQuestionLookUps: IDemographicQuestionLookUp[] = [];
  customerCategoryOptions: IDropdownOption[] = [];
  readonly #lookUpsService = inject(LookupsService);
  readonly #userService = inject(UsersService);
  readonly #demographicQuestionService = inject(DemographicQuestionService);
  readonly #customerCategoryService = inject(CustomerCategoryService);
  readonly #propertiesEventsService = inject(PropertiesEventsService);
  readonly #legalEntityService = inject(LegalEntityService);
  readonly #matDialog = inject(MatDialog);
  readonly #fb = inject(FormBuilder);
  readonly phoneControl = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue(), {
    nonNullable: true,
  });
  #updatePhoneFormControl$ = this.phoneControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
    this.inviteCustomerFormControls.form.controls.customer.controls.phoneNumber.setValue(value?.number || "");
    this.inviteCustomerFormControls.form.controls.customer.controls.phoneIsdCode.setValue(value?.dialCode || "");
  });

  constructor() {
    this.#setEventInstanceAndTimeslotIds();
    this.#getAddressLookUps();
    this.#getLanguages();
    this.#getCountries();
    this.#getDemographicQuestions();
    this.#getCustomerCategories();
  }

  matchCustomer(value: typeof this.inviteCustomerFormControls.form.value.customer) {
    if (!value) return;
    return this.#propertiesEventsService.matchCustomer({
      birthday: value.birthday,
      firstName: value.firstName!,
      lastName: value.lastName!,
      phoneNumber: value.phoneNumber!,
      careOf: value.addressInformation?.careOf,
      city: value.addressInformation?.city,
      countryId: value.addressInformation?.countryId,
      door: value.addressInformation?.door as number,
      email: value.email,
      floor: value.addressInformation?.floor as number,
      houseNumber: value.addressInformation?.houseNumber as number,
      nationality: value.nationalityId,
      pin: value.pin,
      side: value.addressInformation?.side,
      streetName: value.addressInformation?.streetName,
      vat: value.addressInformation?.vat,
      zipCode: value.addressInformation?.zipCode,
    });
  }

  inviteCustomer() {
    this.inviteCustomerFormControls.form.value.customer;
    return this.#legalEntityService.inviteCustomerOnsite(
      this.inviteCustomerFormControls.form.value as IInviteCustomerOnsiteModel,
    );
  }

  updateCustomer(id: number, body: IUpdateCustomerPayloadModel) {
    return this.#legalEntityService.updateCustomer(id, body);
  }

  closeDialog() {
    this.#matDialog.closeAll();
  }

  #setEventInstanceAndTimeslotIds() {
    if (this.componentInputs.eventInstanceId)
      this.inviteCustomerFormControls.form.controls.eventInstanceId.setValue(this.componentInputs.eventInstanceId);
    if (this.componentInputs.eventTimeslotId)
      this.inviteCustomerFormControls.form.controls.eventTimeSlotId.setValue(this.componentInputs.eventTimeslotId);
  }

  #getAddressLookUps() {
    this.#lookUpsService.getAddressLookupOptions().subscribe((lookUps) => (this.addressLookups = lookUps));
  }

  #getLanguages() {
    this.#userService.getAllLanguages().subscribe((languages) => (this.languageOptions = languages));
  }

  #getCountries() {
    this.#lookUpsService.getAllCountries().subscribe((countries) => (this.countries = countries));
  }

  #getDemographicQuestions() {
    this.#demographicQuestionService
      .lookUpDemographicQuestions(this.componentInputs.sourceLevel)
      .subscribe((demographicQuestions) => (this.demographicQuestionLookUps = demographicQuestions));
  }

  #getCustomerCategories() {
    this.#customerCategoryService
      .lookUpCustomerCategoriesOptions({
        sourceLevel: this.componentInputs.sourceLevel,
      })
      .subscribe((options) => (this.customerCategoryOptions = options));
  }
}
