@if (demographicQuestionLookUp.demographicQuestionAnswerOptions.length === 0) {
  <est-input
    [label]="demographicQuestionLookUp.value"
    [control]="castControlFromAbstractToFormControl(control().controls['textAnswer'])"
  ></est-input>
} @else {
  <est-select-field
    [selectMultipleOptions]="demographicQuestionLookUp.answerOptionType === AnswerOptionType.Multi"
    [control]="castControlFromAbstractToFormControl(control().controls['answerOptionIds'])"
    [dropDownLabel]="demographicQuestionLookUp.value"
    [dropDownOptions]="answerOptions"
  ></est-select-field>
}
