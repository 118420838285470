<mat-form-field
  [attr.aria-required]="control().hasValidator(Validators.required)"
  [ngClass]="appliedClasses()"
  appearance="outline"
  hideRequiredMarker
  [id]="id()"
  subscriptSizing="dynamic"
>
  <img
    [alt]="'SELECT_MENU' | translate"
    [priority]="true"
    class="absolute end-0 translate-y-full"
    height="8"
    ngSrc="assets/images/icons/arrow-down.svg"
    width="14"
  />
  @if (withLabel()) {
    <mat-label [attr.aria-labelledby]="'LANGUAGE' | translate">{{ "LANGUAGE" | translate }}</mat-label>
  }
  <mat-select
    #selected
    (selectionChange)="selectionChanged.emit()"
    [formControl]="control()"
    [multiple]="selectMultipleOptions()"
    [compareWith]="compareWithFunction"
  >
    <mat-select-trigger>
      <div class="flex justify-start items-center gap-2">
        <img
          [alt]="'FLAG' | translate"
          [src]="getSelectChange(selected.value)?.flagImageUrl"
          class="w-6 h-4 rounded-sm"
        />
        @if (withLanguageName()) {
          {{ getSelectChange(selected.value)?.name }}
        }
      </div>
    </mat-select-trigger>
    @for (country of countries(); track trackByFunction) {
      <mat-option [value]="getCountryValue(country)">
        <div class="flex justify-start items-center gap-2">
          <img class="w-6 h-4 rounded-sm" [src]="country.flagImageUrl" [alt]="'FLAG' | translate" />
          @if (withLanguageName()) {
            <div>{{ country.name }}</div>
          }
        </div>
      </mat-option>
      <mat-divider />
    }
  </mat-select>
</mat-form-field>
@if (withErrorMessage() && control().touched && control().invalid && control().hasError("required")) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ "LANGUAGE" | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
  </mat-error>
}
