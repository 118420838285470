import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { UserRole } from "../../data/market/users/enums/user-role.enum";
import { BillingMethod } from "../../data/market/division/enums/billing-method.enum";

// Order is extremely important.
export const USER_ROLES_ORDERED = [
  UserRole.Viewer,
  UserRole.GenericUser,
  UserRole.ComplianceManager,
  UserRole.DivisionAdmin,
  UserRole.SystemAdmin,
];
export const NUMBER_OF_PX_TO_SHOW_DROPDOWN_TO_TOP = 400;
export const SYSTEM_ADMIN_ROLE_ID = "7";
export const BILLING_DETAILS_OPTIONS: IDropdownOption[] = [
  {
    label: "BILLING.ON_DIVISION_LEVEL",
    value: BillingMethod.OnDivision,
  },
  {
    label: "BILLING.ON_COMPANY_LEVEL",
    value: BillingMethod.OnCompany,
  },
  {
    label: "BILLING.ON_PROPERTY_LEVEL",
    value: BillingMethod.OnProperty,
  },
];

export const DIVISION_HIERARCHY_RESULT = {
  NO_PROPERTIES: "No_properties",
  NO_COMPANIES: "No_companies",
};
export const DATE_PIPE_FORMAT = "dd. MMM. yyyy '|' hh:mm:ss";

export const M_SQUARE = "m\u00B2";
