import { Component, computed, DestroyRef, effect, inject, input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { isDateValid } from "@root/shared/utilities/date.utilities";
import { DateTime } from "luxon";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { shouldShowControlRequiredError } from "@root/shared/utilities/form.utilities";

/*
  @component
  @description Time picker which outputs the time in the form of ISO 8601
 */
@Component({
  selector: "est-time-picker",
  standalone: true,
  imports: [TranslateModule, NgClass, ReactiveFormsModule, MatFormField, MatInput, MatLabel, MatError, MatIcon],
  templateUrl: "./time-picker.component.html",
  styleUrl: "./time-picker.component.scss",
})
export class TimePickerComponent implements OnInit {
  /*
    Control which will hold the ISO datetime
   */
  control = input(new FormControl());
  appliedClasses = input("");
  label = input<string>("");
  /*
    The ISO date which will be reflected in the provided control
   */
  isoDate = input<string | null>(DateTime.now().toISO());
  /*
    Holds the value of the native time picker to be mapped to the provided control
   */
  innerControl = new FormControl();
  isRequired = computed(() => this.control()?.hasValidator(Validators.required));
  requiredLabel = computed(() => (this.control()?.hasValidator(Validators.required) ? "REQUIRED" : ""));
  readonly #destroyRef = inject(DestroyRef);

  protected readonly shouldShowControlRequiredError = shouldShowControlRequiredError;
  protected readonly Object = Object;
  readonly #updateControlOnInnerControlChange$ = this.innerControl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((time) => {
      this.#updateControlValue(this.isoDate(), time);
    });
  readonly #updateControlOnDatepickerControlChange$ = effect(() => {
    this.#updateControlValue(this.isoDate(), this.innerControl.value);
  });

  ngOnInit() {
    this.#setInnerControlInitialTime();
    this.#setControlWithValue();
    this.control().disabled ? this.innerControl.disable() : this.innerControl.enable();
  }

  #setControlWithValue() {
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#setInnerControlInitialTime();
      });
  }

  #setInnerControlInitialTime() {
    if (!this.control().value || !isDateValid(this.control().value)) return;
    const dateTime = DateTime.fromISO(this.control().value);
    this.innerControl.setValue(dateTime.toFormat("HH:mm"), { emitEvent: false });
  }

  #updateControlValue(date: string | null, time: string) {
    if (!time) {
      this.control().setValue(null);
      return;
    }
    const dateTime = DateTime.fromISO(date || DateTime.now().toISO());
    const [hour, minute] = time.split(":");
    const newDate = dateTime.set({
      hour: +hour,
      minute: +minute,
    });
    this.control().setValue(newDate.toISO());
  }
}
