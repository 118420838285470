<div class="text-end mb-6">
  <est-search-input [searchSignal]="searchSignal" placeholder="SEARCH"></est-search-input>
</div>
<est-small-list-table
  [checkboxControlMapper]="controlMapper()"
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
  (checkItem)="checkTeam($event)"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
