import { NgClass } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatError, MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { shouldShowControlRequiredError } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-text-area",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatLabel,
    TranslateModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    NgClass,
    MatError,
    MatIconModule,
  ],
  templateUrl: "./text-area.component.html",
  styleUrl: "./text-area.component.scss",
})
export class TextAreaComponent {
  protected readonly shouldShowControlRequiredError = shouldShowControlRequiredError;
  control = input<FormControl>(new FormControl());
  label = input<string>("");
  appliedClass = input<string>("");
  isRequired = computed(() => this.control().hasValidator(Validators.required));
}
