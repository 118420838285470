import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
  ɵGetProperty,
} from "@angular/forms";
import { IAddressFormGroup } from "../interfaces/address-form-group.interface";
import { latLongValidators } from "@root/shared/validators/lat-long.validators";
import { IPhoneNumber } from "../interfaces/phone-number.interface";
import { OperationTypeEnum } from "@root/core/enum/operation-type.enum";

export const castControl = (control: AbstractControl<ɵGetProperty<string, string>> | null) => {
  if (control instanceof FormControl) return control;
  return control as FormControl;
};

export const castControlFromAbstractToFormControl = (control: AbstractControl): FormControl => {
  if (control instanceof FormControl) return control;
  return control as FormControl;
};

export const castControlFromAbstractToFormGroup = (control: AbstractControl): FormGroup => {
  if (control instanceof FormGroup) return control;
  return control as FormGroup;
};

export const castControlFromAbstractToFormArray = (control: AbstractControl): FormArray => {
  if (control instanceof FormArray) return control;
  return control as FormArray;
};

export const markControlAsTouchedAndDirty = (abstractControl: AbstractControl, onlySelf = false): void => {
  abstractControl.markAsDirty({ onlySelf });
  abstractControl.markAsTouched({ onlySelf });
};

export const markAllControlsAsTouchedAndDirty = (abstractControl: AbstractControl, onlySelf = false): void => {
  if (abstractControl instanceof FormControl) {
    markControlAsTouchedAndDirty(abstractControl);
    return;
  } else if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray)
    Object.keys(abstractControl.controls).forEach((key) => {
      const control = abstractControl.get(key);
      markControlAsTouchedAndDirty(control!, onlySelf);
      if (control instanceof FormGroup || control instanceof FormArray) {
        markAllControlsAsTouchedAndDirty(control);
      }
    });
};

// added hasVat condition to construct controls only if needed, as it prevents stepper from moving next because controls are invalid,
export const constructAddressFormGroup = (
  fb: NonNullableFormBuilder,
  hasVat: boolean = true,
  hasGeoLocation: boolean = true,
): FormGroup<IAddressFormGroup> => {
  // Dependent on form builder
  return fb.group<IAddressFormGroup>({
    countryId: fb.control("", Validators.required),
    streetName: fb.control("", Validators.required),
    houseNumber: fb.control("", Validators.required),
    floor: fb.control(null),
    side: fb.control(null),
    door: fb.control(null),
    zipCode: fb.control("", Validators.required),
    city: fb.control("", Validators.required),
    ...(hasGeoLocation && {
      longitude: fb.control("", [Validators.required, latLongValidators("long")]),
      latitude: fb.control("", [Validators.required, latLongValidators("lat")]),
    }),
    ...(hasVat && {
      vat: fb.control("", Validators.required),
      companyName: fb.control("", Validators.required),
      careOf: fb.control(""),
    }),
  });
};

export const getControlFromAddressFormGroup = (
  addressGroup: FormGroup<IAddressFormGroup>,
  controlName: keyof IAddressFormGroup,
): FormControl => {
  return addressGroup.get(controlName) as FormControl;
};

export const disableAllControlsInFormGroup = (abstractControl: AbstractControl, except?: string) => {
  if (abstractControl instanceof FormGroup) {
    Object.keys(abstractControl.controls).forEach((controlName) => {
      const control = abstractControl.get(controlName);
      if (control instanceof FormGroup) {
        disableAllControlsInFormGroup(control, except);
      } else if (control instanceof FormControl && controlName !== except) {
        control?.disable({ emitEvent: false });
      }
    });
  }
};

export const enableAllControlsInFormGroup = (abstractControl: AbstractControl): void => {
  if (abstractControl instanceof FormGroup) {
    Object.keys(abstractControl.controls).forEach((controlName) => {
      const control = abstractControl.get(controlName);
      if (control instanceof FormGroup) {
        enableAllControlsInFormGroup(control);
      } else if (control instanceof FormControl) {
        control?.enable({ emitEvent: false });
      }
    });
  }
};

export const updateAllValueAndValidityInFormGroup = (abstractControl: AbstractControl): void => {
  if (abstractControl instanceof FormGroup) {
    abstractControl.updateValueAndValidity();
    Object.keys(abstractControl.controls).forEach((controlName) => {
      const control = abstractControl.get(controlName);
      control && control.updateValueAndValidity();
      if (control instanceof FormGroup) {
        updateAllValueAndValidityInFormGroup(control);
      }
    });
  }
};
export const updateNestedControlsPathAndValue = (
  formGroup: FormGroup | FormArray,
  ignoreParent: boolean = false,
  parentKeys: string[] = [],
): { [key: string]: any } => {
  const controlPaths: { [key: string]: any } = {};
  Object.keys(formGroup.controls).forEach((controlName: string) => {
    const control = formGroup.get(controlName);
    if (control instanceof FormGroup || control instanceof FormArray) {
      Object.assign(
        controlPaths,
        updateNestedControlsPathAndValue(control, ignoreParent, [...parentKeys, controlName]),
      );
    } else if (control?.dirty) {
      if (control.valid) {
        control.markAsPristine();
        if (ignoreParent) {
          controlPaths[controlName] = control.value;
        } else {
          const keyPath = [...parentKeys, controlName].join("/");
          controlPaths[keyPath] = control.value;
        }
      }
    }
  });
  return controlPaths;
};

export const getControlInFormArray = (
  formArray: FormArray,
  abstractControlName: string,
  index: number,
): FormControl => {
  const controls = formArray.at(index);
  const Control = controls.get(abstractControlName);
  return castControl(Control);
};

export const getControlInFormGroup = (formGroup: FormGroup, abstractControlName: string): FormControl => {
  const control = formGroup.get(abstractControlName);
  return castControl(control);
};

export const getControlValueInFormGroup = (formGroup: FormGroup, abstractControlName: string): string => {
  const control = formGroup.get(abstractControlName);
  return control?.value;
};

export const shouldShowControlRequiredError = (control: AbstractControl): boolean => {
  return control.invalid && control.touched && control.dirty && control.hasValidator(Validators.required);
};

// utility for patching select inputs
export const updateControlAndPatch = (
  value: string | number,
  filedName: string,
  formGroup: FormGroup,
  PatchValueFunction: () => void,
): void => {
  const control = formGroup.get(filedName);
  if (control?.invalid) {
    return;
  }
  control?.setValue(value);
  PatchValueFunction();
};

export const mergeFormGroups = (target: FormGroup, origin: FormGroup): FormGroup => {
  for (const property in origin.controls) {
    target.setControl(property, origin.controls[property]);
  }

  return target;
};

// Utility function to check if phone form control dirty or not
// can't use the .dirty as it's always being set to true from the ngx-intl-tel-input initialization.
// An issue with the package was open since 2020: https://github.com/webcat12345/ngx-intl-tel-input/issues/340
export const arePhoneControlsDirty = (phoneControl: FormControl<IPhoneNumber>, parentControl: FormGroup): boolean => {
  if (!phoneControl.value) return false;
  const { number: newNumber, countryCode: newCountryCode } = phoneControl.value;
  const { contactPersonPhoneNo: oldNumber, contactPersonIsdCode: oldCountryCode } = parentControl.value;
  const isNumberDirty = newNumber !== oldNumber;
  const isCountryCodeDirty = newCountryCode !== oldCountryCode;
  return isNumberDirty || isCountryCodeDirty;
};

export const addPatchJsonParser = (body: any) => {
  if (Array.isArray(body)) {
    return body; // If body is already an array of operations, return it directly
  } else if (typeof body === "object" && body !== null) {
    // Convert body to a single replace operation if it is an object
    return Object.entries(body).map(([key, value]) => ({
      op: OperationTypeEnum.Replace,
      path: `/${key}`,
      value,
    }));
  } else {
    return [];
  }
};
