import { Component, DestroyRef, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { MatFormField } from "@angular/material/form-field";
import { InputComponent } from "@root/shared/input/input.component";
import { FormGroup } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { AnswerOptionType } from "@root/data/market/demographic-questions/enums/answer-option-type.enum";
import { generateDropdownOptionsFromLookUps } from "@root/shared/utilities/dropdown-options.utilities";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-demographic-question-answer",
  standalone: true,
  imports: [MatFormField, InputComponent, SelectFieldComponent],
  templateUrl: "./demographic-question-answer.component.html",
  styleUrl: "./demographic-question-answer.component.scss",
})
export class DemographicQuestionAnswerComponent implements OnInit {
  @Output() answerDemographicQuestion = new EventEmitter<any>();
  readonly #destroyRef = inject(DestroyRef);
  demographicQuestionLookUps = input.required<IDemographicQuestionLookUp[]>();
  control = input.required<FormGroup>();
  answerOptions: IDropdownOption[] = [];
  demographicQuestionLookUp!: IDemographicQuestionLookUp;
  protected readonly AnswerOptionType = AnswerOptionType;
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;

  ngOnInit() {
    this.demographicQuestionLookUp = this.demographicQuestionLookUps().find(
      (x) => x.id == this.control().controls["questionId"].value,
    )!;
    this.answerOptions = generateDropdownOptionsFromLookUps(
      this.demographicQuestionLookUp.demographicQuestionAnswerOptions,
    );
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => {
        if (this.control().dirty) {
          this.answerDemographicQuestion.emit(value);
          this.control().markAsPristine();
        }
      });
  }
}
