<div class="flex justify-between gap-2 gap-y-4 mb-6 max-xl:flex-col">
  <est-small-header
    heading="DOCUMENTS.DOCUMENTS_ON_THE_PROPERTY"
    subheading="DOCUMENTS.BELOW_ARE_THE_DOCUMENTS_PROPERTY"
    tooltip="DOCUMENTS.DOCUMENTS_ON_THE_PROPERTY"
  ></est-small-header>
  <div class="flex gap-4 items-stretch">
    <est-search-input [searchSignal]="searchSignal" placeholder="SEARCH"></est-search-input>
    <est-button
      imageSrc="/assets/images/icons/plus.svg"
      imageAlt="ADD"
      label="UPLOAD"
      buttonType="button"
      appliedClasses="!px-6"
      [buttonStyleType]="'button--primary'"
      (click)="openUploadDocumentsModal()"
    >
    </est-button>
  </div>
</div>
<div class="text-end mb-4 gap-4 flex xl:justify-end"></div>
<est-small-list-table
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
  (itemPopup)="viewDocument($event)"
  [showOrHideLoaderOnClickedRow]="restRow"
  (toggleItem)="updateDocumentInheritance($event)"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
