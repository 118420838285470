import { Component, inject, input, Input, signal } from "@angular/core";
import { SelectSearchFieldComponent } from "../select-search-field/select-search-field.component";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { DivisionService } from "../../data/market/division/services/division.service";
import { BaseCompositeSelectSearchFieldComponent } from "../abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { IDropdownWithRoleNameOption } from "../interfaces/dropdown-with-role-name-option.interface";

@Component({
  selector: "est-select-division-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./select-division-field.component.html",
  styleUrl: "./select-division-field.component.scss",
})
export class SelectDivisionFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  override selectedDropdownOptionSignal = signal<IDropdownWithRoleNameOption | undefined>(undefined);
  @Input() override paginatedOptions = constructInitialSignalPaginatedResponse<IDropdownWithRoleNameOption>();
  userId = input<number>();
  #divisionService = inject(DivisionService);

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownWithRoleNameOption>> {
    return this.#divisionService.getPaginatedDivisionOptions({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      ...(this.userId() ? { userId: this.userId() } : {}),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
