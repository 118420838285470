import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-file-upload-success-toast",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./file-upload-success-toast.component.html",
  styleUrl: "./file-upload-success-toast.component.scss",
})
export class FileUploadSuccessToastComponent {
  totalFiles = input<number>();
}
