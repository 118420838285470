import { TableHeaderType } from "../enums/table-header-type.enum";
import { ITableHeader } from "../interfaces/table-header.interface";
import { PropertyAccess } from "@root/data/market/properties/enums/property-access.enum";

export const listSelectPropertiesTableHeaders: ITableHeader[] = [
  {
    label: "",
    key: PropertyAccess.Direct,
    type: TableHeaderType.RadioGroup,
    labelIconUrl: "assets/images/icons/opened-door.svg",
    labelIconTooltipTitle: "PROPERTIES.DIRECT_ACCESS_TITLE",
    labelIconTooltipText: "PROPERTIES.DIRECT_ACCESS_MESSAGE",
    isSortable: false,
  },
  {
    label: "",
    key: PropertyAccess.Denied,
    type: TableHeaderType.RadioGroup,
    labelIconUrl: "assets/images/icons/closed-door.svg",
    labelIconTooltipTitle: "ACCESS_DENIED",
    labelIconTooltipText: "PROPERTIES.ACCESS_DENIED_MESSAGE",
    isSortable: false,
  },
  {
    label: "",
    key: PropertyAccess.Team,
    type: TableHeaderType.RadioGroup,
    labelIconUrl: "assets/images/icons/team.svg",
    labelIconTooltipTitle: "PROPERTIES.TEAM_ACCESS_TITLE",
    labelIconTooltipText: "PROPERTIES.TEAM_ACCESS_MESSAGE",
    isSortable: false,
  },
  {
    label: "ACCESS_STATUS",
    key: "accessStatus",
    type: TableHeaderType.Checkmark,
    isSortable: false,
  },
  {
    label: "PROPERTIES.FORM.PROPERTY_NAME",
    key: "name",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "PROPERTIES.ADDRESS",
    key: "address",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "PROPERTIES.LISTING_TYPE",
    key: "listingType",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
  {
    label: "PROPERTIES.NUMBER_OF_UNITS",
    key: "unitsNumber",
    type: TableHeaderType.Text,
    isSortable: true,
    partialTranslationKey: "UNITS",
  },
  {
    label: "PROPERTIES.ACTIVE_USERS",
    key: "activeUsers",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
  {
    label: "DIVISIONS.DIVISION",
    key: "division",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
];
