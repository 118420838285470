import { MatDateFormats } from "@angular/material/core";
import { SUPPORTED_DATE_FORMATS_FOR_PARSING } from "../constants/supported-date-formats.constants";

export const dateFormat: MatDateFormats = {
  parse: {
    dateInput: SUPPORTED_DATE_FORMATS_FOR_PARSING,
  },
  display: {
    dateInput: "DD",
    monthYearLabel: "MMM yyyy",
    dateA11yLabel: "DD",
    monthYearA11yLabel: "MMMM yyyy",
  },
};
