import { DatePipe, NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { DATE_PIPE_FORMAT } from "@root/shared/constants/global.constants";

@Component({
  selector: "est-subheader",
  standalone: true,
  imports: [TranslateModule, DatePipe, MatTooltip, TooltipComponent, NgClass],
  templateUrl: "./subheader.component.html",
  styleUrl: "./subheader.component.scss",
})
export class SubheaderComponent {
  label = input.required<string>();
  date = input.required<string | Date>();
  name = input.required<string>();
  tooltip = input.required<string>();
  appliedClass = input("");
  protected readonly DATE_PIPE_FORMAT = DATE_PIPE_FORMAT;
}
