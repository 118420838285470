import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { IMarketingQuestion } from "@root/data/market/marketing-questions/models/marketing-question.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { SourceLevelMarketingQuestionsApiMapper } from "@root/data/market/marketing-questions/mappers/marketing-question-api.mapper";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { IMarketingQuestionLookup } from "@root/data/market/marketing-questions/models/marketing-question-lookup.model";
import { APIS } from "@root/core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class MarketingQuestionsRepository {
  readonly #http = inject(HttpClient);
  readonly #apiMap = SourceLevelMarketingQuestionsApiMapper;

  getMarketingQuestion(payload: IInheritedQueryPayload): Observable<IMarketingQuestion> {
    return this.#http.get<IMarketingQuestion>(this.#apiMap.get(payload.sourceLevel)!.get(payload.routeId), {
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }

  updateMarketingQuestion(payload: IInheritedQueryPayload, body: any): Observable<void> {
    return this.#http.put<void>(this.#apiMap.get(payload.sourceLevel)!.update(payload.routeId), {
      ...payload.ids,
      ...body,
    });
  }

  createMarketingQuestion(body: any, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(sourceLevel)!.create(routeId), body);
  }

  getPaginatedMarketingQuestions(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<IPaginatedResponse<IMarketingQuestion>> {
    return this.#http.get<IPaginatedResponse<IMarketingQuestion>>(this.#apiMap.get(sourceLevel)!.getAll(routeId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteMarketingQuestion(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.delete<void>(this.#apiMap.get(payload.sourceLevel)!.delete(payload.routeId), {
      body: { ...payload.ids },
    });
  }

  toggleMarketingQuestionInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(payload.sourceLevel)!.toggleInheritance(payload.routeId), {
      ...payload.ids,
    });
  }

  toggleBlockedMarketingQuestion(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(this.#apiMap.get(payload.sourceLevel)!.toggleBlocked(payload.routeId), {
      ...payload.ids,
    });
  }

  getMarketingQuestionsLookups(): Observable<IMarketingQuestionLookup[]> {
    return this.#http.get<IMarketingQuestionLookup[]>(APIS.organisation.getMarketingQuestionsLookups);
  }
}
