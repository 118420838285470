<button [ngClass]="appliedClasses()" class="cursor-pointer h-full" (click)="toggleFavourite()" type="button">
  <img
    class="max-w-none my-auto"
    [alt]="(control().value ? 'UNFAVORITE' : 'FAVORITE') | translate"
    [ngSrc]="control().value ? 'assets/images/icons/heart-solid.svg' : 'assets/images/icons/heart-outlined.svg'"
    priority="true"
    width="20"
    height="17"
  />
</button>
