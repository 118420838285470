import { Component, inject, Input, input, signal, WritableSignal } from "@angular/core";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { CustomerJourneysService } from "@root/data/market/customer-journeys/services/customer-journeys.service";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { AuthService } from "@root/data/market/auth/services/auth.service";

@Component({
  selector: "est-select-search-available-responsible-agents",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./select-search-available-responsible-agents.component.html",
  styleUrl: "./select-search-available-responsible-agents.component.scss",
})
export class SelectSearchAvailableResponsibleAgentsComponent extends BaseCompositeSelectSearchFieldComponent {
  readonly journeyId = input<number>();
  @Input() initialResponsibleAgentOption: WritableSignal<IDropdownOption | undefined> = signal(undefined);
  readonly appliedClasses = input("");
  readonly #lookupsService = inject(LookupsService);
  readonly #authService = inject(AuthService);
  readonly #customerJourneysService = inject(CustomerJourneysService);
  dropDownLabel = input.required<string>();

  getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    if (this.journeyId()) {
      return this.#customerJourneysService.getAvailableResponsibleAgentsLookupsOptions({
        id: this.journeyId()!,
        search: this.searchSignal(),
        pageSize: this.paginatedOptions.pageSize(),
        pageNumber,
      });
    }
    return this.#lookupsService.getUsersLookUpsInDivision({
      search: this.searchSignal(),
      pageSize: this.paginatedOptions.pageSize(),
      pageNumber,
      divisionId: this.#authService.currentUserDivisionRole?.divisionId,
    });
  }
}
