import { booleanAttribute, Component, effect, input, Input, OnInit, signal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ISignalPaginatedResponse } from "../../interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "../../interfaces/dropdown-option.interface";
import { constructInitialSignalPaginatedResponse } from "../../utilities/signals.utilities";
import { Observable } from "rxjs";
import { IDropdownWithRoleNameOption } from "@root/shared/interfaces/dropdown-with-role-name-option.interface";

@Component({
  template: "",
  styles: "",
})
export abstract class BaseCompositeSelectSearchFieldComponent implements OnInit {
  // This should be inherited by composite SelectSearchFields that handle pagination, searching
  // and infinite scrolling on their own for a specific entity

  @Input() selectSignal = signal<any | undefined>(undefined);
  disabledOptions = input<(number | string)[]>([]);
  acceptInitialOptionsAndSelectFirstValue = input(false, {
    // Selects first value from preloaded paginated options. Must pass paginated options if this is true
    transform: booleanAttribute,
  });
  control = input<FormControl>(new FormControl());
  searchSignal = signal("");
  isLoading = false;
  isNoMoreResults = false;
  paginatedOptions: ISignalPaginatedResponse<IDropdownWithRoleNameOption> = constructInitialSignalPaginatedResponse();
  selectedDropdownOptionSignal = signal<IDropdownOption | undefined>(undefined);
  #isInitialRequest = true;
  readonly #searchAndFilterOptionsEffect$ = effect(
    () => {
      this.getHttpServiceCall(1); // Registers any other signals than searching in the effect, so it would run if they changed
      this.searchSignal();
      if (this.#isInitialRequest) {
        // Prevents effect running initially
        this.#isInitialRequest = false;
        return;
      }
      this.#resetPagination();
      this.loadOptions(1);
    },
    {
      allowSignalWrites: true,
    },
  );

  abstract getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>>;

  ngOnInit() {
    if (!this.acceptInitialOptionsAndSelectFirstValue())
      this.loadOptions(1); // Do not send request if the data is already there
    // Select first value from the data passed from outside
    else this.#selectFirstValue();
  }

  loadOptions(pageNumber: number): void {
    this.paginatedOptions.currentPage.set(pageNumber);
    this.isLoading = true;
    this.getHttpServiceCall(pageNumber).subscribe((signalPaginatedOptions) => {
      this.paginatedOptions.totalCount.set(signalPaginatedOptions.totalCount());
      this.paginatedOptions.totalPages.set(signalPaginatedOptions.totalPages());
      this.paginatedOptions.results.set(this.paginatedOptions.results().concat(signalPaginatedOptions.results()));
      this.isLoading = false;
      if (signalPaginatedOptions.results().length === 0) {
        this.isNoMoreResults = true;
      }
    });
  }

  #selectFirstValue(): void {
    const firstOption = this.paginatedOptions.results()[0];
    this.control().setValue(firstOption.value);
    this.selectSignal.set(firstOption.value);
    this.selectedDropdownOptionSignal.set(firstOption);
  }

  #resetPagination(): void {
    this.paginatedOptions.currentPage.set(1);
    this.paginatedOptions.results.set([]);
    this.isNoMoreResults = false;
  }
}
