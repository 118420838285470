<div class="flex flex-row grow items-center">
  <mat-button-toggle-group
    [hideSingleSelectionIndicator]="true"
    class="h-[1.625rem] w-[6.25rem] flex flex-shrink"
    id="button-list"
    [ngClass]="appliedClass()"
    [formControl]="control()"
    (change)="selectionChanged.emit($event.value)"
  >
    @for (option of options(); track option) {
      <mat-button-toggle id="button-list-button" [value]="option.value">{{
        option.label | translate
      }}</mat-button-toggle>
    }
  </mat-button-toggle-group>
  <mat-label class="ml-4 text-sm">{{ label() | translate }}</mat-label>
</div>
