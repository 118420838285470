import { Component, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { markAllControlsAsTouchedAndDirty } from "../../utilities/form.utilities";

@Component({
  template: "",
  styles: "",
})
export abstract class BaseStepperComponent {
  @ViewChild("stepper", { static: true }) matStepper!: MatStepper;
  abstract totalTabsNo: number;

  abstract submitForm(): void;

  getProgressbarValue(): number {
    if (!this.matStepper) {
      // can be undefined at this stage only if some data is being loaded
      return 100 / this.totalTabsNo;
    }
    return ((this.matStepper.selectedIndex + 1) * 100) / this.totalTabsNo;
  }

  moveToNextStepIfValid(): void {
    if (this.matStepper.selected?.stepControl && this.matStepper.selected?.stepControl.invalid) {
      markAllControlsAsTouchedAndDirty(this.matStepper.selected.stepControl as FormGroup);
      return;
    }
    this.matStepper.next();
  }
}
