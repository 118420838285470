import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { BaseCustomerCardTabComponentComponent } from "@root/shared/abstracts/base-customer-card-tab-component/base-customer-card-tab-component.component";

@Component({
  selector: "est-customer-card-notes",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./customer-card-notes.component.html",
  styleUrl: "./customer-card-notes.component.scss",
})
export class CustomerCardNotesComponent extends BaseCustomerCardTabComponentComponent {
  override loadData(): void {
    console.log("Not implemented");
  }
}
