import { inject, Injectable } from "@angular/core";
import { SnackbarType } from "../enums/snackbar-type.enum";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { SnackbarComponent } from "../snackbar/snackbar.component";
import { ComponentType } from "@angular/cdk/overlay";

@Injectable({ providedIn: "root" })
export class SnackbarService {
  matSnackbar = inject(MatSnackBar);
  currentSnackbar: MatSnackBarRef<SnackbarComponent> | null = null;

  open(
    type: SnackbarType,
    customMessage: string | undefined = undefined,
    component: ComponentType<any> | undefined = undefined,
    componentInputs: { [key: string]: any } | undefined = undefined,
    duration = 8000,
    horizontalPosition: MatSnackBarHorizontalPosition = "center",
    verticalPosition: MatSnackBarVerticalPosition = "top",
  ): void {
    this.currentSnackbar = this.matSnackbar.openFromComponent(SnackbarComponent, {
      duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      data: {
        type,
        component,
        componentInputs,
        customMessage,
      },
    });
  }

  closeCurrentSnackbar(): void {
    this.currentSnackbar?.dismiss();
  }
}
