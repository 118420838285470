import { Component, input } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-small-header",
  standalone: true,
  imports: [MatTooltip, TranslateModule, TooltipComponent, NgClass],
  templateUrl: "./small-header.component.html",
  styleUrl: "./small-header.component.scss",
})
export class SmallHeaderComponent {
  heading = input.required<string>();
  subheading = input<string>();
  tooltip = input<string>();
  appliedClasses = input("");
  subheadingAppliedClasses = input("");
}
