import { inject, Injectable, signal } from "@angular/core";
import { map, Observable, OperatorFunction, shareReplay } from "rxjs";
import { IUser } from "../models/user.model";
import { UsersRepository } from "../repositories/users.repository";
import { UserRole } from "../enums/user-role.enum";
import { RoleNameMapper } from "../mappers/role-name.mapper";
import { UserStatus } from "../enums/user-status.enum";
import { UserLoginStatusNameMapper } from "../mappers/user-login-status-name.mapper";
import { IUserRole } from "../models/user-role.model";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { FormGroup } from "@angular/forms";
import {
  constructCreateSystemAdminFormData,
  constructCreateUserFormData,
} from "@root/data/utilities/create-user-form-data.utilities";
import { mapToSignalResponseAllUsers } from "@root/data/market/users/utilities/users.utilities";
import { IEditPropertyAccessPayload } from "@root/data/market/users/models/edit-property-access-payload.model";
import { IUserWithPropertyAndTeam } from "@root/data/market/users/models/user-with-property-and-team.model";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  readonly #usersRepository = inject(UsersRepository);
  #getLanguages$ = this.#usersRepository.getAllLanguages().pipe(shareReplay(1));
  #getUserRoles$ = this.#usersRepository.getUserRoles().pipe(shareReplay(1));

  getPaginatedSmallTableInputUsers(
    status: UserStatus,
    params: IPaginationSortPayload,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#usersRepository.getPaginatedUsers(status, params).pipe(this.#mapToSignalResponseSmallTableInput());
  }

  createNewUser(formGroup: FormGroup, isSystemAdmin = false): Observable<IUser> {
    const formData = isSystemAdmin
      ? constructCreateSystemAdminFormData(formGroup)
      : constructCreateUserFormData(formGroup);
    return this.#usersRepository.createUser(formData);
  }

  editUser(formGroup: FormGroup, isSystemAdmin = false, userId: number): Observable<void> {
    const formData = isSystemAdmin
      ? constructCreateSystemAdminFormData(formGroup)
      : constructCreateUserFormData(formGroup);
    return this.#usersRepository.editUser(formData, userId);
  }

  editUserPropertyAccess(payload: IEditPropertyAccessPayload): Observable<void> {
    return this.#usersRepository.editUserPropertyAccess(payload);
  }

  getAllUserRoles(): Observable<IDropdownOption[]> {
    return this.#getUserRoles$.pipe(
      map((userRoles: IUserRole[]) => {
        return userRoles.map((userRole) => ({
          label: RoleNameMapper.get(userRole.name)!,
          value: userRole.id,
        }));
      }),
    );
  }

  getAllLanguages(): Observable<ILanguage[]> {
    return this.#getLanguages$;
  }

  getAllUser(params: IPaginationSortPayload): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#usersRepository.getAllUsers(params).pipe(mapToSignalResponseAllUsers());
  }

  archiveUser(userId: number): Observable<void> {
    return this.#usersRepository.archiveUser(userId);
  }

  blockUser(id: number): Observable<void> {
    return this.#usersRepository.blockUser(id);
  }

  restoreUser(id: number): Observable<void> {
    return this.#usersRepository.restoreUser(id);
  }

  unblockUser(id: number): Observable<void> {
    return this.#usersRepository.unblockUser(id);
  }
  getUserDetailsById(id: number): Observable<IUserWithPropertyAndTeam> {
    return this.#usersRepository.getUserById(id);
  }

  resendInvitation(id: number): Observable<void> {
    return this.#usersRepository.resendInvitation(id);
  }

  #mapUsersToSmallListTableInput(users: IUser[]): ISmallListTableInput[] {
    return users.map((user: any) => {
      return {
        ...user,
        organisationRole: this.#constructUserRoleString(user.role),
        loginStatus: UserLoginStatusNameMapper.get(user.loginStatus),
        reinvitedOn: user.reinvitedOn ?? "SETTINGS.USERS.NO_REMINDER_SET",
        lastActive: user.lastActive ?? "-",
        mouseoverMenuInputs: {
          name: user.firstName,
          userDivisionRoles: user.userDivisionRoles,
        },
        mouseoverItemTextCount: user.userDivisionRoles.length,
      };
    });
  }

  #constructUserRoleString(userRole?: UserRole): string {
    if (!userRole) return "SETTINGS.USERS.GENERIC_USER";
    return `${RoleNameMapper.get(userRole)!}`;
  }

  #mapToSignalResponseSmallTableInput(): OperatorFunction<any, any> {
    return map((paginatedUsers: IPaginatedResponse) => {
      return {
        ...mapToSignalPaginatedResponse(paginatedUsers),
        results: signal(this.#mapUsersToSmallListTableInput(paginatedUsers.results)),
      };
    });
  }
}
