import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, input, signal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-rating",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, NgClass],
  templateUrl: "./rating.component.html",
  styleUrl: "./rating.component.scss",
})
export class RatingComponent {
  maxStars = input(5);
  control = input(new FormControl(2));
  highlightedStarIndex = signal<number | null>(null);
  appliedClasses = input("");
  protected readonly Array = Array;

  updateControlValue(value: number) {
    this.control().markAsDirty();
    if (this.control().value === value + 1) {
      this.control().setValue(null);
      this.highlightedStarIndex.set(null);
    } else {
      this.control().setValue(value + 1);
    }
  }

  isStarHighlighted(value: number) {
    return (this.control().value || 0) > value || (this.highlightedStarIndex() || 0) > value;
  }
}
