import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IDialog } from "../interfaces/dialog.interface";
import { NgOptimizedImage, SlicePipe } from "@angular/common";
import { SubheaderComponent } from "../subheader/subheader.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { ButtonComponent } from "../button/button.component";
import { InputComponent } from "../input/input.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

@Component({
  selector: "est-confirmation-model",
  standalone: true,
  imports: [
    NgOptimizedImage,
    SubheaderComponent,
    TranslateModule,
    MatTooltip,
    ButtonComponent,
    SlicePipe,
    InputComponent,
    TooltipComponent,
  ],
  templateUrl: "./confirmation-dialog.component.html",
  styleUrl: "./confirmation-dialog.component.scss",
})
export class ConfirmationDialogComponent {
  matDialogData: IDialog = inject(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<ConfirmationDialogComponent> = inject(MatDialogRef);

  protected cancel = () => this.dialogRef.close(false);
}
