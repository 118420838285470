<est-dialog-header label="EVENTS.CUSTOMER_DETAILS" tooltipLabel="EVENTS.CUSTOMER_DETAILS"></est-dialog-header>
<form class="relative pb-24">
  <div class="px-6">
    <p class="mt-6 mb-2 font-medium text-sm">{{ "CUSTOMERS.CHOOSE_SCREENING_TEMPLATE" | translate }}</p>
    <p class="mb-4 font-light text-sm">
      {{ "CUSTOMERS.CHOOSE_SCREENING_TEMPLATE_DESCRIPTION" | translate }}
    </p>
  </div>
  <hr class="my-6 inset-y-0 bg-black-100" />
  @if (!isLoading) {
    <div>
      <div class="px-6">
        <p class="mt-6 mb-2 font-medium text-sm">{{ "EVENTS.CUSTOMER_DETAILS" | translate }}</p>
        <div class="flex flex-col gap-4">
          <est-input
            [control]="editCustomerFormControls.formGroup.controls.firstName"
            appliedClass="w-[18.75rem]"
            label="FIRST_NAME"
            aria-disabled="false"
          ></est-input>
          <est-input
            [control]="editCustomerFormControls.formGroup.controls.lastName"
            appliedClass="w-[18.75rem]"
            label="LAST_NAME"
            aria-disabled="false"
          ></est-input>
          <est-input
            [control]="editCustomerFormControls.formGroup.controls.email"
            appliedClass="w-[18.75rem]"
            label="EMAIL"
            aria-disabled="false"
          ></est-input>
          <est-phone-input [control]="editCustomerFormControls.phone"></est-phone-input>
        </div>
      </div>

      <hr class="my-6 inset-y-0 bg-black-100" />
      <div class="px-6">
        <div class="flex items-center gap-2 mb-2">
          <p class="font-medium text-sm">{{ "PREFERRED_LANGUAGE" | translate }}</p>
          <est-tooltip tooltip="PREFERRED_LANGUAGE"></est-tooltip>
        </div>
        <est-language-dropdown
          [countries]="languageOptions"
          [control]="editCustomerFormControls.formGroup.controls.preferredLanguageId"
          [selectMultipleOptions]="false"
          [selectWithLanguageId]="true"
          appliedClasses="w-[18.75rem]"
        ></est-language-dropdown>
      </div>
      <div class="dialog__footer">
        <est-button
          (click)="matDialog.closeAll()"
          buttonStyleType="button--tertiary"
          buttonType="button"
          label="CANCEL"
        ></est-button>
        <est-button
          [isLoading]="isFormSubmitted"
          [disabled]="isFormSubmitted"
          (click)="saveAndCloseDialog()"
          [label]="'SAVE'"
          buttonStyleType="button--primary"
          buttonType="button"
        ></est-button>
      </div>
    </div>
  } @else {
    <div class="flex justify-center items-center h-[20rem]">
      <est-spinner width="30" height="30"></est-spinner>
    </div>
  }
</form>
