import { Component, Input, WritableSignal, input, output, signal } from "@angular/core";
import { AddressComponent } from "../address/address.component";
import { GeographicalLocationComponent } from "../geographical-location/geographical-location.component";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "../button/button.component";
import { FormGroup } from "@angular/forms";
import { IPropertyAddressFormGroup } from "./property-address-form-group.model";
import { IAddressLookUp } from "../interfaces/address-look-up.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { NgOptimizedImage } from "@angular/common";
import { IAddress } from "../interfaces/address.interface";

@Component({
  selector: "est-property-address",
  standalone: true,
  templateUrl: "./property-address.component.html",
  styleUrl: "./property-address.component.scss",
  imports: [AddressComponent, GeographicalLocationComponent, TranslateModule, ButtonComponent, NgOptimizedImage],
})
export class PropertyAddressComponent {
  addressLookups = input.required<IAddressLookUp<IDropdownOption>>();
  propertyAddressControls = input.required<FormGroup<IPropertyAddressFormGroup>>();
  @Input() constructSecondaryAddress!: () => void;
  @Input() isLoadingSignal: WritableSignal<boolean> = signal(false);
  @Input() isPrimaryAddressLoadingSignal: WritableSignal<boolean> = signal(false);
  @Input() isSecondaryAddressLoadingSignal: WritableSignal<boolean> = signal(false);
  @Input() initialSecondaryAddressSignal: WritableSignal<IAddress | null> = signal(null);
  @Input() formattedPrimaryAddress: WritableSignal<string> = signal("");
  @Input() formattedSecondaryAddress: WritableSignal<string> = signal("");
  selectedValueChange = output<{ name: string; value: number | string }>();
  geoLocationChange = output<boolean>();
  addOrRemoveSecondaryAddressSignal = output<boolean>();

  addExtraAddress() {
    this.constructSecondaryAddress();
    this.addOrRemoveSecondaryAddressSignal.emit(true);
  }

  removeSecondaryAddress() {
    this.propertyAddressControls().removeControl("secondaryAddress");
    this.addOrRemoveSecondaryAddressSignal.emit(false);
  }
}
