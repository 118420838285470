import { NgClass } from "@angular/common";
import { Component, DestroyRef, ElementRef, inject, input, OnInit, output, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatError } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ICountry } from "../interfaces/country.interface";
import { LanguageDropdownComponent } from "../language-dropdown/language-dropdown.component";
import { markControlAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-pin-input",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, MatIconModule, LanguageDropdownComponent, MatError, NgClass],
  templateUrl: "./pin-input.component.html",
  styleUrl: "./pin-input.component.scss",
})
export class PinInputComponent implements OnInit {
  pinControl = input.required<FormControl<string>>();
  countries = input.required<ICountry[]>();
  countryIdControl = input.required<FormControl<string>>();
  valuesChanged = output<void>();

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #destroyRef$ = inject(DestroyRef);

  @ViewChild("pinPart1Element") pinPart1Element!: ElementRef<HTMLElement>;
  @ViewChild("pinPart2Element") pinPart2Element!: ElementRef<HTMLElement>;

  protected showPin: boolean = false;
  protected countryFormControl = this.#fb.control("", Validators.required);
  protected pinPart1 = this.#fb.control("", [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  protected pinPart2 = this.#fb.control("", [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);

  protected pinForm: FormGroup = this.#fb.group(
    {
      country: this.countryFormControl,
      pinPart1: this.pinPart1,
      pinPart2: this.pinPart2,
    },
    { updateOn: "blur" },
  );

  ngOnInit() {
    if (this.pinControl().value) this.#setPinValues();
    if (this.countryIdControl().value) this.#selectCountry();
    this.countryFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef$))
      .subscribe(() => this.updatePinControls());
  }

  protected onPinPart1Change(pinEvent: any): void {
    if (pinEvent.target.value.length === 6) {
      this.pinPart2Element?.nativeElement.focus();
    }
  }
  protected onPinPart2Change(pinEvent: any): void {
    if (
      (this.pinPart2?.value.length === 0 && this.pinPart1?.value.length === 0) ||
      (this.#isDeletingCharacter(pinEvent) && pinEvent.target.value.length === 0)
    ) {
      this.pinPart1Element?.nativeElement.focus();
    }
  }

  protected togglePinVisibility(): void {
    this.showPin = !this.showPin;
  }

  protected updatePinControls(): void {
    this.pinControl().setValue(this.#getPIN());
    this.countryIdControl().setValue(this.#getSelectedCountry());
    if (this.pinForm.touched && this.pinForm.valid) {
      if (!this.showPinErrorMessage()) this.pinControl().clearValidators();
      this.countryIdControl().clearValidators();
      if (this.pinControl().valid && this.countryIdControl().valid) {
        markControlAsTouchedAndDirty(this.pinControl());
        markControlAsTouchedAndDirty(this.countryIdControl());
      }
    } else {
      if (this.showPinErrorMessage()) {
        this.pinControl().setValidators(Validators.required);
        this.pinControl().setValidators(Validators.minLength(10));
      }
      this.countryIdControl().setValidators(Validators.required);
    }
    this.pinControl().updateValueAndValidity();
    this.countryIdControl().updateValueAndValidity();
  }

  protected showCountryIsRequiredError(): boolean {
    return this.countryFormControl.hasError("required");
  }

  protected showPinErrorMessage(): boolean {
    return (
      this.pinPart1.hasError("minlength") ||
      this.pinPart2.hasError("minlength") ||
      this.pinPart1.hasError("maxlength") ||
      this.pinPart2.hasError("maxlength") ||
      this.pinPart1.hasError("required") ||
      this.pinPart2.hasError("required")
    );
  }
  protected getPinErrorMessage(): string {
    if (this.pinPart1.hasError("required") && this.pinPart2.hasError("required")) {
      return "VALIDATION.IS_REQUIRED";
    }
    if (this.pinPart1.dirty && this.pinPart1.hasError("minlength")) {
      return "PIN.LEFT_SIDE_VALIDATION";
    }
    if (
      (this.pinPart2.dirty && this.pinPart2.hasError("minlength")) ||
      (this.pinPart2.value.length === 0 && this.pinPart2.hasError("required"))
    ) {
      return "PIN.RIGHT_SIDE_VALIDATION";
    }
    return "PIN.PIN_INVALID";
  }

  #setPinValues() {
    this.pinPart1.setValue(this.pinControl().value.slice(0, 6));
    this.pinPart2.setValue(this.pinControl().value.slice(6, this.pinControl().value.length));
    this.pinPart1.updateValueAndValidity();
    this.pinPart2.updateValueAndValidity();
  }

  #selectCountry() {
    if (this.countries().find((country) => country.id === this.countryIdControl().value)) {
      this.countryFormControl.setValue(this.countryIdControl().value);
      this.countryFormControl.updateValueAndValidity();
    }
  }

  #isDeletingCharacter(pinEvent: any): boolean {
    return (
      pinEvent.key === "Backspace" ||
      pinEvent.key === "Delete" ||
      (pinEvent.key === "Meta" && pinEvent.code === "MetaLeft")
    );
  }

  #getPIN(): string {
    return `${this.pinPart1?.value}${this.pinPart2?.value}`;
  }
  #getSelectedCountry(): string {
    return this.countryFormControl?.value;
  }
}
