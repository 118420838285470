import { Component, effect, input, WritableSignal } from "@angular/core";
import { SourceLevel } from "@root/shared/enums/source-level.enum";

@Component({
  standalone: true,
  template: "",
})
export abstract class BaseCustomerCardTabComponentComponent {
  legalEntityId = input.required<number>();
  sourceLevel = input.required<SourceLevel>();
  journeyId = input.required<WritableSignal<number | null>>();
  routeId = input<string>();
  isLoading = false;

  abstract loadData(): void;

  readonly #loadDataEffect$ = effect(() => this.loadData());
}
