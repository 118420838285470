import { inject, Injectable } from "@angular/core";
import { LegalEntityRepository } from "@root/data/market/legalEntities/repositories/legal-entity.repository";
import { Observable } from "rxjs";
import { ICustomerDetails } from "@root/data/market/legalEntities/models/customer-details.model";
import { IUpdateCustomerPayloadModel } from "@root/data/market/legalEntities/models/update-customer-payload.model";
import { UpdateCustomerAdapter } from "@root/data/market/legalEntities/adapters/update-customer.adapter";
import { IEmailTemplate } from "@root/data/market/legalEntities/models/email-template.model";
import { ISearchCustomer } from "@root/data/market/legalEntities/models/search-customer.model";
import { IInviteCustomerOnsiteModel } from "@root/data/market/legalEntities/models/invite-customer-onsite.model";
import { InviteCustomerOnsiteAdapter } from "@root/data/market/legalEntities/adapters/invite-customer-onsite.adapter";

@Injectable({
  providedIn: "root",
})
export class LegalEntityService {
  readonly #legalEntityRepository = inject(LegalEntityRepository);
  readonly #updateCustomerAdapter = inject(UpdateCustomerAdapter);
  readonly #inviteCustomerAdapter = inject(InviteCustomerOnsiteAdapter);

  getCustomerDetails(id: number): Observable<ICustomerDetails> {
    return this.#legalEntityRepository.getCustomerDetails(id);
  }

  patchCustomerDetails(id: number, body: Partial<ICustomerDetails>): Observable<void> {
    return this.#legalEntityRepository.patchCustomerDetails(id, body);
  }

  favouriteCustomer(id: number): Observable<void> {
    return this.#legalEntityRepository.favouriteCustomer(id);
  }

  inviteCustomerOnsite(model: IInviteCustomerOnsiteModel) {
    const dto = this.#inviteCustomerAdapter.toDto(model);
    return this.#legalEntityRepository.inviteCustomerOnsite(dto);
  }

  updateCustomer(id: number, payload: IUpdateCustomerPayloadModel) {
    const updateCustomerDto = this.#updateCustomerAdapter.toDto(payload);
    return this.#legalEntityRepository.updateCustomer(id, updateCustomerDto);
  }

  getEmailTemplate(id: number): Observable<IEmailTemplate> {
    return this.#legalEntityRepository.getEmailTemplate(id);
  }

  updateLegalEntity(id: number, body: Partial<ICustomerDetails>): Observable<void> {
    return this.#legalEntityRepository.updateLegalEntity(id, body);
  }

  searchCustomers(search: string): Observable<ISearchCustomer[]> {
    return this.#legalEntityRepository.searchCustomers(search);
  }
}
