<mat-form-field
  [attr.aria-required]="isRequired()"
  [ngClass]="[appliedClasses(), isControlHasError() ? 'invalid' : '']"
  id="datepicker"
>
  @if (control()) {
    <input
      (dateChange)="updateDateAndEmitValueChange($event)"
      [formControl]="innerControl"
      [matDatepickerFilter]="filterInvalidDates"
      [matDatepicker]="picker"
      matInput
    />
  } @else {
    <input (dateChange)="updateDateAndEmitValueChange($event)" [value]="value()" [matDatepicker]="picker" matInput />
  }
  @if (label()) {
    <mat-label>{{ label() | translate }} {{ requiredLabel() | translate }}</mat-label>
  }
  <mat-datepicker-toggle [disableRipple]="true" [for]="picker" matIconSuffix>
    <img
      [alt]="'CHOOSE_DATE' | translate"
      class=""
      height="14"
      matDatepickerToggleIcon
      ngSrc="assets/images/icons/calendar.svg"
      priority
      width="14"
    />
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
@if (innerControl.hasError("matDatepickerParse")) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ "VALIDATION.INVALID_DATE" | translate }}
  </mat-error>
} @else if (control()?.hasError("required") && shouldShowControlError(this.control()!)) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    @if (label()) {
      {{ label() | translate }}
      {{ "VALIDATION.IS_REQUIRED" | translate }}
    } @else {
      {{ "VALIDATION.THIS_FIELD_IS_REQUIRED" | translate }}
    }
  </mat-error>
} @else if (control()?.hasError(DATE_BEFORE_TODAY_KEY) && shouldShowControlError(this.control()!)) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ label() | translate }}
    {{ "VALIDATION.DATE_IN_PAST" | translate }}
  </mat-error>
}
