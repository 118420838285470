import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

export const mapDemographicQuestionLookUpToDropdownOption = (
  demographicQuestions: IDemographicQuestionLookUp[],
): IDropdownOption[] => {
  return demographicQuestions.map((dq) => {
    return {
      label: dq.value,
      value: dq.id,
    };
  });
};
