<div [ngClass]="appliedClasses()" class="flex">
  @for (_ of Array(maxStars()); track $index) {
    <button
      class="px-0.5"
      (mouseleave)="highlightedStarIndex.set(null)"
      (mouseenter)="highlightedStarIndex.set($index + 1)"
      type="button"
      (click)="updateControlValue($index)"
    >
      <img
        [ngSrc]="isStarHighlighted($index) ? 'assets/images/icons/star-fill.svg' : 'assets/images/icons/star.svg'"
        width="20"
        height="20"
        priority="true"
        [alt]="'RATING' | translate"
      />
    </button>
  }
</div>
