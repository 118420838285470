<mat-form-field
  [class]="appliedClass()"
  appearance="outline"
  class="w-[43.75rem] relative"
  id="search"
  subscriptSizing="dynamic"
>
  <input
    #searchInput
    [matAutocomplete]="auto"
    [attr.aria-labelledby]="placeholder() | translate"
    [placeholder]="(placeholder() | translate) + '...'"
    matInput
    type="search"
  />
  <mat-autocomplete #auto="matAutocomplete" class="!max-h-[25rem] !p-0">
    @if (searchSignal().length < 2) {
      <mat-option disabled>
        <p class="py-4 ps-4 pe-11 items-center mt-2 rounded">
          {{ "CUSTOMER_CARD.CUSTOMER_DETAILS.SEARCH_MINIMUM_CHARACTERS" | translate }}
        </p>
      </mat-option>
    } @else {
      @if (customers.length === 0) {
        <mat-option disabled>
          <p class="py-4 ps-4 pe-11 items-center mt-2 rounded">
            {{ "CUSTOMER_CARD.CUSTOMER_DETAILS.NO_RESULTS" | translate }}
          </p>
        </mat-option>
      }
      @for (customer of customers; track customer.id) {
        <mat-option
          class="!p-0 border-b-2"
          (onSelectionChange)="handleCustomerSelected(customer)"
          [value]="customer.name"
        >
          <est-search-customer-card [searchCustomer]="customer" [search]="searchSignal()"></est-search-customer-card>
        </mat-option>
      }
    }
  </mat-autocomplete>
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
