import { FormGroup } from "@angular/forms";
import { IAddressFormGroup } from "../interfaces/address-form-group.interface";
import { IAddressLookUp } from "../interfaces/address-look-up.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { IAddress } from "@root/shared/interfaces/address.interface";

export const constructInitialAddressLookups = (): IAddressLookUp<IDropdownOption> => {
  return {
    countries: [],
    floors: [],
    sides: [],
  };
};

export const formatAddress = (addressFormGroup: FormGroup<IAddressFormGroup>): string => {
  const streetName = addressFormGroup.controls.streetName.value;
  const houseNumber = addressFormGroup.controls.houseNumber.value;
  const zipCode = addressFormGroup.controls.zipCode.value;
  const city = addressFormGroup.controls.city.value;

  return `${streetName} ${houseNumber}, ${zipCode} ${city}`;
};

export const formatAddressValue = (address: IAddress): string => {
  const streetName = address.streetName;
  const houseNumber = address.houseNumber;
  const zipCode = address.zipCode;
  const city = address.city;

  return `${streetName} ${houseNumber}, ${zipCode} ${city}`;
};
