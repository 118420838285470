import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { APIS } from "@root/core/api/market.apis";

export const FollowUpStatusApiMapper = new Map<
  SourceLevel,
  {
    get: (id?: string) => string;
    getAll: (id?: string) => string;
    delete: (id?: string) => string;
    update: (id?: string) => string;
    create: (id?: string) => string;
    toggleInheritance: (id?: string) => string;
  }
>([
  [
    SourceLevel.Organisation,
    {
      get: () => APIS.followUpStatus.organisation.getFollowUpStatus,
      getAll: () => APIS.followUpStatus.organisation.getAllFollowUpStatus,
      delete: () => APIS.followUpStatus.organisation.deleteFollowUpStatus,
      update: () => APIS.followUpStatus.organisation.updateFollowUpStatus,
      create: () => APIS.followUpStatus.organisation.createFollowUpStatus,
      toggleInheritance: () => APIS.followUpStatus.organisation.toggleFollowUpStatusInheritance,
    },
  ],
]);
