import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  input,
  OnDestroy,
  output,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { InputComponent } from "@root/shared/input/input.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from "@angular/material/autocomplete";
import { SearchCustomerCardComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/components/search-customers-bar/components/search-customer-card/search-customer-card.component";
import { MatChipInput } from "@angular/material/chips";
import { ReactiveFormsModule } from "@angular/forms";
import { debounceTime, distinctUntilChanged, fromEvent, map, Subscription } from "rxjs";
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { ChipsComponent } from "@root/shared/chips/chips.component";
import { CdkOverlayOrigin, CdkScrollable } from "@angular/cdk/overlay";
import { ISearchCustomer } from "@root/data/market/legalEntities/models/search-customer.model";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";

@Component({
  selector: "est-search-customers-bar",
  standalone: true,
  imports: [
    InputComponent,
    SearchInputComponent,
    MatAutocomplete,
    MatOption,
    SearchCustomerCardComponent,
    MatAutocompleteTrigger,
    MatChipInput,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatSuffix,
    TranslateModule,
    ChipsComponent,
    CdkOverlayOrigin,
    CdkScrollable,
  ],
  templateUrl: "./search-customers-bar.component.html",
  styleUrl: "./search-customers-bar.component.scss",
})
export class SearchCustomersBarComponent implements AfterViewInit, OnDestroy {
  searchSignal: WritableSignal<string> = signal("");
  placeholder = input<string>("SEARCH");
  @ViewChild("searchInput") search!: ElementRef<HTMLInputElement>;
  appliedClass = input<string>();
  debounceTime = input(500);
  searchInputValue$: Subscription = new Subscription();
  customers: ISearchCustomer[] = [];
  selectedCustomer = output<ISearchCustomer | null>();
  legalEntityService = inject(LegalEntityService);

  ngAfterViewInit(): void {
    this.searchInputValue$ = fromEvent<Event>(this.search.nativeElement, "input")
      .pipe(
        map((event) => (event.target as HTMLInputElement).value.trim()),
        debounceTime(this.debounceTime()),
        distinctUntilChanged(),
      )
      .subscribe((searchValue: string) => {
        if (searchValue.length >= 2) {
          this.legalEntityService.searchCustomers(searchValue).subscribe((data) => {
            this.customers = data;
          });
        }
        this.searchSignal.set(searchValue);
        if (!searchValue) {
          this.selectedCustomer.emit(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.searchInputValue$.unsubscribe();
  }

  handleCustomerSelected(customer: ISearchCustomer): void {
    this.selectedCustomer.emit(customer);
  }
}
