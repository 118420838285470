import { Component, inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DecisionButtonListComponent } from "@root/shared/decision-button-list/decision-button-list.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { InputComponent } from "@root/shared/input/input.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { EditCustomerPopUpFormControls } from "@root/shared/send-invitiation-to-customer/edit-customer-pop-up/edit-customer-pop-up.form-controls";
import { finalize } from "rxjs";
import { LegalEntityService } from "@root/data/market/legalEntities/services/legal-entity.service";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { UsersService } from "@root/data/market/users/services/users.service";
import { SpinnerComponent } from "../../spinner/spinner.component";

@Component({
  selector: "est-edit-customer-pop-up",
  standalone: true,
  imports: [
    ButtonComponent,
    DecisionButtonListComponent,
    LoadingOverlayComponent,
    MatSlideToggle,
    SelectFieldComponent,
    TooltipComponent,
    TranslateModule,
    DialogHeaderComponent,
    LanguageDropdownComponent,
    InputComponent,
    PhoneInputComponent,
    SpinnerComponent,
  ],
  templateUrl: "./edit-customer-pop-up.component.html",
  styleUrl: "./edit-customer-pop-up.component.scss",
  providers: [EditCustomerPopUpFormControls],
})
export class EditCustomerPopUpComponent implements OnInit {
  #legalEntityService = inject(LegalEntityService);
  #userService = inject(UsersService);
  protected componentInputs = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
  protected languageOptions: ILanguage[] = [];
  editCustomerFormControls = inject(EditCustomerPopUpFormControls);

  protected isLoading: boolean = false;
  protected isFormSubmitted: boolean = false;
  ngOnInit() {
    this.getCustomerDetails();
    this.getLanguages();
  }

  getCustomerDetails() {
    this.isLoading = true;
    this.#legalEntityService
      .getCustomerDetails(this.componentInputs.legalEntityId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (customerDetails) => {
          this.editCustomerFormControls.phone.patchValue({
            internationalNumber: customerDetails.phoneNumber,
            number: customerDetails.phoneNumber,
            countryCode: customerDetails.phoneIsdCode,
          });
          this.editCustomerFormControls.formGroup.controls.preferredLanguageId.patchValue(
            customerDetails.preferredLanguage.languageId!,
          );
          this.editCustomerFormControls.formGroup.patchValue(customerDetails);
        },
      });
  }
  getLanguages() {
    return this.#userService
      .getAllLanguages()
      .pipe(finalize(() => {}))
      .subscribe({
        next: (languages) => {
          this.languageOptions = languages;
        },
      });
  }

  updateLegalEntity() {
    if (this.editCustomerFormControls.formGroup.valid) {
      this.isFormSubmitted = true;
      this.#legalEntityService
        .updateLegalEntity(this.componentInputs.legalEntityId, this.editCustomerFormControls.formGroup.value)
        .pipe(finalize(() => this.matDialog.closeAll()))
        .subscribe({
          next: () => {
            this.isFormSubmitted = false;
          },
        });
    }
  }

  saveAndCloseDialog(): void {
    this.updateLegalEntity();
  }
}
