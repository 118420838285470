import { HttpClient, HttpParams } from "@angular/common/http";
import { APIS } from "@root/core/api/market.apis";
import { IPropertyLookup } from "@root/data/market/properties/models/property-lookup.model";
import { IDivisionTree } from "@root/shared/interfaces/division-tree.interface";
import { IPaginatedPropertyLookupResponse } from "@root/shared/interfaces/paginated-property-lookup-response.interface";
import { IPaginatedPropertyResponse } from "@root/shared/interfaces/paginated-property-response.interface";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { PropertyType } from "../enums/property-type.enum";
import { IPropertyOverview } from "../models/property-overview.model";
import { IPropertiesParams } from "@root/shared/interfaces/properties-params.interface";
import { IAvailableCurrencies } from "../../units/models/unit-available-currencies.model";
import { ICreatePropertyPayload } from "../models/create-property-payload.model";
import { IFavoritePropertyResponse } from "../models/favorite-property-response.model";
import { IPropertyDetails } from "../models/property-details.model";
import { IPropertyUnitStatus } from "../models/property-unit-status.model";
import { inject, Injectable } from "@angular/core";
import { IPropertyPetPolicy } from "@root/data/market/properties/models/property-pet-policy.model";
import { IPropertyPetTypePolicy } from "@root/data/market/properties/models/property-pet-type-policy.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IToggleResponse } from "@root/data/market/shared/models/toggle-response.model";
import { Observable } from "rxjs/internal/Observable";
import { IPropertyMediaOverview } from "@root/data/market/properties/models/property-media-overview.model";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";
import { IDocument } from "@root/data/market/documents/models/documents.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";

@Injectable({
  providedIn: "root",
})
export class PropertiesRepository {
  readonly #http = inject(HttpClient);

  postProperty(body: ICreatePropertyPayload): Observable<number> {
    return this.#http.post<number>(APIS.properties.create, body);
  }

  favoriteProperty(body: { propertyId: number; companyId?: number }): Observable<IFavoritePropertyResponse> {
    return this.#http.post<IFavoritePropertyResponse>(APIS.properties.favouriteProperty, body);
  }

  getPaginatedProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType | "";
    },
  ): Observable<IPaginatedPropertyResponse<IPropertyOverview>> {
    return this.#http.get<IPaginatedPropertyResponse<IPropertyOverview>>(APIS.properties.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getPropertiesLookups(params?: IPaginationPayload): Observable<IPaginatedPropertyLookupResponse<IPropertyLookup>> {
    // Used for getting properties while creating a user
    return this.#http.get<IPaginatedPropertyLookupResponse<IPropertyLookup>>(APIS.properties.getPropertiesLookup, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getPropertiesHierarchy(params: IPropertiesParams): Observable<IDivisionTree> {
    return this.#http.get<IDivisionTree>(APIS.properties.getPropertiesHierarchy, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getPropertyDetails(id: string): Observable<IPropertyDetails> {
    return this.#http.get<IPropertyDetails>(APIS.properties.getPropertyDetails(id));
  }

  updatePropertyDetails(id: string, propertyDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.properties.updatePropertyDetails(id), propertyDetailsForm);
  }

  getAllCurrenciesForProperty(propertyId: string): Observable<IAvailableCurrencies> {
    return this.#http.get<IAvailableCurrencies>(APIS.currency.getAvailableCurrencies, {
      params: new HttpParams({
        fromObject: { propertyId },
      }),
    });
  }

  reactivatePropertySettings(id: string, settingValue: string): Observable<void> {
    return this.#http.post<void>(APIS.properties.reactivatePropertySettings(id), {
      propertySettingField: settingValue,
    });
  }

  updateSelectedCurrencies(id: string, selectedCurrencies: number[]): Observable<void> {
    return this.#http.post<void>(APIS.properties.updateSelectedCurrencies(id), {
      otherCurrencies: selectedCurrencies,
    });
  }

  changeCompany(id: string, companyId: number): Observable<void> {
    return this.#http.post<void>(APIS.properties.changeCompany(id), { CompanyId: companyId });
  }

  addOrRemoveSecondaryAddress(id: string, isAddAddress: boolean): Observable<void> {
    return this.#http.post<void>(APIS.properties.addOrRemoveSecondaryAddress(id), { AddAddress: isAddAddress });
  }

  getPaginatedPropertiesByCompanies(
    params: IPaginationSortPayload & {
      companyIds: number[];
      isFavourite?: boolean;
      propertyType?: PropertyType;
    },
  ): Observable<IPaginatedPropertyResponse<IPropertyOverview>> {
    return this.#http.get<IPaginatedPropertyResponse<IPropertyOverview>>(APIS.properties.getPropertiesByCompanies, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getPropertyUnitStatusOverview(id: string): Observable<IPropertyUnitStatus> {
    return this.#http.get<IPropertyUnitStatus>(APIS.properties.getPropertyUnitStatusOverview(id));
  }

  addPropertyPetTypePolicy(
    body: {
      [key: string]: any;
    },
    propertyId: number,
    petTypePolicyId: number,
  ): Observable<void> {
    return this.#http.post<void>(APIS.properties.addPropertyPetTypePolicy(propertyId, petTypePolicyId), body);
  }

  getPropertyPetTypesAssignedIds(propertyId: number, petTypePolicyId: number): Observable<number[]> {
    return this.#http.get<number[]>(APIS.properties.getPropertyPetTypesAssignedIds(propertyId, petTypePolicyId));
  }

  deleteProperty(id: number): Observable<void> {
    return this.#http.delete<void>(APIS.properties.deleteProperty(id));
  }

  getPropertyPetPolicy(id: number): Observable<IPropertyPetPolicy> {
    return this.#http.get<IPropertyPetPolicy>(APIS.properties.getPropertyPetPolicy(id));
  }

  patchPropertyPetPolicy(propertyId: number, propertyPetPolicyForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.properties.patchPropertyPetPolicy(propertyId), propertyPetPolicyForm);
  }

  listPropertyPetTypePolicies(
    params: IPaginationSortPayload & { propertyId?: number },
  ): Observable<IPaginatedResponse<IPropertyPetTypePolicy>> {
    return this.#http.get<IPaginatedResponse<IPropertyPetTypePolicy>>(
      APIS.properties.listPropertyPetTypePolicies(params.propertyId),
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }

  getPropertyPetTypePolicy(propertyId: number, petPolicyId: number): Observable<IPropertyPetTypePolicy> {
    return this.#http.get<IPropertyPetTypePolicy>(APIS.properties.getPropertyPetTypePolicy(propertyId, petPolicyId));
  }

  updatePropertyPetTypePolicy(propertyId: number, petPolicyId: number, body: { [key: string]: any }): Observable<void> {
    return this.#http.post<void>(APIS.properties.updatePropertyPetTypePolicy(propertyId, petPolicyId), body);
  }

  deletePropertyPetTypePolicy(propertyId: number, petTypePolicyId: number): Observable<void> {
    return this.#http.delete<void>(APIS.properties.deletePropertyPetTypePolicy(propertyId, petTypePolicyId));
  }

  togglePropertyPetTypePolicy(propertyId: number, petTypePolicyId: number): Observable<IToggleResponse> {
    return this.#http.post<IToggleResponse>(
      APIS.properties.togglePropertyPetTypePolicy(propertyId, petTypePolicyId),
      {},
    );
  }

  getPropertyMediaOverview(propertyId: number): Observable<IPropertyMediaOverview[]> {
    return this.#http.get<IPropertyMediaOverview[]>(APIS.properties.propertyMediaOverview(propertyId));
  }

  getPropertyMediaDetailsById(propertyId: number, mediaId: number): Observable<IUploadedPropertyMediaPackageDetails> {
    return this.#http.get<IUploadedPropertyMediaPackageDetails>(
      APIS.properties.getPropertyMediaDetailsByMediaId(propertyId, mediaId),
    );
  }

  uploadPropertyMedia(media: FormData, propertyId: number): Observable<void> {
    return this.#http.post<void>(APIS.properties.uploadMedia(propertyId), media);
  }

  editPropertyMedia(propertyMedia: { [key: string]: any }, propertyId: number, mediaId?: number): Observable<void> {
    return this.#http.patch<void>(
      APIS.properties.editPropertyMediaDetailsByMediaId(propertyId, mediaId),
      propertyMedia,
    );
  }

  getPaginatedInvitationDocuments(
    params: IPaginationSortPayload & { filter: SourceLevel; eventInstanceId: number },
  ): Observable<IPaginatedResponse<IDocument>> {
    return this.#http.get<IPaginatedResponse<IDocument>>(
      APIS.properties.listInvitationDocuments(params.eventInstanceId),
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }
}
