<div class="sticky top-0 bg-white z-10">
  <est-dialog-header [includeExitButton]="true" label="CUSTOMER_CARD.NAME" tooltipLabel="CUSTOMER_CARD.NAME">
  </est-dialog-header>
</div>
<div class="max-h-[calc(100dvh-12.5rem)] min-h-[50dvh] overflow-auto relative" cdkScrollable>
  @if (!isJourneysLoading()) {
    <est-tabs [componentInputs]="componentTabInput" [tabs]="tabs">
      <est-select-field
        [selectSignal]="componentTabInput.journeyId"
        [initialValue]="initialSelectedJourneyId"
        [dropDownOptions]="customerJourneysOptions"
        [selectMultipleOptions]="false"
        dropDownLabel="CUSTOMERS.CUSTOMER_JOURNEY"
      ></est-select-field>
    </est-tabs>
  } @else {
    <est-loading-overlay></est-loading-overlay>
  }
</div>
<div class="dialog__footer">
  <est-button
    (click)="closeDialog()"
    [appliedClasses]="'!py-3 !px-6'"
    [label]="'CLOSE'"
    buttonStyleType="button--tertiary"
    buttonType="button"
  />
</div>
