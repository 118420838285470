import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { APIS } from "@root/core/api/market.apis";

export const SourceLevelCustomerCategoryApiMapper = new Map<
  SourceLevel,
  {
    get: (id?: string) => string;
    getAll: (id?: string) => string;
    delete: (id?: string) => string;
    update: (id?: string) => string;
    create: (id?: string) => string;
    lookUp: (id?: string) => string;
    toggleInheritance: (id?: string) => string;
    toggleBlocked?: (id?: string) => string;
  }
>([
  [
    SourceLevel.Organisation,
    {
      get: () => APIS.customerCategories.organisation.getCustomerCategory,
      getAll: () => APIS.customerCategories.organisation.getAllCustomerCategories,
      delete: () => APIS.customerCategories.organisation.deleteCustomerCategory,
      update: () => APIS.customerCategories.organisation.updateCustomerCategory,
      create: () => APIS.customerCategories.organisation.createCustomerCategory,
      lookUp: () => APIS.customerCategories.organisation.lookUpCustomerCategories,
      toggleInheritance: () => APIS.customerCategories.organisation.toggleCustomerCategoryInheritance,
    },
  ],
]);
