import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { ICustomerFollowUp } from "@root/data/market/customer-journeys/models/customer-follow-up.model";
import { ICustomerJourney } from "@root/data/market/customer-journeys/models/customer-journey.model";
import { IResponsibleAgentLookup } from "@root/data/market/customer-journeys/models/responsible-agent-lookup.model";
import { IGetAvailableResponsibleAgentsLookupsQuery } from "@root/data/market/customer-journeys/queries/get-available-responsible-agents-lookups.query";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ICreateCustomerFollowUpTask } from "@root/views/main/property/property-events/customer-follow-up/create-customer-follow-up-task.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerJourneysRepository {
  readonly #http = inject(HttpClient);

  getAll(customerId: number): Observable<IPaginatedResponse<ICustomerJourney>> {
    return this.#http.get<IPaginatedResponse<ICustomerJourney>>(APIS.journeys.getAll, {
      params: new HttpParams({
        fromObject: { legalEntityId: customerId },
      }),
    });
  }

  getCustomerJourneysWithParams(
    params?: IPaginationSortPayload & {
      legalEntityId: number;
    },
  ): Observable<IPaginatedResponse<ICustomerJourney>> {
    return this.#http.get<IPaginatedResponse<ICustomerJourney>>(APIS.journeys.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getFollowUp(journeyId: number): Observable<ICustomerFollowUp> {
    return this.#http.get<ICustomerFollowUp>(APIS.journeys.getFollowUp(journeyId), {
      params: new HttpParams({
        fromObject: { id: journeyId },
      }),
    });
  }

  getAvailableResponsibleAgentsLookups(
    query: IGetAvailableResponsibleAgentsLookupsQuery,
  ): Observable<IPaginatedResponse<IResponsibleAgentLookup>> {
    return this.#http.get<IPaginatedResponse<IResponsibleAgentLookup>>(
      APIS.journeys.getAvailableResponsibleAgentsLookups(query.id),
      {
        params: new HttpParams({
          fromObject: { ...query },
        }),
      },
    );
  }

  patchFollowUp(journeyId: number, body: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.journeys.patchFollowUp(journeyId), body);
  }

  selectMarketingQuestion(journeyId: number, questionId: number): Observable<void> {
    return this.#http.post<void>(APIS.journeys.selectMarketingQuestion(journeyId), questionId);
  }

  createFollowUpTask(journeyId: number, payload: ICreateCustomerFollowUpTask): Observable<void> {
    return this.#http.post<void>(APIS.journeys.createFollowUpTask(journeyId), payload);
  }
}
