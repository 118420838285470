import { Injectable } from "@angular/core";
import { CustomerCommunicationMethod } from "@root/data/market/legalEntities/enums/customer-communication-method.enum";
import { IInviteCustomerOnsiteDto } from "@root/data/market/legalEntities/models/invite-customer-onsite-dto.model";
import { IInviteCustomerOnsiteModel } from "@root/data/market/legalEntities/models/invite-customer-onsite.model";
import { ModelAdapter } from "@root/data/market/shared/adapters/model.adapater";
import { removeNullsFromObject, setEmptyStringsToNull } from "@root/shared/utilities/object.utilities";

@Injectable({
  providedIn: "root",
})
export class InviteCustomerOnsiteAdapter extends ModelAdapter<IInviteCustomerOnsiteDto, IInviteCustomerOnsiteModel> {
  override fromDto(): IInviteCustomerOnsiteModel {
    throw new Error("Method not implemented.");
  }

  override toDto(model: IInviteCustomerOnsiteModel): IInviteCustomerOnsiteDto {
    const dto = {
      eventInstanceId: model.eventInstanceId!,
      eventTimeSlotId: model.eventTimeSlotId,
      responsibleAgentId: (model.customer as any)["responsibleAgentId"],
      customer: {
        ...model.customer,
        type: model.customer.customerType,
        address: model.customer.addressInformation,
        nationalityCountryId: model.customer.nationalityId,
      },
      followUp: {
        ...model.followUp,
        journeyFollowUpTasks: model.followUp.followUpTasks.map((task) => ({
          followUpTaskId: task.followUpTaskId!,
          note: task.followUpNote!,
          notificationDate: task.date!,
        })),
      },
      notificationMethod: {
        body: model.notificationMethod.message,
        subject: model.notificationMethod.subject,
        communicationMethods: this.#mapCommunicationMethods({
          myPage: model.notificationMethod.communicationMethodMyPage,
          sms: model.notificationMethod.communicationMethodSMS,
        }),
        notifiableAgentsIDs: model.followUp.responsibleAgentId ? [model.followUp.responsibleAgentId] : [],
      },
    };
    setEmptyStringsToNull(dto);
    removeNullsFromObject(dto);
    return dto;
  }

  #mapCommunicationMethods({ sms, myPage }: { sms: boolean; myPage: boolean }) {
    const communicationMethods: CustomerCommunicationMethod[] = [CustomerCommunicationMethod.Email];
    sms && communicationMethods.push(CustomerCommunicationMethod.SMS);
    myPage && communicationMethods.push(CustomerCommunicationMethod.MyPage);
    return communicationMethods;
  }
}
