import { Component, inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CustomerJourneysService } from "@root/data/market/customer-journeys/services/customer-journeys.service";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CustomerCardActivitiesComponent } from "@root/shared/customer-card/components/customer-card-activities/customer-card-activities.component";
import { CustomerCardDetailsComponent } from "@root/shared/customer-card/components/customer-card-details/customer-card-details.component";
import { CustomerCardFollowUpComponent } from "@root/shared/customer-card/components/customer-card-follow-up/customer-card-follow-up.component";
import { CustomerCardNotesComponent } from "@root/shared/customer-card/components/customer-card-notes/customer-card-notes.component";
import { CustomerCardOverviewComponent } from "@root/shared/customer-card/components/customer-card-overview/customer-card-overview.component";
import { ICustomerCardInputs } from "@root/shared/customer-card/customer-card-inputs.interface";
import { ICustomerCardTabInputs } from "@root/shared/customer-card/customer-card-tab-inputs.interface";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ITab } from "@root/shared/interfaces/tab.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TabsComponent } from "@root/shared/tabs/tabs.component";
import { CdkScrollable } from "@angular/cdk/overlay";

@Component({
  selector: "est-customer-card",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    TabsComponent,
    ButtonComponent,
    SelectFieldComponent,
    LoadingOverlayComponent,
    CdkScrollable,
  ],
  templateUrl: "./customer-card.component.html",
  styleUrl: "./customer-card.component.scss",
})
export class CustomerCardComponent implements OnInit {
  readonly componentInputs: ICustomerCardInputs = inject(MAT_DIALOG_DATA);
  readonly isJourneysLoading = signal(false);
  customerJourneysOptions: IDropdownOption[] = [];
  protected tabs: ITab[] = [
    {
      label: "CUSTOMER_CARD.OVERVIEW.NAME",
      component: CustomerCardOverviewComponent,
    },
    {
      label: "CUSTOMER_CARD.CUSTOMER_DETAILS.NAME",
      component: CustomerCardDetailsComponent,
    },
    {
      label: "CUSTOMER_CARD.FOLLOW_UP.NAME",
      component: CustomerCardFollowUpComponent,
    },
    {
      label: "CUSTOMER_CARD.NOTES.NAME",
      component: CustomerCardNotesComponent,
    },
    {
      label: "CUSTOMER_CARD.ACTIVITIES.NAME",
      component: CustomerCardActivitiesComponent,
    },
  ];
  readonly #customerJourneysService = inject(CustomerJourneysService);
  readonly #matDialog = inject(MatDialog);
  componentTabInput: ICustomerCardTabInputs = { ...this.componentInputs, journeyId: signal(null) };
  initialSelectedJourneyId?: number;

  ngOnInit() {
    this.#loadCustomerJourneys();
  }

  closeDialog() {
    this.#matDialog.closeAll();
  }

  #loadCustomerJourneys() {
    this.isJourneysLoading.set(true);
    this.#customerJourneysService.getAllOptions(this.componentInputs.legalEntityId).subscribe({
      next: (res) => {
        console.log("next");
        this.customerJourneysOptions = res.results();
        this.isJourneysLoading.set(false);
        this.initialSelectedJourneyId = res.results()[0].value;
      },
      error: () => {
        this.closeDialog();
      },
    });
  }
}
