import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ICustomerCategory } from "@root/data/market/system-configurations/customer-category/models/customer-category.model";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";

export const mapCustomerCategoryToSignalResponseSmallTableInput = (): OperatorFunction<any, any> => {
  return map((paginatedResponse: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedResponse),
      results: signal(mapCustomerCategoryToSmallListTableInput(paginatedResponse.results)),
    };
  });
};

export const mapCustomerCategoryToSmallListTableInput = (
  customerCategories: ICustomerCategory[],
): ISmallListTableInput[] => {
  return customerCategories.map((customerCategory) => {
    return {
      ...customerCategory,
    };
  });
};
