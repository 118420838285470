import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";

export const FileExtensionMIMETypeMapper = new Map<FileExtension, string>([
  [FileExtension.PDF, "application/pdf"],
  [FileExtension.JPG, "image/jpg"],
  [FileExtension.JPEG, "image/jpeg"],
  [FileExtension.PNG, "image/png"],
  [FileExtension.CSV, "text/csv"],
  [FileExtension.XLSX, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  [FileExtension.MP4, "video/mp4"],
  [FileExtension.MOV, "video/quicktime"],
  [FileExtension.AVI, "video/x-msvideo"],
  [FileExtension.WAV, "audio/wav"],
]);

export const MIMETypeFileExtensionMapper = new Map<string, FileExtension>([
  ["application/pdf", FileExtension.PDF],
  ["image/jpg", FileExtension.JPG],
  ["image/jpeg", FileExtension.JPEG],
  ["image/png", FileExtension.PNG],
  ["text/csv", FileExtension.CSV],
  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", FileExtension.XLSX],
  ["video/mp4", FileExtension.MP4],
  ["video/quicktime", FileExtension.MOV],
  ["video/x-msvideo", FileExtension.AVI],
  ["audio/wav", FileExtension.WAV],
]);
