import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { APIS } from "@root/core/api/market.apis";
import { IDocument } from "@root/data/market/documents/models/documents.model";
import { IDocumentType } from "../models/document-type.model";
import { IDocumentDetails } from "@root/shared/interfaces/document-details.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { SourceLevelDocumentApiMapper } from "@root/data/market/documents/mappers/source-level-api.mapper";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";

@Injectable({
  providedIn: "root",
})
export class DocumentsRepository {
  readonly #http = inject(HttpClient);
  readonly #documentApiMap = SourceLevelDocumentApiMapper;

  getPaginatedDocuments(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<IPaginatedResponse<IDocument>> {
    return this.#http.get<IPaginatedResponse<IDocument>>(this.#documentApiMap.get(sourceLevel)!.getAll(routeId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteDocument(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.delete<void>(this.#documentApiMap.get(payload.sourceLevel)!.deleteDocument(payload.routeId), {
      body: { ...payload.ids },
    });
  }

  viewDocument(payload: IInheritedQueryPayload): Observable<Blob> {
    return this.#http.get<Blob>(this.#documentApiMap.get(payload.sourceLevel)!.viewDocument(payload.routeId), {
      responseType: "blob" as "json",
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }

  getDocumentTypes(): Observable<IDocumentType[]> {
    return this.#http.get<IDocumentType[]>(APIS.document.getDocumentTypes);
  }

  uploadDocuments(document: FormData, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#http.post<void>(this.#documentApiMap.get(sourceLevel)!.uploadDocuments(routeId), document);
  }

  getDocumentDetails(payload: IInheritedQueryPayload): Observable<IDocument> {
    return this.#http.get<IDocument>(this.#documentApiMap.get(payload.sourceLevel)!.getDetails(payload.routeId), {
      params: new HttpParams({
        fromObject: payload.ids,
      }),
    });
  }

  updateDocument(payload: IInheritedQueryPayload, body: IDocumentDetails): Observable<void> {
    return this.#http.put<void>(this.#documentApiMap.get(payload.sourceLevel)!.editDocument(payload.routeId), {
      ...payload.ids,
      ...body,
    });
  }

  toggleBlockedDocument(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(
      this.#documentApiMap.get(payload.sourceLevel)!.toggleBlockedDocument(payload.routeId),
      { ...payload.ids },
    );
  }

  toggleDocumentInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#http.post<void>(
      this.#documentApiMap.get(payload.sourceLevel)!.toggleDocumentInheritance(payload.routeId),
      { ...payload.ids },
    );
  }
}
