export const VAT_NUMBER_NOT_EXISTS_ERROR_KEY = "VAT_NOT_EXIST";

export const INVALID_VAT_NUMBER_ERROR_KEY = "INVALID_VAT";

export const VALIDATION_ERROR_KEY = "EMAIL_ERROR";

export const DATE_BEFORE_TODAY_KEY = "dateBeforeToday";

export const LAT_LONG_INVALID = "LAT_LONG_INVALID";

export const EMPTY_ARRAY_ERROR_KEY = "EMPTY_ARRAY_ERROR_KEY";

export const END_DATE_BEFORE_START_DATE_ERROR_KEY = "END_DATE_BEFORE_START_DATE_ERROR_KEY";
